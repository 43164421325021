.contact-us-subject{
    height: 56px;
}

.contact-us-desc{
    height: 128px;
}

/** float container */
.contact-float-container {
    border-radius: 8px;
    border: 1px solid rgba(223, 227, 232, 1);
    /* background-color: rgb(55, 65, 81); */
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0 8px;
    position: relative;
    width: 100%;
    /** active label */
  }
  .contact-float-container input {
    /* border: none;
      font-size: 16px;
      outline: 0;
      padding: 16px 0 10px;
      width: 100%; */
  
    border: none;
    outline: 0;
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    /* height: 56px; */
    width: 100%;
    margin-top: 16px;
  }

  .contact-float-container textarea {
    border: none;
    outline: 0;
    resize: none;
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    height: 70%;
    margin-top: 16px;
    width: 100%;
  }
  .contact-float-container label {
    font-size: 16px;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 16px) scale(1);
    transition: all 0.1s ease-in-out;
  
    opacity: 1;
    color: rgba(21, 24, 27, 1);
    /* background-color: rgb(55, 65, 81); */
    font-family: "Graphik-Regular";
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.35px;
    text-align: left;
    line-height: 20px;
  }
  .contact-float-container.active label {
    transform: translate(0, 4px) scale(0.75);
  }
  
  .contact-float-container_dark {
    border-radius: 8px;
    /* border: 1px solid rgba(223,227,232, 1); */
    background-color: rgb(55, 65, 81);
  
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0 8px;
    position: relative;
    width: 100%;
    /** active label */
  }
  .contact-float-container_dark input {
    /* border: none;
      font-size: 16px;
      outline: 0;
      padding: 16px 0 10px;
      width: 100%; */
  
    border: none;
    outline: 0;
    opacity: 1;
    /* background-color: rgba(255,255,255, 1); */
    background-color: rgb(55, 65, 81);
    color: white;
    /* height: 56px; */
    width: 100%;
    margin-top: 16px;
  }
  .contact-float-container_dark textarea {
    border: none;
    /* font-size: 16px; */
    outline: 0;
    /* padding: 16px 0 10px;
      width: 100%; */
    resize: none;
    opacity: 1;
    /* background-color: rgba(255,255,255, 1); */
    background-color: rgb(55, 65, 81);
    color: white;
    height: 70%;
    /* width: 937px; */
    margin-top: 16px;
    /* margin-left: 16px; */
    width: 100%;
  }
  .contact-float-container_dark label {
    font-size: 16px;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 16px) scale(1);
    transition: all 0.1s ease-in-out;
  
    opacity: 1;
    /* color: rgba(21,24,27,1); */
    color: rgba(255, 255, 255, 1);
    background-color: rgb(55, 65, 81);
    font-family: "Graphik-Regular";
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.35px;
    text-align: left;
    line-height: 20px;
  }
  .contact-float-container_dark.active label {
    transform: translate(0, 4px) scale(0.75);
  }
  .dark-contact-nav-tabs.active {
    background-color: rgb(33, 43, 54);
  }


.hAXbWy {
    background-color: rgb(239, 242, 245);
    margin: 30px 0px 0px 0px !important;
    padding: 10px;
    height: 50px;
    display: flex;
    border-radius: 5px;
    -webkit-box-align: center;
    align-items: center;
}

.cFOIUY {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.bRDpfN {
  margin: 0px 0px 0px 20px;
  display: flex;
}

.dAhoyD {
  font-size: 12px;
  width: 100px;
}

.kbxoxF {
  color: rgb(145, 158, 171);
  font-size: 10px;
}

.la-dTuR {
  width: 400px;
}

.css-fbkt3g {
  font-weight: 500!important;
  width: 42px;
  margin-right: 10px;
  margin-left: 5px;
  color: RGB(128, 0, 204);
  font-size: 32px;
}

.contact-us-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.support-text {
  font-size: 12px;
  width: 210px;
}

.cursor {
  cursor: pointer;
}

.cGODdJ {
  margin: 0px 10px 0px 0px;
}

.fJlQtE {
  background-color: transparent;
  padding: 5px;
  display: flex;
  border: 1px solid rgb(145, 158, 171);
  border-radius: 3px;
  -webkit-box-align: center;
  align-items: center;
}

.jzuRnk {
  font-size: 12px;
  margin: 0px 5px 0px 0px;
  width: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.feedbackFormModal .modal-dialog {
  max-width: 75%;
}
.feedbackFormModal .modal-body {
  padding: 26px 32px 14px;
  /* max-height: calc(90vh - 128px);  */
  max-height: unset !important;
  overflow-y: auto;
  overflow-x: hidden; 
}
.feedbackFormModal .modal-content {
  background: rgb(255, 255, 255);
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  border-radius: 16px;
  overflow: visible;
}
.feedbackFormModal .modal-content .modal-header {
  height: 56px;
  background: rgb(128, 0, 204);
  border-radius: 16px 16px 0px 0px;
  padding: 0 0 0 66px;
  display: flex;
  align-items: center;
}
.feedbackFormModal .modal-content .modal-header .close {
  background: url("../../../Static/images/CloseGray.png") no-repeat;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 16px;
  width: 16px;
}
.feedbackFormModal .modal-content .modal-header .close:hover {
  background-color: transparent;
  opacity: 0.9;
}
.feedbackFormModal .modal-content .modal-header .modal-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.33px;
  position: relative;
  margin-bottom: 40px;
  margin-left: 42px;
}
.feedbackFormModal .modal-content .modal-header .modal-title::before {
  position: absolute;
  content: "";
  display: inline-flex;
  left: -42px;
  top: 0;
  bottom: 0;
  width: 26px;
  height: 24px;
  margin: auto;
  background: url("../../../Static/images/feedback-white.svg") no-repeat;
}
.feedbackFormModal .modal-content .modal-header button,
.feedbackFormModal .modal-content .modal-header button:hover,
.feedbackFormModal .modal-content .modal-header button:active {
  color: #ffff;
  outline: none;
}
.feedbackFormModal .modal-content .modal-footer {
  height: 72px;
  background-color: rgb(244, 246, 248);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  flex-wrap: nowrap;
}
.feedbackFormModal .modal-content .modal-footer .success-feedback {
  height: 72px;
  background-color: rgb(244, 246, 248);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feedbackFormModal .modal-content .modal-footer p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  padding: 0 32px;
}
.feedbackFormModal .modal-content .modal-footer p span {
  font-weight: 600;
}
.feedbackFormModal .modal-content .modal-footer button,
.feedbackFormModal .modal-content .modal-footer button:hover,
.feedbackFormModal .modal-content .modal-footer button:active {
  background: rgb(161, 0, 255);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 13px 24px;
}
.feedbackFormModal .modal-content .modal-footer .btn.disabled {
  opacity: 0.3;
  background: rgb(161, 0, 255);
}
.feedbackFormModal .modal-content .modal-footer button:active:focus,
.feedbackFormModal .modal-content .modal-footer button:focus,
.feedbackFormModal .modal-content .modal-footer button:hover {
  outline: none;
  box-shadow: none;
  background: rgb(179, 51, 255);
}
.feedback-form-wrapper p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
}
.feedbackFormModal .tabs-container ul.nav-pills li {
  width: calc(33.33% - 22px);
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15);
  border-radius: 8px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.feedbackFormModal .tabs-container ul.nav-pills li:first-child {
  margin-left: 0;
}
.feedbackFormModal .tabs-container ul.nav-pills li:last-child {
  margin-right: 0;
}
.feedbackFormModal .tabs-container ul.nav-pills li span {
  display: none;
}
.feedbackFormModal .tabs-container ul.nav-pills li:hover span {
  position: absolute;
  background: #212b36;
  padding: 14px 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  z-index: 999 !important;
  top: 84px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.feedbackFormModal .tabs-container ul.nav-pills li:hover span:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
  transform: rotate(180deg);
}
.feedbackFormModal .tabs-container .nav-pills .nav-link {
  width: 100%;
  line-height: 60px;
  padding: 0px;
  color: rgb(99, 115, 129) !important;
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  border-radius: 8px !important;
  padding-top: 24px;
  position: relative;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.active {
  border-left: none;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(33, 43, 54) !important;
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.35px;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link::after,
.feedbackFormModal .tabs-container .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  background: rgb(161, 0, 255);
  border-radius: 0px 0px 4px 4px;
  left: 0;
  right: 0;
  width: 0%;
  margin: auto;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link:hover::after,
.feedbackFormModal .tabs-container .nav-pills .nav-link.active::after {
  transition: width 0.5s;
  width: 100%;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link::before {
  position: absolute;
  content: "";
  display: inline-flex;
  width: 24px;
  height: 24px;
  left: 0;
  right: 0;
  top: 16px;
  margin: auto;
}
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.general::before {
  background: url("../Images/general-inactive.svg") no-repeat;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.general.active::before {
  background: url("../Images/general-active.svg") no-repeat;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.enhancement::before {
  background: url("../Images/enhancement-inactive.svg") no-repeat;
  top: 24px;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.enhancement.active::before {
  background: url("../Images/enhancement-active.svg") no-repeat;
  top: 24px;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.newidea::before {
  background: url("../Images/newidea-inactive.svg") no-repeat;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.newidea.active::before {
  background: url("../Images/newidea-active.svg") no-repeat;
} */
.feedbackFormModal .tab-content .tab-pane h2 {
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  margin: 0px 0px 6px 0px;
  padding-top: 10px;
}
.feedbackFormModal .tab-content .rating-star {
  margin-bottom: 10px;
}
.feedbackFormModal .tab-content .rating-star span {
  margin-right: 26px;
  cursor: pointer;
  position: relative;
  width: 26px;
  height: 26px;
  display: inline-flex;
}
.sup-rating {
  top: 0px;
  color: #aa0025;
  font-size: 16px;
  font-weight: bold;
}
.feedbackFormModal .tab-content .rating-star span::after {
  content: "";
  display: inline-flex;
  /* background: url("../Images/star-inactive.svg"); */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  width: 26px;
  height: 26px;
}
.feedbackFormModal .tab-content .rating-star span.checked::after {
  content: "";
  display: inline-flex;
  /* background: url("../Images/star-active.svg"); */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  width: 26px;
  height: 26px;
}
.feedbackFormModal .tab-content .form-check .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  cursor: pointer;
}
.feedbackFormModal .tab-content .form-check.checkbox-checked .form-check-label {
  font-family: Graphik-Medium;
  font-weight: 500;
}
.feedbackFormModal .tab-content label.form-check {
  padding: 0px 0 8px 30px;
  margin-top: 8px;
}
.feedbackFormModal .tab-content .form-check .checkmark {
  top: -7px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

/* .form-control {
  width: 95%;
} */

.contactus-tab-panel {
  padding: 0px 32px 32px;
  height: 100%;
  width: 96%;
  overflow-y: auto;
  overflow-x: hidden;
}

.contact-us-header {
  color: white;
}

.form-control-feedback {
  height: 200px;
}

.support-modal-body {
  padding: 0px 32px 32px;
  overflow-y: auto;
  overflow-x: hidden;
}

.issue-textbox {
  outline: unset !important;
}

.access-issue-input {
  height: 130px;
  resize: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}

.feedback-input {
  height: 200px;
  resize: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}

.contact-us-textarea-error {
  border: 1px solid red !important;
}

.success-message {
  color: green !important;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 0px  !important;
}