.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  border-style: dashed;
  border: 2px dashed #c4cdd5;
  border-radius: 4px;
  margin-bottom: 15px;
  border-color: #c4cdd5;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin: 20px 0;
}
.upload-btn-wrapper .btn {
  font-family: Graphik-Medium;
  font-size: 14px;
  position: relative;
  color: #637381;
  font-family: graphik;
  display: block;
  align-items: center;
  top: 0;
  right: 0;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  font-size: 100px;
}
#approvalEmailFile {
  display: none;
}
.approvalFileBtn {
  color: #a100ff;
  border: none;
  background: transparent;
  margin: 0;
  font-family: Graphik-Medium;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}
.fileupload-browse .approval-attach {
  font-family: graphik;
  margin-left: 5px;
  color: #637381;
  font-size: 12px;
}
.filedrop-msg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filedrop-msg p {
  color: #637381;
  font-family: Graphik-Medium-Web;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}
.submit-settings-container #groove-radio-button .form-check > label {
  font-size: 16px !important;
}
.submit-settings-container .row {
  margin: 0;
}
.submitSettings-dialog .modal-header {
  padding: 30px 20px 0px 40px !important;
}
.submitSettings-dialog .modal-body {
  padding: 0px !important;
}
/* .submit-modal-body {
    padding: 20px 20px 0px 40px !important;
} */
.submit-settings-container .submit-content {
  margin-top: 40px;
  /* padding-right: 20px;
  max-height: 420px;
  overflow-y: auto; */
}
.submit-settings-container .rejection-recipient p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-bottom: 12px;
}
.div.cbox-container {
  max-height: 200px;
}
.submit-settings-container .rejection-recipient .groove-select-dropdown {
  margin-bottom: 24px;
}
.submit-settings-container div.custom-checkboxlist {
  max-height: 154px;
}
.attaching-file-container {
  display: flex;
  align-items: center;
  margin: 20px 0px 30px 0px;
}
.attaching-file-container .progress {
  width: 55%;
  height: 10px;
  border-radius: 4px;
  font-size: 10px;
  margin: 0 10px;
}
.attaching-file-container .progress-bar {
  background: rgb(161, 0, 255);
}
.attaching-file-container .attaching-file-name {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
}
.submitSettings-dialog .selectmenu-options {
  border: 1px solid #dfe3e8;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.submitSettings-dialog .selectmenu-options .checkbox-dropdown-content {
  margin: 10px 15px !important;
}
.radio-button-container > form > div{
  display: flex;
  flex-direction: row;
}

.flip-switch-container {
  margin: 10px 0 20px;
  
}

.radio-button-container > form > div > div {
  margin: 0 0 0 8px
}
.submitSettings-dialog .selectmenu-options {
  border: 1px solid #dfe3e8;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.submitSettings-dialog .selectmenu-options .checkbox-dropdown-content {
  margin: 10px 15px !important;
}