.cvm-homepage {
  margin: 0;
  /* min-height: 640px !important; */
}

.cvm-banner {
  padding-bottom: 10px;
}

img.overlay-image {
  width: 505px;
  position: absolute;
  top: 0;
  left: -35px;
}

.cvm-banner > div:first-child {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(0, 0, 0, 0.5) 100%
    )
    rgb(161, 0, 255);
  border-radius: 0 0 16px 16px;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  padding: 100px 30px 170px 30px;
  position: relative;
  margin: 0;
}


.cvm-banner > div {
  text-align: center;
  margin: 8px;
}

.cvm-welcome {
  color: #ffffff;
  font-family: "Graphik-Medium-Web";
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 50px;
}

.cvm-verbiage {
  color: #ffffffdb;
  font-family: "Graphik-Regular-Web";
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 75px !important;
}

.modules-cards-container {
  display: flex;
  justify-content: center;
  margin-top: -170px !important;
}

.modules-cards-container.row > div {
  /* height: 100%; */
  display: flex;
  justify-content: center;
}

.modules-card {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%);
  width:100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 0 20px;
  position: relative;
}

.modules-card:hover {
  box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%),
    0px 0px 0px 2px rgb(161 0 255) inset;
}

.module-info {
  display: flex;
  height: 0px;
  justify-content: flex-end;
  padding-top: 10px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.module-info .svg-container {
  font-size: 14px !important;
}

.module-info:hover ~ .module-long-desc, .module-long-desc:hover {
  display: block;  
}

.module-long-desc {
  position: absolute;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 2px solid rgb(161, 0, 255);
  box-shadow: 0px 2px 32px 0px rgb(0 0 0 / 10%);
  padding: 30px;
  opacity: 1 !important;
  z-index: 1;
  display: none;
  width: 105%;
  left: -3%;
  bottom : 0;
  min-height: 100%;
}

.modules-card.no-access .module-long-desc {
  border-color: rgb(191 191 191);
}

.module-desc-head {
  display: flex;
  margin-bottom: 15px;
}

.module-desc-icon {
  width: 35px;
  margin-right: 15px;
}
.module-desc-name {
  color: rgb(21, 24, 27);
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 28px;
  text-align: left;
  display: flex;
  align-items: center;
}

.module-desc {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 22px;
  text-align: justify;
}

.module-desc-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.module-desc-footer .module-button {
  margin: 0;
  height: 33px;
}


.module-icon > img {
  width: 60px;
  margin: 10px 0 0 0;
  height: 60px;
}

.larger-icon-dashboard > img {
  width: 80px;
  margin: 15px 0 -18px 0;
}

.larger-icon-data > img {
  margin: 26px 0 -4px 0;
}

.larger-icon-master > img {
  width: 80px;
  margin: 16px 0 -9px 0;
}

.module-name {
  color: rgb(21, 24, 27);
  font-family: Graphik-Semibold-Web;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 28px;
  text-align: center;
  margin-top: 5px;
}

.module-description {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Regular-Web";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
  text-align: center;
  width: 100%;
  padding: 0 30px;
  align-self: center;
  margin: 12px 0;
}

.module-button {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 40px;
  width: 124px;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
}

.module-button > svg {
  margin: 0 0 0 10px;
  font-size: 12px;
}

.module-button:hover {
  background: rgb(161, 0, 255);
  color: #fff;
}

.modules-card.no-access {
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  cursor: default;
  filter: grayscale(1);
}

.modules-card.no-access:hover {
  border: none;
}

.modules-card.no-access > .module-button:hover {
  cursor: not-allowed;
  background: none;
  color: rgb(161, 0, 255);
}

.modules-card.no-access > * {
  opacity: 0.5;
}
