.table-action-buttons > img {
  width: 22px;
  margin: 0 60px 0 0;
  cursor: pointer;
}

.client-steakholder-title {
  margin: 5px 0 20px 0;
}

.admin-client-details .client-details-button {
  display: none;
}

.admin-client-details .client-stage-details span {
  color: #7500C0;
}