.rvd-commentary-modal {
  max-width: 600px !important;
}

.rvd-commentary-history-modal {
  max-width: 800px !important;
}

.rvd-commentary-history-modal .commentary-comments {
  border: none;
}

.rvd-commentary-history-modal .commentary-comment > div {
  width: 100% !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.rvd-commentary-history-modal .commentary-comments > div {
  padding: 10px 0px 0px;
}

.commentary-history-label {
  margin-bottom: 0px !important;
  background: white !important;
  cursor: default !important;
}

.commentary-boidetails-container .commentary-boi-details {
  display: flex;
  width: 100%;
}

.commentary-boidetails-container .commentary-boi-details > div {
  width: 100%;
  font-size: 12px;
  padding-right: 20px;
}

.commentary-boi-details > div > div:first-child {
  font-weight: 600;
}

.rvd-commentary-modal .modal-header,
.rvd-commentary-history-modal .modal-header {
  padding: 20px 20px 0px !important;
}

.rvd-commentary-modal .modal-body > div:first-child,
.rvd-commentary-history-modal .modal-body > div:first-child {
  padding: 20px !important;
}

.commentary-add-comments {
  display: flex;
}

.commentary-add-comments > div {
  padding: 0px 10px;
}

.commentary-add-comments > div:nth-child(3) {
  margin-top: 7px !important;
}

.commentary-add-comments #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
}

.commentary-add-comments #addComment::placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment:focus-visible {
  box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #a100ff;
  outline: none;
}

.commentary-add-comments #addComment.commentary-add-comment-invalid,
.commentary-update-comment #addComment.commentary-add-comment-invalid {
  border: 1px solid #aa0025 !important;
}

.rvd-commentary-modal .modal-title > div,
.rvd-commentary-history-modal .modal-body > div {
  font-size: 20px !important;
}

.commentary-comments {
  min-height: 200px;
  max-height: 300px;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
  margin: 9px 0px;
  overflow-y: auto;
}

.commentary-comments > div {
  padding: 10px 0px;
}

.commentary-comments > div > div {
  display: flex;
}

.commentary-comments > div > div > div > div {
  padding: 0px 5px;
}

.commentary-comments > div > div > div:nth-child(2) {
  padding: 5px 0px;
}

.commentary-comments > div > div > div:nth-child(2) > div:first-child {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.commentary-comments > div > div > div:nth-child(2) > div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Regular-Web";
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.commentary-comment > div {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%);
  min-height: 40px;
  padding: 10px;
  width: 93%;
  margin-left: auto;
  margin-right: 5px;
  cursor: not-allowed;
}

.commentary-comment > div > span {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
  word-wrap: break-word;
}

.commentary-comment > div > span:nth-child(2) {
  float: right;
  padding-top: 5px;
  color: rgb(161, 0, 255);
  cursor: pointer;
}

.send-disabled {
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events: none;
}

.commentary-no-comment {
  width: 100%;
  text-align: center;
  font-size: 13px;
  padding: 40px 0px !important;
}

.commentary-no-comment > div {
  display: block !important;
}

.commentary-update-comment #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 93%;
  margin-left: auto;
  margin-right: 5px;
}

.commentary-update-comment #addComment::placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment:focus-visible {
  box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #a100ff;
  outline: none;
}

.update-comments-buttons {
  display: flex;
  float: right;
}

.update-comments-buttons > div:first-child {
  margin-right: 15px;
}

.comment-edit-header > div {
  font-size: 20px;
  margin-left: 10px;
}

.comment-edit-header > svg {
  padding-top: 5px;
  cursor: pointer;
}

.commentary-boidetails-container .single-select-label{
  width: 40px;
}

.commentary-send-icon{
  cursor: pointer;
}