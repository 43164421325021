.projectEntry-container {
  padding: 30px 80px;
  min-height: 840px !important;
}

.projectEntry-container .btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.projectEntry-container .btn-container .project-title-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-container > div {
  display: flex;
  width: 60%;
  word-break: break-all;
}

.projectEntry-container .title-section {
  margin-bottom: 5px;
}

.projectEntry-container .title-section .fa-times {
  cursor: pointer;
}

.select-project-modal .add-new-program-container {
  overflow-y: visible;
}

.select-project-modal .modal-header {
  padding-bottom: 15px !important;
}

.program-details-column {
  display: flex;
  margin-bottom: 3px;
}

.program-details-row {
  border-right: 1px solid #dfe3e8;
}

.program-details-row:last-child {
  border-right: none;
}

.program-details-row.col-lg-4 {
  padding-right: 10px;
  padding-left: 12px;
}

.project-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 18px;
}

.projectEntry-subcontainer {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 40px 50px;
}

.projectEntry-subcontainer .project-title {
  width: 100%;
  word-break: break-word;
}
.projectEntry-container .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.computeAll-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.sub-title-header {
  margin-right: 0px !important;
  margin-left: 0px !important;
  text-align: center;
}

.projectEntry-container .sub-title .sub-title-header h5 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 0;
  align-self: center;
}

.reset-all-button,
.compute-all-button {
  display: flex;
  flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 50px;
  cursor: pointer;
}

.reset-all-button {
  padding: 3px 0;
  padding-left: 15px;
}
.reset-all-button button {
  color: #a100ff !important;
  align-self: center;
  justify-content: center;
  padding-left: 15px;
}
.compute-all-button button {
  color: #a100ff !important;
  align-self: center;
  justify-content: center;
}

.reset-all-button img {
  height: 30px;
  width: 15px;
  position: absolute;
}

.compute-all-button img {
  height: 33px;
  width: 15px;
  position: absolute;
  margin-left: 15px;
}

.reset-all-button:hover,
.compute-all-button:hover {
  background-color: #a100ff;
  transition: all 0.15s ease-in-out;
}

.reset-all-button:hover button,
.compute-all-button:hover button {
  color: #fff !important;
  transition: all 0.15s ease-in-out;
}

.compute-all-button:hover img,
.reset-all-button:hover img {
  filter: brightness(0) invert(1);
  transition: all 0.15s ease-in-out;
}

.projectEntry-container .sub-title-right span,
.projectEntry-container .sub-title-right label {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  margin-left: 25px;
  cursor: pointer;
}

.reports-boiHistory-container {
  margin: 5px 0 10px 0;
}

.projectEntry-container .file-upload-wrap {
  padding: 19px 20px;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  justify-content: space-between;
  margin: 24px 0px;
}

.projectEntry-container .file-upload-wrap.file-error {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
}

.projectEntry-container .file-upload-wrap.file-success {
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.projectEntry-container .accordion-action-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subdimension-table {
  margin: 20px 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 0px;
}

.subdimension-header {
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
}

.subdimension-header .row {
  padding: 12px 13px 12px 3%;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

.subdimension-header > .row > div,
.subdimension-body .card-header > .row > div {
  padding: 0 5px;
}
/*css for columns based on status*/

@media (min-width: 992px) {
  .projectEntry-container .subdimension-header .row .col-lg-2,
  .projectEntry-container .subdimension-row.accordion .col-lg-2,
  .projectEntry-container .subdimension-header .row .col-lg-1,
  .projectEntry-container .subdimension-row.accordion .col-lg-1 {
    word-break: break-word;
  }

  .projectEntry-container .subdimension-row.accordion div:nth-child(3),
  .projectEntry-container .subdimension-row.accordion div:nth-child(4),
  .projectEntry-container .subdimension-row.accordion div:nth-child(5),
  .projectEntry-container .subdimension-row.accordion div:nth-child(6),
  .projectEntry-container .subdimension-row.accordion div:nth-child(7),
  .projectEntry-container .subdimension-row.accordion div:nth-child(8) {
    padding-left: unset !important;
  }

  .target-value-padding {
    padding-left: unset !important;
  }
}

.boiComm-awapp {
  left: 30px;
}
.wVis-awapp {
  left: 25px;
}
.projectEntry-subcontainer label.mini-toggle-switch {
  top: 6px;
}
.app-rej-awapp {
  left: 40px;
}
.app-rej-rejected,
.app-rej-rejected-new {
  align-self: baseline;
  padding-left: unset;
}
.app-rej-rejected .rvd-rejection-comment #groove-textarea,
.app-rej-rejected-new .rvd-rejection-comment #groove-textarea,
.app-rej-awapp-new .rvd-rejection-comment #groove-textarea {
  width: 80%;
}

.boiComm-rejected {
  right: 23px;
}

.subdimension-section-collapse .subdimension-row .card-header > button {
  justify-content: flex-start;
}

.subdimension-row .card-header {
  padding: 20px 30px;
}

.subdimension-row .card-header div {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.bodytext14 {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.parameter-title-center {
  margin-left: 185px;
}

.false {
  font-family: Graphik-Medium;
}

.bodytext14-regular {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.fa-icons {
  color: rgb(161, 0, 255);
  padding: 3px;
  font-size: x-large;
  cursor: pointer;
}

.data-entries {
  margin: 15px;
  opacity: 0.5;
}

.new-pde {
  margin-bottom: 40px !important;
}

.report-prd {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 24px;
  justify-content: flex-end;
}

.new-proj-btn {
  display: inherit;
  justify-content: flex-end;
}

.subdimension-row .card-header > button .svg-container {
  display: inline;
  margin-right: 5px;
}

.subdimension-row.accordion .card-header {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px !important;
}

.subdimension-row.accordion:first-child .card-header {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
}

.subdimension-row.accordion .card-header .row {
  width: inherit;
}

.subdimension-row.accordion .card-header > button {
  width: 3%;
}

.rvd-subdimension-table-wrap .subdimension-row.accordion .card-header > button {
  width: 3%;
  padding-right: 7px;
}

.subdimension-section-collapse .card-header > button > .pde-subdimension-name {
  display: flex;
  width: 100%;
}

.subdimension-section-collapse .card-header > button > .bodytext18-medium-midnight {
  margin-left: 20px;
}

.parameter-field {
  display: flex;
  align-items: center;
  margin: 10px 0px 50px 0px;
}

.field-text {
  width: 34%;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 19px;
}

.field-value {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.field-value input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px 5px;
  margin: 0px 12px 0px 40px;
}

.field-row-btn {
  display: flex;
  justify-content: center;
}

.subdimension-row.accordion .card {
  border: none;
}

.bodytext14-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.subdimension-row .card-header {
  background: #fff;
}

.subdimension-row .card.error-card {
  background: rgb(255, 245, 247);
  border-radius: 0px;
  border-left: 5px solid #d5002f;
}

.subdimension-row .card.error-card .card-header {
  background: rgb(255, 245, 247);
  padding-left: 0px;
}

img.comment-icon {
  margin-right: -5px;
}

.file-img {
  width: 14px;
  height: 19px;
  margin-right: 5px;
}

.projectEntry-container .errorbox {
  margin-bottom: 24px;
}

.errorMsg {
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 14px;
}

.file-error-list table {
  width: 100%;
}

.file-error-list table,
.file-error-list th,
.file-error-list td {
  border: 1px solid rgb(223, 227, 232);
  border-collapse: collapse;
}

.file-error-list th,
.file-error-list td {
  padding: 6px;
  text-align: left;
  font-size: 12px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 12px;
}

.file-error-list th {
  font-family: Graphik-Medium-Web;
}

.error-list-table {
  margin-bottom: 24px;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 5px;
}

.fa-times {
  cursor: pointer;
}

.attachment-list-container {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px;
}

.attachment-Item:not(:last-child) {
  border-bottom: 1px solid rgb(223, 227, 232);
  margin-bottom: 15px;
}

.view-Attachment-modal .modal-body > div:first-child {
  padding: 16px 30px;
}

.view-Attachment-modal .title,
.view-Attachment-modal .value {
  font-size: 14px;
  line-height: 14px;
}

.view-Attachment-modal .file-img {
  width: 12px;
  height: 16px;
  margin-right: 5px;
}
.subdimension-row #groove-radio-button {
  display: flex;
  align-items: center;
}

.subdimension-row .form-check {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.subdimension-row .form-check-input {
  margin-top: 0.15rem;
}

/* @media (min-width: 992px){
.subdimension-table .col-lg-2:nth-child(2) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
.subdimension-table .col-lg-2:nth-child(3) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
} */

.subdimension-row.accordion .collapse.show ~ div.card-header .btn-link > div.svg-container {
  transform: rotate(90deg);
}

.subdimension-row.accordion .collapse.show ~ div.card-header div.svg-container {
  transform: rotate(0deg);
  cursor: pointer;
}

.validate {
  color: red;
  margin-left: 10px;
}

.rdv-view-comment {
  display: flex;
}

.expandAll-button.active > button,
.collapseAll-button.active > button {
  color: #a100ff;
}

.info-icon {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

.info-icon .svg-container {
  transform: none !important;
  font-size: 0.9em !important;
}

.info-icon > .svg-container > svg {
  width: 11px;
  cursor: pointer;
}

.info-icon.top {
  position: absolute;
  right: -15px !important;
  top: -10px !important;
}
/* 
.ApprovalRequestModal .groove-textbox {
  margin-top: 9px;
} */

.custom-boi {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 10px;
  border: none;
  margin-top: 0.25em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 70px;
  margin-left: 10px;
}

.custom-boi:hover {
  box-shadow: 0 0 5px rgb(187 135 255);
}

.modal-fade-custom-boi {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  height: 1064px;
  width: 1440px;
}

.custom-boi-modal {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modalheader-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  width: 308px;
}

.modalbody-details {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.2px;
  line-height: 20px;
  width: 63px;
}

.modalbody-desc {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 20px;
  width: 67px;
}

.modalbody-column {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modal-custom-BOI-container {
  /* position: fixed; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  height: 100%;
  /* width: 20vw; */
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-Custom {
  width: 70vw;
  height: 80vh;
  /* max-width: 100%; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* background-color: #c47474; */
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row-inside {
  display: flex;
}

.row-outside {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
  display: flex;
}

.row-outside h3 {
  position: absolute;
  margin: 2rem 3rem;
  font-size: x-large;
}

#close {
  position: absolute;
  margin: 1em 65rem;
  color: gray;
  border: none;
  background-color: white;
}

.col-50 {
  flex: 25%;
  margin: 4rem 3rem;
}

.col-50 h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
}

.col-50 p {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.col-25 {
  flex: 12.5%;
}

/* ==========================================CHANGES=================================== */

.modal-body .custom-boi-container {
  height: 400px;
}

.modal-custom-BOI-container .modal-body {
  padding: 1rem !important;
}

.modal-custom-BOI-container .modal-dialog {
  max-width: 800px;
}

.modal-custom-BOI-container h3 {
  font-size: x-large;
  padding-left: 16px;
}

.custom-boi-container {
  padding: 0 25px !important;
}

.modal-custom-BOI-container h6 {
  margin: 0;
  padding-bottom: 4px;
  /* color: rgb(99, 115, 129); */
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.modal-custom-BOI-container .modal-title.h4 {
  font-family: Graphik-Semibold;
  /* color: rgb(45, 58, 75); */
  color: #000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.42px;
}

.modal-custom-BOI-container p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 16px;
  word-break: break-all;
}

.custom-close-Modal {
  height: 16.25px;
  width: 16.25px;
}

.custom-boi-btn #custom-btn span {
  font-size: 11px;
  color: rgb(80, 35, 143);
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

#groove-btn-id.custom-boi-btn #custom-btn.btn-outline-stat-alternate {
  padding: 5px;
  background: rgb(235, 225, 248);
  border-radius: 16px;
  border: none;
}

.custom-boi-empty {
  padding-bottom: 24px;
}

.custom-boi-param {
  padding-left: 0px !important;
}
.param-char-length-err {
  color: red;
  margin-left: 37px;
  font-size: 13px;
  padding-top: 4px;
  height: 1px;
}

.warning-message-parameter {
  padding-left: 5%;
  width: 30%;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  color: #ffbd00;
}

.rdv-refresh-list {
  display: flex;
  margin-left: 20px;
}
.rdv-refresh-list button {
  color: #a100ff !important;
}
.rdv-refresh-list button > span {
  font-size: 16px !important;
}

#rvd-refresh-tooltip-bottom .tooltip-inner {
  max-width: 250px;
  padding: 8px 15px;
  font-size: 12px;
}
/* Rejection Comment CSS */
.rvd-rejection-comment {
  display: block !important;
}
.rvd-rejection-comment #groove-textarea {
  position: absolute;
  margin: 10px 0px;
  top: 20px;
  width: 68%;
}
.rvd-rejection-comment #groove-textarea .groove-textbox {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}

.rvd-rejection-comment .floating-label-textbox label {
  padding: 0px !important;
}

.rvd-rejection-comment .floating-label-textbox {
  border-top: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}
.rvd-rejection-comment div.groove-textbox-custom-label,
.rvd-rejection-comment div.groove-input-custom-label {
  color: #919eab !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 12px;
  margin-top: -9px;
}
.rvd-rejection-comment
  #groove-textarea
  .floating-label-textbox
  .groove-textbox.is-invalid:not(:placeholder-shown):disabled
  + label {
  transform: translateY(0);
}

/* #boi-tooltip-right .tooltip-inner {
  -webkit-box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #000;
  padding: 15px 15px;
  min-width: 550px !important;
  max-width: 650px !important;
  margin-bottom: 75px;
} */
#boi-tooltip-right.bs-tooltip-right .arrow:before {
  border-right-color: #fff !important;
}

.boi-details-wrap h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
  text-align: left;
}

.boi-details-wrap p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
  text-align: left;
}
/* View Aggregated BOI Details CSS */
.aggregated-boi-popover {
  width: 285px;
  height: 50px;
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
}

#aggregated-boi-popover,
#aggregated-boi-popover .popover-body {
  width: 634px;
  height: 651px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 5;
}

.aggregated-boi-popover-header {
  width: 100%;
  display: flex;
  height: 30px;
  padding: 5px 14px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-title {
  width: 90%;
  height: 25px;
  color: rgb(109, 114, 120);
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-close-btn {
  width: 10%;
  color: rgb(33, 43, 54);
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
  padding-left: 40px;
}

.aggregated-boi-accordion-container {
  max-height: 582px;
  overflow-y: auto;
  padding: 2px;
}

.visualization-collapse.accordion .card {
  margin-bottom: 24px;
  border-radius: 5px !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.visualization-collapse.accordion .card:last-child {
  margin-bottom: 0px;
}
.visualization-collapse.configure-client-collapse .card-header {
  padding: 20px 25px;
}

.visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 25px 20px 25px;
}

.dim-tabs > div > div > div > div {
  margin-bottom: -7px !important;
  margin: 0 -15px;
}

.dim-tabs .MuiTabs-scroller.MuiTabs-scrollable > div {
  margin: 0 !important;
  width: min-content;
}

.aggregated-boi-detail-tabs button > span:first-child {
  text-transform: initial;
  min-width: max-content;
  padding: 0 10px !important;
}

.aggregated-boi-detail-tabs button span {
  padding: 0;
  letter-spacing: -0.21px;
  text-align: center;
  word-wrap: break-word;
  display: block;
  max-width: 200px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.aggregated-boi-detail-tabs button > span:nth-child(2) {
  display: none;
}

.aggregated-boi-detail-tabs button {
  border: 3px solid transparent;
}

.aggregated-boi-detail-tabs button > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.aggregated-boi-detail-tabs button > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='true'] {
  border-bottom-color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='false'] span,
.aggregated-boi-detail-tabs button[aria-selected='false'] span span span {
  color: rgb(45, 58, 75);
}

.aggregated-boi-detail-tabs > div > div > span {
  display: none;
}

.aggregated-boi-detail-tabs span.MuiTabs-indicator {
  display: none;
}

#aggregated-info-icon {
  margin: 0 10px !important;
}

.aggregated-boi-label-container {
  height: 45px;
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  padding: 15px 15px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.aggregated-boi-left-label {
  width: 70%;
  border-right: 1px solid #e7eaee;
  padding-right: 5px;
  display: flex;
}
.aggregated-boi-left-label .aggregated-boi-left-text {
  /* width: 70%; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aggregated-boi-right-label {
  width: 35%;
  padding-left: 5px;
}

.aggregated-boi-title-label #aggregated-info-icon > .svg-container > svg {
  font-size: 14px !important;
}

#tooltiptext-aggregated {
  top: 20px !important;
  clear: both !important;
  left: 10px !important;
  box-shadow: 0 0 2px black;
}

div[role='tabpanel'] > div.aggregated-tabpanel {
  max-height: 500px;
  overflow-x: hidden;
  padding: 10px;
  overflow-y: auto;
  display: inline;
}

.tooltip-parameter {
  margin-right: 50px;
}
.reporting-period-normal {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
}
.reporting-period-highlight {
  font-family: Graphik-Medium;
  font-size: 14px;
  line-height: 18px;
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}
.rvdboi-new-info .new-info-icon {
  transform: scale(1);
  margin-top: 0px;
}

.aggregated-info.info-icon-wrap {
  margin-top: -14px;
}
.rvd-invalid-realizedval {
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}

#groove-radio-button.approve-reject-radio-button input.form-check-input {
  margin-top: 0;
  height: 20px;
  width: 20px;
  content: '';
  display: inline-block;
  visibility: visible;
  -moz-appearance: initial;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:after {
  content: '';
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1.5px solid #6c757d;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:hover:after,
#groove-radio-button.approve-reject-radio-button
  input.form-check-input[type='radio']:checked:after {
  height: 20px;
  width: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
}

#groove-radio-button.approve-reject-radio-button
  input.form-check-input[type='radio']:checked::before {
  content: '';
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: rgb(161, 0, 255);
}

#groove-radio-button.approve-reject-radio-button .form-check > label {
  margin-left: 3px !important;
}

.aggregated-info img.new-info-icon {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

#boi-tooltip-right.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff;
}

.subdimension-header > .row > div.col-lg-1.wVis-false > a,
.subdimension-header > .row > div.col-lg-1.wVis-awapp-new > a {
  position: absolute;
  right: 0.1px;
}
