.tileCanned {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
}

.tileAdhoc {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  margin-left: 50px;
}

.tileContainer {
  padding: 80px 0;
  margin: 0 40px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.tileContainer.row>div {
  display: flex;
  min-height: 240px;
  max-width: 580px;
  height: fit-content;
  width: 49%;
}

.a {
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 28px;
  letter-spacing: -0.35px;
  line-height: 28px;
  width: 91px;
  margin-top: 10px;
}

.b {
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 28px;
  letter-spacing: -0.35px;
  line-height: 28px;
  width: 263px;
  margin-top: 10px;
}

.c {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 36px;
  letter-spacing: -0.25px;
  line-height: 18px;
  width: 266px;
  margin-top: 10px;
  display: block;
}

.d {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.22px;
  width: 97px;
  white-space: nowrap;
}

.img {
  margin-top: 40px;
  margin-bottom: 10px;

}

.leftbutton {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 40px;
  width: 40px;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  align-items: center;
  margin: auto;
  margin-bottom: 50px;

}

a:link, a[href^="/DataIngestion"] {
  color: rgb(161, 0, 255)
}

.progress-animation .loading-animation {
  display: block;
  text-align: center;
  position: relative;
}

.data-extract-completed, .data-extract-failed {
  text-align: center;
}

.data-extract-completed h6,
.download-content h6,
.data-extract-failed h6 {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
}

.data-extract-spinner.spinner-border {
  color: rgb(161, 0, 255) !important;
  width: 3em;
  height: 3em;
}

.data-extract-failed svg {
  width: 5em !important;
  height: 5em !important;
}

.data-extract-modal-container {
  max-width: 700px;
}