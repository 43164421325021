.ApprovalRequestModal {
    overflow-y: visible!important;
}

.ApprovalRequestModal .submit-question {
    color: #2D3A4B;
    font-size: 18px;
    font-family: Graphik-Regular-Web;
    font-weight: normal;
    letter-spacing: -0.315px;
    line-height: 16px;
    margin-bottom: 12px;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal>.notifyModal-buttons.add-program-buttons{
    padding-bottom: 0px !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal>.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label{
    font-size: 11px !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.ApprovalRequestModal-invalid-comment {
    outline: 1px solid #aa0025 !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .groove-textbox{
    font-size: 16px !important;
    letter-spacing: -0.35px;
}

.notifyModal-dialog.submit-modal .modal-title.h4 .header24-semibold-midnight{
    color: #2D3A4B;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label,
.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .floating-label-textbox .groove-textbox:focus+label{
    color: #637381 !important;
}
