@media only screen and (max-width: 2020px) and (min-width: 1821px) {
  .col-sm-2.target-realized-approved {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
  }
  .col-sm-1.wVis-approved.dashboard-visibility-rvd {
    flex: 0 0 10.33333%;
    max-width: 10.33333%;
  }
}

@media only screen and (max-width: 1200px) {
  /* Program ALA*/
  .dimension-table.dimension-table-program,
  .ALATable,
  .boi-history .dimension-table,
  .dimension-table,
  .rvd-subdimension-table-wrap,
  .file-history-container {
    overflow-x: scroll;
  }
  .dimension-table .dimension-table-header,
  .dimension-table .dimension-table-body,
  .ALATable .dimension-table-header,
  .ALATable .dimension-table-body,
  .rvd-subdimension-table,
  .file-history-container .select-boi-header,
  .file-history-container .select-boi-body,
  .ag-grid-table-wrap > div {
    width: 1100px;
  }
  .AA-accordion-dropdown div#dropdown-list {
    max-height: 94px;
  }
  /* ADD BOI MODAL */
  /* .added-boi-container, .add-boi-box-container{
    overflow-x: scroll !important;
  } */
  /* .add-boi-field-container{
    width: 1100px;
  } */

  /*BOI HISTORY*/
  .boi-history-content {
    overflow: hidden;
  }

  /* POP OVER */
  #boi-tooltip-right.bs-tooltip-top .tooltip-inner {
    margin-bottom: 0;
  }
  #boi-tooltip-right.bs-tooltip-top .arrow:before {
    border-top-color: #fff !important;
  }

  /* Project Config*/
  .add-boi-modal .select-boi-modal-column:first-child {
    width: 50px !important;
  }
  .add-boi-modal .select-boi-modal-column {
    padding-right: 5px;
    width: 15% !important;
  }
  .program-lead-landing
    .applyFlex-align-center.apply-relative
    .single-select-dropdown
    .single-select-label {
    width: 25% !important;
  }
  .fileupload-browse {
    text-align: center;
  }

  /* RVD File history*/
  .file-history-container .file-history-column:first-child {
    width: 5% !important;
  }
  .file-history-container .file-history-column:nth-child(2) {
    width: 33% !important;
  }
  .file-history-container .file-history-column:nth-child(3) {
    width: 25% !important;
  }
  .file-history-container .file-history-column:nth-child(4) {
    width: 20% !important;
  }
  .file-history-container .file-history-column:nth-child(5) {
    width: 17% !important;
  }

  .client-story-filename,
  .clientstory-download {
    width: 50% !important;
  }

  .rvd-subdimension-table-wrap .app-rej-awapp {
    max-width: 20%;
  }

  .ag-grid-table-wrap {
    overflow-x: scroll;
  }
  .ag-center-cols-viewport {
    overflow: unset !important;
  }
  .ag-body-horizontal-scroll-viewport {
    display: none;
  }

  /* ADMIN */
  .iomanagement-table-container .iouser-table tr th:nth-child(2),
  .iomanagement-table-container .iouser-table tr td:nth-child(2) {
    width: 20% !important;
  }

  /* Dashboard */
  .col-md-5.graph-view-section {
    flex: 0 0 100%;
    max-width: 35%;
    padding-left: 0;
    padding-right: 0;
  }
  .col-md-7.details-view-section {
    flex: 0 0 100%;
    max-width: 65%;
  }
  .cumulative-container {
    padding: 10px;
  }
  .visualization-collapse.configure-client-collapse .card-body {
    padding: 0px 10px 20px 10px !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px){
  .graph-view-section .recharts-pie-labels > g.recharts-layer > text > title ~ tspan {
    font-size: 6px!important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px){
  .program-card-detail {
    flex-wrap: wrap;
  }
  .program-card-wrap {
    grid-auto-rows: 45px 31% 26% auto auto auto !important;
    height: 69% !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px){
  .graph-view-section .recharts-pie-labels > g.recharts-layer > text > title ~ tspan {
    font-size: 6px!important;
  }
}

@media only screen and (max-width: 1100px) {
  g.recharts-layer text, g.recharts-layer text tspan {
    font-size: 6px !important;
  }
}

@media only screen and (max-width: 1044px) {
  .data-visualisation-container .title-section {
    flex-wrap: wrap;
  }

  .data-visualisation-container .value-meter-title {
    width: 100%;
  }
  .data-visualisation-container .dIL-dropdowns {
    margin-top: 10px;
  }

  /*RVD*/
  #aggregated-boi-popover,
  #aggregated-boi-popover .popover-body {
    width: 550px !important;
  }
}

@media only screen and (max-width: 1031px) {
  /* RVD CLIENT DETAILS */
  .projectEntry-container .sub-title > div {
    width: 100%;
  }
  .projectEntry-container .sub-title {
    flex-wrap: wrap;
  }
  .sub-title-header.row > div {
    width: 50%;
    margin: 0;
    justify-content: flex-end;
  }
  .projectEntry-container .sub-title .sub-title-header h5 {
    text-align: left;
  }
  .sub-title .button-container > .pde-btn {
    width: 33.33%;
  }
  .sub-title .button-container > .pde-btn > div {
    width: 100%;
    justify-content: center;
    position: relative;
  }
  .reset-all-button img {
    left: 20px;
  }
  .projectEntry-container .sub-title .button-container {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 1024px) {
  /* ADD BOI MODAL */
  /* .added-boi-container, .add-boi-box-container{
    overflow-x: scroll !important;
  } */

  .customboi-card-collapse > button#primry-btn-1 {
    width: 120px;
    margin-right: 20px;
  }
  .accordion-action-btns button#primry-btn-2 {
    width: 140px;
    justify-content: center;
  }
  .customboi-card-collapse button#primry-btn-1 {
    width: 85px;
    margin-right: 10px;
  }
  .lead-options {
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .lead-options label.custom-boi-label {
    padding-right: 0;
  }
  .custom-boi-radio {
    flex-wrap: wrap;
  }
  .lead-options,
  .lead-title {
    width: 100%;
  }
  .customboi-header-btn #groove-btn-id {
    margin-left: 0px !important;
  }

  .delete-cusboi button#primry-btn-1,
  .update-cusboi button#primry-btn-1 {
    width: 110px;
    padding: 5px 12px;
    justify-content: center;
  }

  /* BOI POPOVER*/
  #boi-tooltip-right.bs-tooltip-top .tooltip-inner {
    margin-bottom: 0;
    max-width: 500px !important;
    min-width: 500px !important;
  }
  #boi-tooltip-right.bs-tooltip-top .arrow:before {
    border-top-color: #fff !important;
  }
  #boi-tooltip-right.bs-tooltip-left .arrow:before {
    border-left-color: #fff !important;
  }
  #boi-tooltip-right .tooltip-inner {
    max-width: 500px !important;
    min-width: 500px !important;
  }

  /* Project Config*/
  .select-boi-modal-column {
    width: 25% !important;
  }

  /* RVD */
  .new-pde .applyFlex-align-center.apply-relative .single-select-dropdown .single-select-label {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  /* Home */
  .modules-cards-container > div {
    margin-bottom: 20px;
  }

  /* Modal */
  .modal-dialog.modal-md {
    max-width: 85% !important;
  }

  /* Program ALA*/
  .program-lead-landing
    .applyFlex-align-center.apply-relative
    .single-select-dropdown
    .single-select-label {
    width: 90px;
  }

  /* ADD BOI MODAL */
  .add-boi-field-container {
    flex-wrap: wrap;
  }

  .add-boi-field-container .select-boi-dropdown {
    flex: 50% !important;
    max-width: unset;
    margin-bottom: 5px;
    width: 50% !important;
  }

  .add-boi-field-container .select-boi-dropdown:first-child,
  .add-boi-field-container .select-boi-dropdown:nth-child(2) {
    margin-bottom: 5px;
  }

  #groove-select-dropdown .default-display {
    width: 75% !important;
  }

  .tabbed-dropdown-value {
    width: 80% !important;
  }

  .addboi-dropdown-containers .addedBOI-dropdown {
    max-width: unset !important;
    width: 50%;
  }

  .addboi-dropdown-containers {
    width: 90% !important;
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
  }

  .new-info-wrap,
  .delete-boi {
    width: 5% !important;
  }

  .addboi-dropdown-containers .addedBOI-dropdown:first-child,
  .addboi-dropdown-containers .addedBOI-dropdown:nth-child(2) {
    margin-bottom: 5px;
  }

  /* BOI POPOVER*/
  .new-info-icon {
    margin-left: -3px !important;
  }

  /* Project Config */
  .program-dropdown-project-landing .single-select-label {
    width: 20% !important;
  }
  .project-header-select .program-dropdown,
  .project-header-select .project-dropdown {
    max-width: 95% !important;
    flex: 100% !important;
    width: 100% !important;
  }
  .project-header-select .project-dropdown {
    margin-top: 5px;
  }
  #programDetails-popover.program-popover {
    left: unset;
    right: 325px;
  }
  #programDetails-popover.program-popover .arrow {
    right: calc(-0.5rem - 1px) !important;
    height: 1rem;
    margin: 0.3rem 0;
    left: unset;
  }
  #programDetails-popover.program-popover .arrow:before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
    left: unset;
    border-right-color: unset;
  }
  #programDetails-popover.program-popover .arrow:after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
    left: unset;
    border-right-color: unset;
  }
  /* Custom Boi */
  .program-details-row {
    border-right: 0 !important;
  }
  .fade.modal-custom-BOI-container.modal.show {
    padding: 0 20px;
  }

  /* RVD */
  .client-details-button {
    flex-wrap: wrap;
  }
  .rvd-client-details-btn .dIL-dropdowns {
    width: 100%;
  }
  .dIL-dropdowns.dIL-actionrow {
    justify-content: flex-start;
  }
  .new-proj-btn {
    padding-left: 0 !important;
  }
  .new-pde > div:first-child {
    margin-bottom: 5px;
  }
  .projectEntry-subcontainer .project-title,
  .projectEntry-subcontainer .report-prd {
    width: 50% !important;
  }
  .modal-dialog.rvd-commentary-history-modal.modal-dialog-centered {
    padding-left: 15px;
    padding-right: 15px;
  }
  #aggregated-boi-popover,
  #aggregated-boi-popover .popover-body {
    width: 363px !important;
  }
  .aggregated-boi-accordion-container {
    overflow-y: unset;
  }
  .aggregated-boi-accordion-container div#scrollable-auto-tabpanel-0 {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .aggregated-boi-label-container {
    width: 400px;
  }
  .document-name {
    word-break: break-all;
  }
  .envelope {
    padding: 0 10px !important;
  }
  /* Dashboard */
  .data-visualisation-container .statusDropdown {
    width: 390px;
  }

  .data-visualisation-container .statusDropdown .css-1csqycf-control,
  .data-visualisation-container .statusDropdown .css-144vaf5-menu,
  .data-visualisation-container .statusDropdown .css-14s1juc-control {
    width: 369px !important;
  }

  /* ADMIN */
  .iomanagement-table-container .iouser-table tr th:nth-child(2),
  .iomanagement-table-container .iouser-table tr td:nth-child(2) {
    width: 30% !important;
  }
  .iomanagement-table-container .iouser-table tr th:nth-child(1),
  .iomanagement-table-container .iouser-table tr td:nth-child(1) {
    width: 5px !important;
    padding: 0;
  }
  .select-offerings {
    margin-bottom: 1rem;
  }
  .select-offerings div#dropdown-popover,
  .select-sub-offerings div#dropdown-popover {
    left: 14px;
    bottom: 57px;
  }
  .select-profile div#selectmenu-dropdown-icon {
    margin-right: 4px !important;
  }
  .selected-profile-container-background > .row {
    flex-wrap: wrap;
  }
  #groove-select-dropdown
    div.selectmenu-options
    .searchable-dropdown-content
    .searchable-dropdown-input {
    text-overflow: ellipsis;
    width: 75%;
  }
  .upload-pending-desc {
    width: 100%;
    padding: 0 15px;
  }
  .selected-sub-offerings div#dropdown-popover {
    left: 14px !important;
    bottom: 86px !important;
  }
  .selected-profile-container-background > div.row > div {
    padding-bottom: 0;
  }
  .selected-offerings.col-md-6 {
    padding-right: 0;
  }
  .selected-offerings.col-md-5 {
    max-width: 46%;
    flex: 100%;
  }
  .delete-profile-wrap {
    width: 4%;
    margin-top: 15px;
  }
  .selected-sub-offerings {
    max-width: 96%;
    flex: 100%;
  }
}

@media only screen and (max-width: 922px) {
  /* Project Config */
  .project-config-select {
    flex-wrap: wrap;
  }
  .landing-img-box {
    margin-left: 10px !important;
  }
  .landing-img-box img:first-child {
    margin-left: 0px !important;
    margin-right: 0 !important;
  }
  .project-config-select > div:nth-child(2) {
    width: 100%;
    margin-top: 5px;
  }

  /* DASHBAORD */
  .visualization-collapse.configure-client-collapse .card-body {
    padding: 0 10px 20px !important;
  }
  .visualization-collapse.configure-client-collapse .card-header {
    padding: 10px 25px !important;
  }

  /*RVD*/
  .rvd-new-project-data-entry-modal {
    max-width: 540px !important;
  }
}

@media only screen and (max-width: 814px) {
  /* Project Config */
  .landing-img-box {
    width: 50px;
  }
  .landing-img-box img:nth-child(2) {
    margin-left: 15px !important;
  }
  .sort-icon {
    margin-left: 5px;
  }
  .add-project-button {
    width: 176px;
  }
}

@media only screen and (max-width: 767px) {
  /* Header */
  .header_title > div {
    display: flex;
  }
  /* Footer */
  .trademark {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 575px) {
  /* HEADER */
  #header_container {
    height: 80px;
  }
  .header_title {
    padding: 0 15px !important;
  }
  .header_title div {
    margin: 0 auto;
  }
  #navigation {
    padding: 0 15px;
    justify-content: center;
  }

  /* BANNER */
  .cvm-banner > div:first-child {
    padding: 100px 10px 170px 10px;
  }

  img.overlay-image {
    width: 100%;
  }

  /* Footer */
  .trademark {
    margin: 0;
    text-align: right;
  }
  .footer-links {
    margin-top: 5px;
  }
  #footer-logo {
    order: 1;
  }
  #company-copyright {
    order: 3;
  }
  #footer-terms {
    order: 2;
  }

  /* Modal */
  .modal {
    padding: 0;
  }
  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .modal-dialog {
    margin: 0 auto !important;
  }

  .modal-dialog.modal-md {
    max-width: 90% !important;
  }

  .search-bar-container {
    position: relative;
  }

  .search-bar > input {
    width: 100%;
  }
  /* RVD Module */
  .projectEntry-subcontainer .button-container .pde-btn {
    justify-content: flex-end;
    width: 100%;
  }
  .projectEntry-subcontainer .button-container .pde-btn:nth-child(2) {
    padding: 10px 0px !important;
  }
  .projectEntry-subcontainer .button-container {
    display: block !important;
  }
}
