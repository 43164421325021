.configure-client {
  margin: 0 0 0 7px;
}

.configure-client button > * {
  font-family: "Graphik-Medium";
  font-size: 14px;
  margin: 0 5px;
}

.configure-client button svg {
  width: 13px !important;
}

.reporting-year-multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 40px;
  display: flex;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 24px;
  cursor: pointer;
  max-width: 500px;
}

.reporting-year-multi-select-dropdown > svg {
  margin-left: auto;
  font-size: 11px;
}

.selected-years {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
}

.selected-years > div {
  display: flex;
  margin: 0 4px;
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  height: 24px;
  width: 79px;
  padding: 0 6px 0 16px;
  align-items: center;
  font-size: 12px;
  cursor: context-menu;
}

.remove-selected {
  transform: rotate(-45deg);
  font-size: 20px;
  width: auto;
  margin-left: auto;
  cursor: pointer;
}
