.modal-content {
    border-radius: 16px !important;
    height: 95%;
  }
  .modal-header {
    border: none !important;
    padding: 40px 40px 0 40px !important;
  }
  
  .modal-header {
    overflow: hidden;
    margin-right: 0px;
    margin-left: 0px;
    word-break: break-all;
    
  }
  .modal-title.h4 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  
  .modal-title.h4 > div {
    display: flex;
  }
  
  .modal-title.h4 .close-modal-btn {
    /* background: rgb(145, 158, 171); */
    height: 20px;
    width: 15px;
    color: rgb(145, 158, 171);
    cursor: pointer;
  }
  
  .modal-body {
    padding: 0 !important;
  }
  
  .modal-body > div:first-child {
    padding: 16px 40px;
    max-height: 65%;
    overflow-y: auto;
  }
  
  .add-boi-popup {
    overflow: visible !important;
  }
  
  .tab-component-purple .react-tabs__tab--selected {
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: 4px solid #7500cc !important;
    border-right: 4px solid transparent !important;
    border-left: 4px solid transparent !important;
  }
  
  .tab-component-purple .react-tabs__tab {
    padding: 14px 12px;
    margin: 0 10px 0 0;
  }
  
  .modal-95w {
    max-width: 95% !important;
    height: 90% !important;
  }
  
  .replacement-modal-message{
    color: rgba(145,158,171,1);
    font-family: "Graphik-Regular";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  .replacement-modal-header{
    color: rgba(21,24,27,1);
    font-family: "Graphik-Semibold";
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.28px;
    text-align: left;
  }

  .replacement-modal {
    max-width: 590px !important;
  }

  .replacement-multiselect-dropdown,
  .single-dropdown-multi-view {
    padding: 20px 0px;
  }

  .replacement-buttons {
    display: flex;
    justify-content: space-between;
  }

  .replacement-buttons div:first-child {
    padding-right: 15px;
  }

  .replacement-modal #groove-input #newUser.invalid.groove-input {
    border: 1px solid red;
  }

  .replacement-buttons .button-container {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  }

  .replacement-multiselect-dropdown > .multi-select-dropdown-with-count > #mt-count-tooltip > .css-1pcexqc-container > .css-1szy77t-control,
  .replacement-multiselect-dropdown > .multi-select-dropdown-with-count > #mt-count-tooltip > .css-1pcexqc-container > .css-bg1rzq-control { 
    top: 15px;
  }

  .replacement-multiselect-dropdown > .blank-multi-field > #mt-count-tooltip > .css-1pcexqc-container > .css-1szy77t-control,
  .replacement-multiselect-dropdown > .blank-multi-field > #mt-count-tooltip > .css-1pcexqc-container > .css-bg1rzq-control { 
    top: 0px !important;
  }

  .replacement-multiselect-dropdown > .multi-select-dropdown-with-count > #mt-count-tooltip > .css-1pcexqc-container > .placeholder-title { 
    display: none;
  }