.ALATable,
.program-approval-history,
.ALATable .card-header .btn-link {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}
.ALATable .dimension-table-header,
.program-approval-history-content .dimension-table-header {
  color: rgb(33, 43, 54);
  letter-spacing: 0px;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
  padding: 12px 24px;
  border-bottom: none;
}
.dimension-table-collapse {
  width: 100%;
}

.ALATable .dimension-table-row > div button > span,
.program-approval-history-content .dimension-table-row > div button > span {
  font-size: 12px !important;
}

.dimension-table-row,
.ALATable .card-header .btn-link {
  width: 100%;
  display: flex;
  align-items: baseline;
  text-align: left;
  justify-content: space-between;
}
.program-approval-history-content .dimension-table-body .dimension-table-row {
  padding: 16px 24px 16px 24px;
  border: 1px solid #E7EAEE;
  border-width: 1px 0px;
}

.ALATable .dimension-table-body,
.program-approval-history-content .dimension-table-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px 24px;
}
.program-approval-history-content .dimension-table-body {
  padding: 0;
  height: 350px;
  overflow: overlay;
}
.dimension-table-row > div,
.dimension-table-row-approved > div {
  padding: 0px 0px;
  margin-right: 12px;
  word-break: break-word;
}

.ALATable .dimension-table-row > div:first-child {
  width: 25%;
}

.ALATable .dimension-table-row > div:nth-child(2) {
  width: 16%;
  word-break: break-all;
}
.ALATable .dimension-table-row > div:nth-child(3) {
  width: 20%;
  position: relative;
}
.ALATable .dimension-table-row > div:nth-child(4) {
  width: 13%;
}
.ALATable .dimension-table-row > div:nth-child(5) {
  width: 15%;
}

.ALATable .dimension-table-row > div:nth-child(6) {
  width: 14%;
}

.ALATable .dimension-table-row {
  display: flex;
  justify-content: space-between;
}

.ALATable .dimension-table-row > div,
.program-approval-history-content .dimension-table-row > div {
  letter-spacing: -0.22px;
  line-height: 16px;
}

.dimension-table-collapse .card {
  border-radius: 0px;
}
.ALATable .card-header,
.ALATable .card-header .btn-link {
  padding: 8px 12px;
}
.ALATable .card-header {
  padding: 8px 12px;
  border: none;
  background: #fff;
}
.ALATable .card-body {
  padding: 16px 24px;
}

.program-status {
  background: rgb(255, 200, 44);
  border-radius: 3px;
  text-align: center;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold;
  font-size: 11px;
  text-transform: uppercase;
}

.dimension-table-row input[type='text'] {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 100%;
}
.dimension-table-collapse .subdimension-rows {
  border-top: 1px solid rgb(223, 227, 232);
}
.dimension-table-collapse .card-body {
  padding: 0;
}
.dimension-table-collapse .card-body .dimension-table-row {
  padding: 16px 0;
}
.dimension-table-collapse .collapse.show ~ div.card-header .btn-link > div.svg-container {
  transform: rotate(90deg);
}
.dimension-table-collapse .collapse ~ div.card-header .dimension-table-row > div {
  display: none;
}
.dimension-table-collapse .collapse ~ div.card-header .dimension-table-row > div:first-child {
  display: block;
}
.dimension-table-collapse .collapse.show ~ div.card-header .dimension-table-row > div {
  display: block;
}

.text-field {
  width: 265px;
  height: 78px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(181, 192, 202);
  border-radius: 4px;
}

textarea {
  width: 100%;
  height: 100%;
  background: white;
}

.approved-edit-modal .modal-header {
  padding: 20px 30px !important;
  margin: 0;
}

.approved-edit-modal {
  max-width: 600px !important;
}

.approved-edit-bold {
  font-weight: 600;
}

.approved-edit-modal .modal-body > div:first-child {
  padding-bottom: 20px;
  width: 100%;
  margin: 0px;
}
.approved-edit-modal .modal-body > div > div:nth-child(2) {
  width: 100% !important;
}

.approved-edit-btn {
  display: flex;
  padding: 30px 0px 0px !important;
}

.approved-edit-btn > div:first-child {
  margin-left: auto;
}

.approved-edit-btn > div:nth-child(2) {
  margin-left: 10px;
}

.warning-edit-icon {
  width: 50px !important;
  height: 50px;
  margin-left: 25px;
}

.calendar-icon-dropdown {
  cursor: pointer;
}

.disabled-editIcon > #calendarIcon > .calendar-icon-dropdown {
  cursor: not-allowed;
  opacity: 0.5;
}

.calendar-icon-dropdown.disabled-edit {
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events: none;
}


/* ProgramApprovalHistoryModal CSS */
#groove-btn-id > button#btn-prog-approv-history {
  cursor: pointer;
  padding-left: 0;
}
.btn-prog-approv-history-container {
  display: inline-block;
}
#groove-btn-id > button#btn-prog-approv-history:active,
#groove-btn-id > button#btn-prog-approv-history:focus,
#groove-btn-id > button#btn-prog-approv-history:hover {
  background: transparent;
}

#groove-btn-id > button#btn-prog-approv-history > * {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 0;
}

.programApprovalHistoryModal {
  max-width: 85vw!important;
}
.programApprovalHistoryModal .header24-semibold-midnight {
  width: 70%;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  line-height: 115%;
}
.pah-search-bar {
  display: flex;
  align-content: center;
  align-items: center;
}
.pah-search-bar > .svg-container.large {
  position: relative;
  left: 30px;
}
.pah-search-bar >input {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e7eaee;
  outline-style: none;
  width: 240px;
  padding: 5px 10px 5px 40px;
}