.dimension-table .dimension-table-header .specific-history-header {
  width: 100% !important;
  align-items: center;
}

.dim-header-container {
  width: 100% !important;
}

.dimension-boi-history {
  line-height: 1.4em;
}
.dimension-boi-history > div > .boi-divider {
  position: relative;
  padding: 0 5px;
}

.dimension-boi-history > div > .boi-divider:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 130%;
  background-color: rgb(99, 115, 129);
}

.dimension-boi-history > div:nth-child(6) {
  display: block;
}

.dimension-boi-history > div:first-child,
.dimension-boi-history > div:nth-child(2) {
  width: 15% !important;
}
.dimension-boi-history > div:nth-child(3) {
  width: 20%;
  position: relative;
}
.dimension-boi-history > div:nth-child(4) {
  width: 12%;
}
.dimension-boi-history > div:nth-child(5) {
  width: 12% !important;
}
.dimension-boi-history > div:nth-child(6) {
  width: 12% !important;
}
.dimension-boi-history > div:nth-child(7) {
  width: 20%;
}
.dimension-boi-history > div:nth-child(8) {
  width: 20%;
}

.dim-card-header {
  padding: 0 24px !important;
}

.card {
  padding: 8px 0 !important;
}

.boi-column > div {
  display: flex;
  align-items: center;
  width: auto;
  word-break: break-word;
}
