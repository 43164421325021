.sub-header {
  /* width: 1360px;
  height: 112px; */
  height: 56px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(33, 43, 54, 0.1);
  border-radius: 0px 0px 16px 16px;
  padding: 18px 34px;
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 18px;
  margin: 0 40px;
}
.sub-header .fa-chevron-left {
  margin-right: 26px;
}
#module-container {
  width: 100%;
  display: block;
  background: #f9fafb;
  position: relative;
  overflow-y: auto
}
.left-sidebar { /*left -> top navbar*/ 
  height: 35px;
  background: rgb(255, 255, 255);
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1),
    1px 0px 0px 0px rgb(244, 246, 248);
  border-radius: 0 0 8px 8px;
  padding: 0px 10px 0 0;
  text-align: center;
  position: absolute;
  z-index: 1;
  width: -webkit-fill-available;
}

.left-sidebar ul{
  display: flex;
}
.left-sidebar ul li {
  padding: 7px 15px;
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
  height: 35px;
  font-size: 14px;
}
.left-sidebar ul li.active,
.left-sidebar ul li:hover {
  background: #ede3f4;
}

.left-sidebar ul li.active span,
.left-sidebar ul li:hover span {
  font-family: "Graphik-Medium" !important;
}

.left-sidebar ul li.active img,
.left-sidebar ul li:hover img {
  filter: brightness(1) sepia(1) hue-rotate(207deg) saturate(25);
}

.left-sidebar ul li span {
  margin-left: 10px;
  vertical-align: middle;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  margin: 40px;
  max-width: calc(100% - 80px);
  min-height: 80vh;
}

.right-section.global-dashboard-rs{
  padding: 0 !important;
  margin: 40px 0 0 40px !important;
}

.right-section-comment {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.title-section {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 12px 0;
}
/* Shared */
.title-section h4 {
  color: rgb(33, 43, 54);
  font-family: "Graphik-Semibold";
  font-size: 24.1px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  line-height: 24px;
  margin: 0;
  margin-right: auto;
}

.project-title .title-section h4 {
  color: rgb(33, 43, 54);
  font-family: "Graphik-Medium";
  font-size: 24.1px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  line-height: 24px;
  margin: 0;
  margin-right: auto;
}

.program-dropdown-project-landing {
  display: flex;
  align-items: center;
  margin: 30px 0 15px 0;
}

.program-dropdown-project-landing > svg {
  cursor: pointer;
}

.left-sidebar ul li.no-access {
  cursor: default;
  filter: grayscale(1);
  opacity: 0.5;
}

.left-sidebar ul li.no-access:hover img {
  filter: none;
}

.left-sidebar ul li.no-access:hover {
  background: transparent;
  cursor: not-allowed;
}

.select-client-container {
  min-height: 600px;
  vertical-align: middle;
  /* text-align: center; */
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}

.applyFlex-justify-center .select-client-container {
  min-height: 0px;
}

.select-client-container img {
  margin-bottom: 30px;
  height: 90px;
  width: 90px;
}

.select-client-container span {
  color: rgb(181, 192, 202);
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.28px;
}

.select-client-container p {
  color: rgb(181, 192, 202);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  height: 29px;
  letter-spacing: -0.38px;
  margin-bottom: 24px;
}

.dashboard-no-data {
  width: 100%;
  min-height: 400px;
}

/*Responsive Nav Bar & header*/
@media (min-width: 426px) and (max-width: 852px) { 
  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    justify-content: center;
  }

  .left-sidebar ul li:hover {
    width: auto;
  }
  
  .left-sidebar ul li:hover span,
  .left-sidebar ul li:active span{
    display: inline;
    width: 100%;
    text-align: left;
    margin-left: 10px;
  }
}

@media (max-width: 426px){ /*MOBILE*/
  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    justify-content: center;
  }
}

@media (max-width: 1023px) and (min-width: 852px) {
  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    justify-content: center;
  }

  .left-sidebar ul li:hover {
    width: auto;
  }
  
  .left-sidebar ul li:hover span,
  .left-sidebar ul li:active span{
    display: inline;
    width: 100%;
    text-align: left;
    margin-left: 10px;
  }

  #navigation{
    justify-content: end;
  }

  .header_title.col-md-9{
    flex: 0 0 70%;
    max-width: 70% ;
  }

  #navigation{
    flex: 0 0 30%;
    max-width: 30%;
  }
}


@media (max-width: 851px) and (min-width: 639px){
  .header_title.col-md-9{
    flex: 0 0 60%;
    max-width: 60% ;
  }

  #navigation{
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 732px) and (min-width: 511px){
  .header_title.col-md-9{
    flex: 0 0 50%;
    max-width: 50% ;
  }

  #navigation{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cvm-span{
    display: none;
  }

  .cvm-span.admin-module{
    display: inline;
  }

  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    justify-content: center;
  }

  
}

@media (max-width: 766px) and (min-width: 505px){
  #profile-pop{
    width: 400px !important;
  }
}
/*MOBILE*/

@media (max-width: 501px) and (min-width: 427px){
  #header_container #selectmenu-options{
  left: -100px;
  width: 300px !important;
  }

  #profile-pop{
    width: 350px !important;
  }
}

@media (max-width: 426px) and (min-width: 377px){
  #header_container #selectmenu-options{
    width: 280px !important;
    left: -80px !important;
  }

  #profile-pop{
    width: 350px !important;
  }

}

@media (max-width: 376px){
  .cvm-span{
    display: none;
  }

  .cvm-span.admin-module{
    display: inline;
  }

  #header_container #selectmenu-options{
    width: 280px !important;
    left: -80px !important;
  }

  #profile-pop{
    width: 300px !important;
  }

  #logo{
    margin: 0 !important;
  }
}

@media (max-width: 321px){
  #profile-pop{
    width: 250px !important;
  }

  #logo{
    margin: 0 !important;
  }
}

