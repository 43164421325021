html {
  overflow-x: inherit !important;
}

body {
  font-family: Graphik-Regular-Web;
  color: #000000;
  overflow-y: overlay;
}

body::-webkit-scrollbar-thumb{
  background: transparent;
}

::-webkit-scrollbar-thumb:hover{
  display: initial;
}

body:hover::-webkit-scrollbar-thumb{
  display: initial;
  
}

::-webkit-scrollbar{
  width: 15px !important;
}

body::-webkit-scrollbar-track{
  margin-top: 45px;
}

.row {
  margin: 0 !important;
}

.full-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

@font-face {
  font-family: "Graphik-Regular-Web";
  src: url("./Fonts/Graphik/Graphik-Regular-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Black-Web";
  src: url("./Fonts/Graphik/Graphik-Black-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Bold-Web";
  src: url("./Fonts/Graphik/Graphik-Bold-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Extralight-Web";
  src: url("./Fonts/Graphik/Graphik-Extralight-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Semibold-Web";
  src: url("./Fonts/Graphik/Graphik-Semibold-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik-Medium-Web";
  src: url("./Fonts/Graphik/Graphik-Medium-Web.woff") format("woff");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./Fonts/OpenSans/OpenSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("./Fonts/OpenSans/OpenSans-Light.woff") format("woff");
}

@font-face {
  font-family: "OpenSans-Semibold";
  src: url("./Fonts/OpenSans/OpenSans-Semibold.ttf") format("ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./Fonts/OpenSans/OpenSans-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("./Fonts/OpenSans/OpenSans-ExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: "OpenSans-Italic";
  src: url("./Fonts/OpenSans/OpenSans-Italic.ttf") format("ttf");
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url("./Fonts/SourceSansPro/SourceSansPro-SemiBold.woff") format("woff");
}

.btn-default .btn {
  font-size: 1em;
  font-family: Graphik-Regular-Web, sans-serif;
  background-color: #008dfd;
  color: #fff;
  font-weight: 500;
  border: 0px;
  border-radius: 0;
}

.asterisk {
  color: rgb(99, 115, 129);
  font-size: 1.2em;
  /* vertical-align: text-bottom; */
  line-height: �1.42857143;
}

.asterisk-red {
  color: red;
  font-size: 1.2em;
}

button:active span,
button:focus span,
button:active,
button:focus {
  position: relative;
  top: 0px;
  left: 0px;
}

div[class*="-singleValue"]{
  color: rgb(161, 0, 255);
}