#table-ureport .tabulator .tabulator-header,
.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

#table-ureport .tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  word-break: break-word;
}

.conModal .modal-content {
  border-radius: 0px;
  height: auto;
}

#userExtractOk {
  background: none;
  border: none;
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  text-align: center;
}

#userExtractCancel {
  background: none;
  border: none;
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  text-align: center;
}

.ba_export_header {
  margin: 0;
  padding: 0;
}

.rfa-table-report-container .alignEntries label {
  margin: 0px;
  padding-top: 8px;
}

.userReport-fetch-btn,
.userReport-refresh-btn {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.fetch-btn {
  cursor: not-allowed !important;
}

.userReport-refresh-icon {
  width: 15px;
  margin-right: 7px;
}

.userReport-fetch-icon {
  height: 15.6px;
  display: flex;
  margin-right: 5px;
  float: left;
  animation: spin 2s linear infinite;
}
