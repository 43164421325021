th {
  font-family: 'Graphik-Bold-Web';
}

td {
  font-family: 'Graphik-Regular-Web';
}

.form-control,
.textarea.form-control,
.btn-default {
  color: black;
  font-family: 'Graphik-Regular-Web';
}

.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus {
  color: #fff;
  font-size: 1.2em;
  font-family: Graphik-Medium-Web;
  background-color: #a100ff;
  font-weight: 500;
  border: 0;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #a100ff;
  border-radius: 20px;
}

.btn-default:disabled {
  background-color: #a0a0a0;
  color: #fff;
}

/* Client select modal */

.tab-component-content {
  padding: 0 !important;
}

.cvm-project-tile-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 380px;
  padding: 0 0 10px 0;
}

.cvm-project-tile-container>.row {
  margin: 14px -10px 0 -10px;
}

.cvm-project-tile {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  margin: 16px 0;
  height: 90%;
}

.cvm-project-tile>* {
  filter: grayscale(1);
  opacity: 0.4;
}

.cvm-project-tile:hover {
  background: rgb(250, 245, 253);
  border-radius: 8px;
  border: 1px solid rgb(161, 0, 255);
}

.cvm-project-tile:hover>* {
  filter: none;
  opacity: 1;
}

.project-logo {
  height: 72px;
  width: 72px;
  margin: auto;
}

/* Client Details */

.client-details {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  width: 100%;
  padding: 28px 36px;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
}

.container-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Medium';
  font-size: 18px;
  font-weight: 500;
  height: 24px;
  letter-spacing: -0.35px;
  line-height: 24px;
  margin: 0 0 18px 0;
}

.client-dg-lable {
  margin: 10px;
  color: rgb(255, 255, 255);
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.client-details-row {
  display: flex;
  margin: 12px 10px;
}

.client-details-column {
  display: flex;
  width: 50%;
  padding: 0 5px;
}

.title {
  margin: 0 12px 0 0;
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
  white-space: nowrap;
}

.value {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular';
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
}

.value.value-link {
  color: rgb(161, 0, 255);
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 22px;
  cursor: pointer;
  display: flex;
}

.value-icon {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(161, 0, 255);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a100ff;
  margin: 0 0 0 7px;
}

.value svg {
  font-size: 12px;
  margin: 0 0 0 2px;
}

.reporting-year-details {
  padding: 28px 36px 38px 36px;
}

.reporting-year-title-row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 18px 0;
}

.reporting-year-title-row>.container-title {
  margin: 0;
}

.reporting-year-title-row>img {
  width: 21px;
  cursor: pointer;
}

.reporting-year-details .title {
  margin: 0 5px 0 0;
}

/* Search */

.search-bar-container {
  position: relative;
}

.search-bar {
  position: absolute;
  right: 0;
  margin: 10px 0 0 0;
}

.search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(231, 234, 238);
  width: 240px;
  padding: 5px 10px 5px 40px;
  outline-style: none;
}

.search-bar svg {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

/* tabs */

.tab-component-purple .react-tabs__tab {
  color: rgb(99, 115, 129) !important;
  font-weight: 500;
  padding: 14px 12px;
  border: 4px solid transparent !important;
}

@media only screen and (max-width: 798px) {
  .tab-component-purple .react-tabs__tab {
    font-size: 12px !important;
  }
}

.tab-component-purple .react-tabs__tab--selected {
  color: #a100ff !important;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 4px solid #7500cc;
}

.star-container {
  right: 30px;
  position: absolute;
  bottom: 25px;
}

.star-container svg {
  color: #ffc82c;
}

.star-container.no-filter {
  filter: none;
  opacity: 1;
}

.client-details-select {
  width: 100%;
  border: none;
  text-align: center;
  background: transparent;
}

.project-name {
  padding-top: 10px;
}

/* HEADER */

#header_container {
  box-shadow: none;
  position: relative;
  z-index: 1499;
  display: flex;
  background: linear-gradient(-225deg,
      rgb(70, 0, 115) 0%,
      rgb(117, 0, 192) 48%,
      rgb(161, 0, 255) 100%);
  border-radius: 0px;
  height: 45px;
  width: -webkit-fill-available;
}

div#session-warning-message {
  position: fixed;
  left: 0;
  right: 0;
  /* margin: auto; */
  text-align: center;
  padding: 5px;
  color: #000000;
  width: 60%;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
  font-family: 'Graphik-Medium';
  box-shadow: 0 0 10px #3a3a3a;
  transition: all 0.5s ease-in-out;
  top: -100%;
  opacity: 0;
  z-index: 1500 !important;
  width: -webkit-fill-available;
}

div#session-warning-message span {
  font-family: 'Graphik-Semibold';
  color: #9d00ff;
  cursor: pointer;
}

.message-show {
  opacity: 1 !important;
  top: 0 !important;
}

.def-cursor {
  cursor: default !important;
}

.header_title {
  display: flex;
  padding-left: 10px !important;
  color: #ffffff;
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
  height: 45px;
}

.header_title>div {
  display: flex;
  align-items: center;
}

#logo {
  width: 32px;
  margin: 0 16px 0 0;
}

#navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px;
}

.divider {
  border-right: 1px solid rgba(255, 255, 255, 0.35);
  height: 45%;
  margin: 0 20px;
}

.data-visualisation-container .divider {
  height: 41px;
  border-right: 1px solid rgba(184, 184, 184, 0.35);
  margin: 0 20px 0 0;
}


.navigation-link,
#profile-link,
#bell-link {
  margin-left: 24px;
  color: rgb(18, 43, 59);
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
}

.profile-pop-links>a {
  color: rgb(18, 43, 59) !important;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 96px;
  margin-bottom: 20px;
}

#profile-link {
  margin-left: -10px;
}

.navigation-link:hover {
  color: #b200ff;
  border-bottom: none;
}

.navigation-link.selected {
  border-bottom: 4px solid #b200ff;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  color: #b200ff;
  margin: 0 -4px 0 41px;
}

.selected.menu-open {
  border: none;
  color: #122b3b;
  margin: 0px 0px 0 45px;
}

.navigation-link>img {
  height: 14px;
  margin-left: 10px;
  width: 14px;
}

.user-eid {
  color: rgb(255, 255, 255);
  font-family: 'Graphik-Medium';
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding-right: 20px;
  min-inline-size: fit-content;
}

.profile-gap {
  margin-bottom: 14px;
}

#profile-pop {
  position: absolute;
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0 0 12px rgb(60 60 60);
  top: 45px;
  transition: top 0.5s, opacity 0.2s;
  cursor: default;
  right: 50px;
  width: 40em;
  margin-right: -15px;
  margin-left: -15px;
}

#profile-pop.visible {
  top: 65px;
  opacity: 1;
  height: auto;
}

.profile-pop-links:hover,
.bell-pop-links:hover {
  cursor: pointer;
  text-decoration: underline;
}

#profile-pop-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  cursor: default;
}

#profile-pop-overlay.visible {
  display: block;
}

.people_header {
  height: auto;
  font-size: 0.87em;
  margin: 3px auto;
  text-align: right;
  width: 29%;
  padding: 0;
}

#header_container>img {
  float: right;
  padding-right: 1.5%;
  width: 43px;
  padding-top: 15px;
}

.people_header>span {
  vertical-align: middle;
  padding: 0 5px;
}

#home {
  cursor: pointer;
  padding: 5px;
  width: 30px;
  padding-top: 10px;
}

#user_logo {
  width: 20px;
  margin: 0 10px;
}

#profile-link svg {
  margin: 0 6px;
}

#burger_logo {
  cursor: pointer;
  width: 26px;
  padding-top: 18px;
  right: -8.5%;
  background-color: white;
  position: absolute;
  float: right;
  margin: 0;
  padding-top: 15px;
}

.burger .hide {
  display: none;
}

.burger {
  right: 2%;
  width: auto;
}

#page-wrap {
  position: relative;
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.content {
  height: 560px;
}

/* FOOTER */

.footer {
  background: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: 0px -2px 16px 0px rgba(33, 43, 54, 0.1), 0px -1px 0px 0px rgb(223, 227, 232);
  width: 100%;
  /*display: flex;*/
  padding: 15px 25px;
  height: 64px !important;
}

#footer_container div {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 28px;
}

#accenture_logo {
  height: 25px;
}

.trademark {
  text-align: left;
  margin: 0 0 0 50px;
}

.footer-links-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.footer-links {
  /*margin-left: 40px;*/
  text-align: right;
  cursor: pointer;
  color: rgb(161, 0, 255) !important;
}

.footer-links:hover {
  text-decoration: underline;
}

.footer-links a {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 28px;
}

.footer .row #footer-logo>img {
  vertical-align: baseline !important;
}

.footer .row {
  align-items: center;
}

.trademark span {
  padding: 0 20px;
  font-weight: bold;
}

.termsOfUse {
  cursor: pointer;
  font-weight: normal;
}

.termsOfUse:hover {
  text-decoration: underline;
}

.footerImage {
  float: left;
  position: relative;
  transition: all 0.5s;
}

.acn_ops_growth_platform {
  color: white;
  font-size: 0.87em;
  font-weight: bold;
  padding: 12px 40px;
}

.acn_logo {
  padding: 1em;
}

.acn_logo>img {
  width: 100%;
  height: 100%;
  padding: 47px 52px;
}

.footer .downloadLink {
  color: white;
  font-family: Graphik-Regular-Web;
  font-size: 0.8em;
}

.footer .downloadLink:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.footer a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.quick_links {
  padding: 20px 30px;
}

@media (min-width: 992px) {
  .quick_links .col-md-5 {
    width: 39%;
  }
}

.quick_links_content,
.quick_links_header {
  padding: 0px;
}

.quick_links_header {
  padding: 10px 0;
}

/* MODAL TERMS */

.terms {
  padding: 10px;
  background-color: darkslategray;
  font-family: 'Graphik-Extralight-Web';
  color: white;
}

.terms>h2 {
  font-family: 'Graphik-Bold-Web';
}

/* NON-MODAL TERMS */

.termsAndAgreement {
  width: auto;
  margin: 3.5% 6%;
  padding: 2% 2%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.termsAndAgreement h1 {
  color: #34b6e5;
  font-size: 2em;
}

.termsAndAgreement p {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 34px;
  margin-bottom: 20px;
}

/* PRIVACY STATEMENT */

.privacyStatement {
  width: auto;
  margin: 3.5% 6%;
  padding: 2% 2%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.privacyStatement h1 {
  color: #34b6e5;
  font-size: 2em;
}

.privacyStatement p {
  margin: 0 0 20px 0;
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Regular-Web';
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 34px;
}

.privacyStatement h2 {
  color: rgb(45, 58, 75) !important;
  font-family: Graphik-Bold-Web;
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  letter-spacing: -0.32px;
}

.privacyStatement a {
  color: #a100ff;
}

/* BURGER MENU */

.hamburgerButton {
  position: relative;
  top: 18px;
  left: 10px;
}

.userDetailsRowContainer {
  margin: 40px 0px 3px 0px !important;
  padding-left: 25px !important;
}

.userPhotoContainer {
  margin-top: 10px;
  padding: 0px;
  text-align: center;
}

.userDetailsContainer {
  margin-top: 10px;
  padding: 0px;
}

#userContainer {
  position: relative;
  font-family: 'Graphik-Regular-Web';
  text-align: center;
}

.userLogo {
  position: relative;
  width: 30%;
}

#userRole {
  font-size: 9.5px;
  font-style: italic;
  color: black;
  left: 1px;
}

.burgerContainer {
  background-color: rgb(43, 0, 255);
  height: 50px;
  width: 50px;
  position: absolute;
  left: 53px;
  float: right;
  cursor: pointer;
}

#userFullName {
  line-height: 23px;
  color: black;
  font-size: 15px;
}

.paragraph {
  text-align: justify;
  text-indent: 30px;
}

.modalBorder {
  border: 10px solid rgb(70, 132, 183);
}

.tab-content a {
  font-family: 'Graphik-Regular-Web';
}

.bm-burger-button>button {
  height: 50px !important;
}

/* Navigation Style */

.nav .open>a,
.dropdown-menu {
  background-color: steelblue;
  height: auto;
}

.menu-nav {
  width: 200px;
  float: left;
  text-align: center;
  font-size: 20px;
  font-family: 'Graphik-Extralight-Web';
}

.menu-nav>a,
.dropdown-menu>li>a {
  color: white;
}

.nav>li>a:hover,
.nav>li>a:focus,
.nav .open>a:hover,
.nav .open>a:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background-color: slateblue;
  color: white;
}

#nav-menu.hov {
  color: #b200ff;
}

#nav-menu.hov>#menu_black {
  display: none;
}

#nav-menu.hov>#menu_pink {
  display: block;
}

#menu_pink {
  display: none;
}

#header-overlay {
  background: #ffffff00;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;
}

#header-overlay.overlay-visible {
  display: block;
}

.terms-header {
  color: rgb(45, 58, 75) !important;
  font-family: Graphik-Bold-Web;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.32px;
  margin-top: 20px;
}

.privacy-header {
  color: rgb(45, 58, 75) !important;
  font-family: 'Graphik-Bold-Web';
  font-size: 28px !important;
  font-weight: bold;
  letter-spacing: -0.32px;
  line-height: 26px;
}

ul.header-profile {
  padding-left: 0px;
  text-align: right;
  list-style: none;
}

li.header-profile-name {
  padding-top: 10px;
}

li.header-profile-role {
  padding-right: 26px;
  max-width: 124px;
  font-size: 10.5px;
  font-style: italic;
}

/* groove override */

@media only screen and (max-width: 1024px) {
  #groove-select-dropdown .selectmenu-group-values {
    width: 70% !important;
  }
}

#groove-select-dropdown div#selectmenu-anchor:hover {
  border: 1.5px solid #a100ff !important;
}

#groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
  color: #637381 !important;
}

#groove-datepicker .dtp-lbl-container:focus,
#groove-datepicker .dtp-lbl-container:hover {
  border: 1px solid #a100ff !important;
  box-shadow: none !important;
  background: none !important;
}

.react-datepicker__day--selected {
  background: #a100ff !important;
  color: #fff !important;
  font-family: 'Graphik-Medium';
  outline: none !important;
}

.react-datepicker__day {
  border: 0.1px solid #a100ff !important;
  background-color: #fcf5ff;
}

.react-datepicker__day--disabled {
  border: 0.1px solid #f9fafb !important;
}

.react-datepicker__day--today {
  color: #a100ff !important;
}

#groove-select-dropdown svg {
  color: #919eab;
}

div#calendar-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #a100ff !important;
  color: #fff !important;
}

#groove-input .groove-input .groove-input:focus,
#groove-input .groove-input:focus,
#groove-textarea .floating-label-textbox .groove-textbox:focus {
  outline: none;
  box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #a100ff;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label,
#groove-textarea .floating-label-textbox .groove-textbox:focus+label {
  margin: 10px 0 !important;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  margin: 10px 0;
}

#groove-input .floating-label .groove-input:focus+label,
#groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
#groove-input .floating-label .groove-input:not(:placeholder-shown):focus+label,
#groove-input .floating-label .groove-input:not(:placeholder-shown):hover+label,
#groove-textarea .floating-label-textbox .groove-textbox:focus+label,
#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: #a100ff !important;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 5px 0;
  padding: 0 7px;
  opacity: 1 !important;
}

.add-program-details #groove-input .floating-label .groove-input:focus+label,
.add-program-details #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.add-program-details #groove-input .floating-label .groove-input:not(:placeholder-shown):focus+label,
.add-program-details #groove-input .floating-label .groove-input:not(:placeholder-shown):hover+label,
.add-program-details #groove-textarea .floating-label-textbox .groove-textbox:focus+label,
.add-program-details #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: #637381 !important;
}

#groove-input .floating-label .groove-input:focus,
#groove-input .floating-label .groove-input:not(:placeholder-shown) {
  padding: 1.3rem 16px 12px !important;
}

#groove-radio-button .form-check>label {
  margin: 0 0 0 0 !important;
  font-size: 14px !important;
}

#groove-textarea .groove-textbox,
#groove-input .groove-input {
  outline: none !important;
  border: 1px solid #dfe3e8;
}

.hideStatusMessage {
  display: none !important;
}

#groove-input .floating-label label {
  top: 44px !important;
}

#groove-input .groove-input {
  margin-bottom: 1.3rem;
}

.header_title .form-group {
  max-width: 400px;
  margin: 0;
}

#groove-btn-id>button {
  line-height: 1.5;
  box-shadow: none !important;
}

#groove-btn-id>button>span {
  font-family: 'Graphik-Medium';
}

.add-button-right {
  margin-left: auto;
}

/* dropdown override for header client select */

.header_title #groove-select-dropdown {
  width: auto;
}

.header_title #groove-select-dropdown div#selectmenu-anchor {
  background: transparent;
  border-radius: 0;
  border: none !important;
  height: 56px;
  width: max-content;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 1.2rem;
}

.header_title #groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
  display: none;
}

.header_title #groove-select-dropdown .selectmenu-selected-value {
  margin: 0;
}

.header_title #groove-select-dropdown div#display-selected {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.header_title #groove-select-dropdown #selectmenu-dropdown-icon {
  margin-top: 0;
  line-height: 0;
}

.header_title #groove-select-dropdown #selectmenu-anchor svg {
  color: #fff;
  width: 13px;
}

.header_title #groove-select-dropdown .default-display {
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.header_title #groove-select-dropdown div.selectmenu-options {
  position: absolute !important;
  top: 57px;
  left: -30px;
  width: 400px !important;
}

.header_title #groove-select-dropdown div.selectmenu-options.display-list {
  margin: 0;
}

.header_title #groove-select-dropdown div#selectmenu-anchor[disabled] {
  border: transparent !important;
  background: transparent !important;
  cursor: default !important;
}

.add-new-program-container #groove-select-dropdown div.selectmenu-options.display-list {
  max-height: 250px;
}

.add-boi-field-container #groove-select-dropdown div.selectmenu-options.display-list {
  max-height: 305px;
}

div#selectmenu-options {
  overflow: hidden !important;
}

div#dropdown-list.dropdown-list {
  max-height: 250px;
}

div.selectmenu-options.display-list .dropdown-item {
  word-break: break-all !important;
}

@media only screen and (max-width: 768px) {
  div.selectmenu-options.display-list .dropdown-item {
    font-size: 13px !important;
  }
}

.cvm-tab-content.add-boi-modal {
  min-height: 500px;
}

.modal-dialog.modal-lg {
  max-width: 95% !important;
}

.modal-dialog.modal-md {
  max-width: 70% !important;
}

.accordion>.card {
  overflow: visible !important;
  display: flex;
  flex-direction: column-reverse;
}

.accordion>.card>.card-header {
  margin-bottom: 0 !important;
  border-radius: 10px !important;
}

.collapse.show~div.card-header .btn-link>div.svg-container {
  transform: rotate(180deg);
}

.searchable-dropdown-content {
  display: flex;
  align-items: center;
  position: sticky !important;
  top: 10px;
}

.searchable-dropdown-content svg {
  font-size: 15px;
}

.searchable-dropdown-content .svg-container {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

body #groove-btn-id .btn-ops-blue {
  padding: 3px 15px;
  background-color: #a100ff;
  border-color: #a100ff;
}

body #groove-btn-id .btn-ops-blue:hover,
body #groove-btn-id .btn-outline-dg-slate:hover,
body #groove-btn-id .btn-outline-dg-slate:active {
  color: #fff !important;
  background-color: #8900d9 !important;
  border-color: #8900d9 !important;
}

body #groove-btn-id .btn-outline-dg-slate {
  padding: 3px 15px;
  color: #a100ff;
  border-color: #a100ff;
  box-shadow: none !important;
}

label#groove-checkbox-label {
  font-size: 14px;
  font-family: 'Graphik-Regular';
  line-height: 2;
}

div#groove-select-dropdown .custom-checkboxlist {
  padding: 10px 20px 0 20px;
}

#groove-select-dropdown div#selectmenu-anchor[disabled] {
  border: 1px solid #dfe3e8 !important;
}

.react-tabs__tab--selected {
  background: transparent !important;
}

#groove-btn-id>button>* {
  font-size: 14px;
  margin: 0 5px;
}

.ag-cell {
  border: none !important;
}

.ag-react-container {
  width: 100%;
}

.bell-notification {
  color: #ffffff;
}

.bell-notification svg.svg-inline--fa.fa-w-14 {
  cursor: pointer;
  width: 21px;
  height: 19.8px;
}

.contact-us-text{
  width: 70px;
  color: rgb(255, 255, 255);
  font-family: 'Graphik-Medium';
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

#bell-pop {
  padding: 15px;
  position: absolute;
  right: -10px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(223, 227, 232);
  -webkit-box-shadow: 0px 2px 32px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
  box-shadow: 0px 2px 32px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
  transition: top 0.5s, opacity 0.2s;
  cursor: default;
  top: 45px;
  margin-left: 0px;
  white-space: nowrap;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content {
  margin: 10px 15px !important;
  width: auto !important;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  height: 100% !important;
}

div#groove-checkbox label {
  color: rgb(0, 0, 0);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 1.8;
}

div.custom-checkboxlist {
  padding-left: 0 !important;
  overflow: hidden !important;
  max-height: 100px !important;
}

.dimension-collapse .card-header>div {
  display: flex;
  justify-content: space-between;
}

.custom-checkboxlist .form-check {
  padding-left: 20px !important;
  height: auto !important;
}

#groove-checkbox label#groove-checkbox-label {
  margin: 0 12px !important;
}

input::placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.dropdown-invalid #selectmenu-anchor,
.multi-select-dropdown-with-count.dropdown-invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}

#groove-input .input-box>input.dropdown-invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}

#groove-textarea .groove-textbox.dropdown-invalid {
  outline: 1px solid red !important;
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}

.dropdown-invalid label#dtp-lbl-container {
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}

.infoIcon {
  height: 13px;
  width: 13px;
  border: 1.2px solid #a100ff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: 5px;
}

.groove-input-custom-label-disabled,
div.groove-input-custom-label {
  z-index: 0 !important;
}

.add-new-program-container .groove-input-custom-label-disabled,
.add-new-program-container div.groove-input-custom-label {
  font-size: 13px;
}

.add-new-program-container div.groove-input-custom-label>div {
  width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
}

.reporting-year-ddown #selectmenu-dropdown-icon svg {
  visibility: hidden;
}

.reporting-year-ddown #selectmenu-dropdown-icon {
  z-index: 999;
}

.reporting-year-ddown>.svg-container {
  position: absolute;
  top: 9px;
  right: 0;
  margin-right: 40px;
}

.reporting-year-ddown {
  position: relative;
}

#selectmenu-options .custom-checkboxlist {
  padding: 0 20px !important;
}

#groove-select-dropdown #selectmenu-dropdown-icon {
  width: auto !important;
}

div.cbox-container {
  min-height: 30px;
}

.input-invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}

.input-conditional {
  border: 1px solid yellow !important;
  box-shadow: 0 0 5px #ffc4007d;
}

/* Notification list css starts here */

#popover-notification>div {
  max-height: 660px;
}

#popover-notification.popover .arrow {
  display: none;
}

#popover-notification.popover {
  min-width: 400px;
  inset: -10px 0px auto auto !important;
  z-index: 5000;
}

#popover-notification .close-btn {
  top: 20px;
}

.close-btn svg.svg-inline--fa.fa-w-11 {
  color: rgb(145, 158, 171);
  height: 15px;
  width: 15px;
}

#popover-notification .popover-header {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
  letter-spacing: -0.07px;
  background: #fff;
  padding: 24px 16px;
  line-height: 16px;
  border-bottom: none;
}

#popover-notification .popover-header>div {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.07px;
}

#popover-notification .popover-body {
  padding: 0;
}

#popover-notification .new-section {
  padding: 5px 16px 24px 16px;
  border-bottom: 1px solid rgb(223, 227, 232);
}

#popover-notification .new-header {
  color: rgb(156, 106, 222);
  font-size: 10px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#popover-notification .new-header .new-count {
  color: rgb(156, 106, 222);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
}

#popover-notification .new-header .mark-read,
.view-complete-list {
  cursor: pointer;
  color: rgb(161, 0, 255);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-right: 16px;
}

.notice-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.notice-title h5 {
  margin-bottom: 0;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.notice-title p {
  margin-bottom: 0;
  color: rgb(145, 158, 171);
  font-size: 10px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.16px;
}

.notice-header {
  display: flex;
  justify-content: space-between;
}

.notice-text {
  display: flex;
  align-items: center;
  text-align: left;
}

.user-wrap {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  background: #e7eaee;
}

.notice-text #user_logo {
  width: 32px;
  height: 32px;
  margin: 0;
}

.notice-text p {
  margin-bottom: 0;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
  word-break: break-word;
}

.reject.notification-item-wrap.card.active {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(234, 128, 151) !important;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0) !important;
  border-radius: 4px !important;
}

.normal.notification-item-wrap.card.active {
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255) !important;
}

.notification-item-wrap.card.active {
  background: #fff;
  border: 1px solid rgb(223, 227, 232) !important;
}

.notification-item-wrap.card {
  background: #fff;
  border: 1px solid rgb(223, 227, 232) !important;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.01);
  border-radius: 4px !important;
  margin-bottom: 10px;
}

.notification-item-wrap.card:last-child {
  margin-bottom: 0;
}

.notification-item-wrap .card-header {
  padding: 16px 16px 28px 16px;
  border-radius: 0px;
  border: none;
  background: inherit;
}

.notification-item-wrap .card-header .btn {
  padding: 0;
}

.action-required.notice-title h5 {
  color: rgb(213, 0, 47);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.notice-desc-wrap {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 16px 6px 16px 16px;
}

.notice-desc {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
}

.notice-desc .notif-comment {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
  max-width: 120ch;
}

.notice-desc .veiw-more {
  color: rgb(161, 0, 255);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
  cursor: pointer;
  margin-left: 46%;
  right: 44px;
  position: absolute;
}

.notification-item-wrap .card-body {
  padding-top: 0px;
}

.notice-ext-link {
  padding-left: 55px;
  padding-right: 55px;
  height: 20px;
  display: flex;
}

.old-notif.notice-ext-link {
  padding-left: 0;
  padding-right: 0;
}

.notice-ext-link span {
  color: rgb(161, 0, 255);
  font-size: 11px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  vertical-align: middle;
}

.notice-ext-link .svg-container {
  margin: auto 0;
}

.notice-ext-link .action-link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.notification-item-wrap .btn-link.focus,
.notification-item-wrap .btn-link:focus,
.notification-item-wrap .btn-link:hover {
  text-decoration: none;
}

.notification-item-wrap .btn.focus,
.notification-item-wrap .btn:focus {
  outline: 0;
  box-shadow: none;
}

.old-section {
  padding: 24px 16px;
}

.old-list,
.new-list {
  padding-right: 10px;
  max-height: 240px;
  overflow-y: auto;
}

.old-header {
  color: rgb(145, 158, 171);
  font-size: 10px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.old-header .old-title {
  color: rgb(145, 158, 171);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.collapse.show~div.card-header .btn-link>.notice-header div.svg-container {
  transform: rotate(180deg);
}

/* Notification list css ends here */

#groove-input {
  height: 80%;
  position: relative;
  display: block;
}

span.tooltiptext {
  display: none;
  width: 400px;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
  left: 35px;
}

.error-message {
  height: 24px;
}

.stepper-tab-left-section .card {
  border: none;
}

.stepper-tab-left-section .accordion>.card>.card-header {
  border: none;
  border-radius: 0 !important;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  background: rgb(249, 250, 251);
}

.stepper-tab-left-section .accordion>.card>.card-header .col {
  padding: 0 5x 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper-tab-left-section .accordion>.card>.card-header button {
  font-size: 24px;
  padding: 0 !important;
  box-shadow: none !important;
}

.stepper-tab-left-section .accordion>.card>.card-header button svg {
  color: rgb(99, 115, 129);
}

.stepper-tab-left-section .accordion>.card .card-body {
  padding: 1.25rem 0;
  background: rgb(249, 250, 251);
}

.stepper-tab-left-section .accordion>.card .card-body li {
  padding: 10px 30px 10px 45px;
}

.groove-dtpicker-label-disabled,
.groove-dtpicker-label-onfocus {
  color: #637381 !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 11px !important;
  margin: 5px 0 !important;
  padding: 0 7px !important;
  opacity: 1 !important;
  position: absolute !important;
  transition: all 0.25s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  left: 22px !important;
  top: 2px !important;
}

#groove-datepicker .react-datepicker__input-container input:focus,
#groove-datepicker .react-datepicker__input-container input:not(:placeholder-shown) {
  padding: 21px 6px 15px !important;
}

div#groove-textarea .help-text {
  display: none;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.notifyModal-dropdown>.multi-select-dropdown {
  padding: 0 20px 15px 20px;
}

#mystery {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.cvm-span {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.groove-selectmenu-dropdown-icon {
  margin-top: 0 !important;
}

/* Loader */

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  margin: 60px auto;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #a100ff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: unset !important;
}

#popover-notification .notification-item-wrap .card-header {
  padding: 5px 15px;
}

#popover-notification .notification-item-wrap .card-body {
  padding: 10px 35px 10px 55px;
}

.card-header .btn-link {
  width: 100%;
}

.notif-count {
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  background: rgb(213, 0, 47);
  right: -7px;
  top: 10px;
  width: auto;
  height: 16px;
  text-align: center;
  border-radius: 20px;
  padding: 2px;
}

.refresh-btn {
  cursor: pointer;
  background: rgb(156, 106, 222);
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  padding: 3px;
  margin-left: 8px;
  line-height: 0;
}

.refresh-btn svg {
  color: white;
  padding: 0.7px;
}

.refresh-btn svg.rotate {
  animation: rotation 2s infinite linear;
}

.notif-added {
  position: absolute;
  top: 95px;
  left: 90px;
  z-index: 1000;
  width: 204px;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(70, 0, 115);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.01);
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  padding: 3px 0px;
  cursor: pointer;
  display: none;
}

.notif-added span {
  width: 152px;
  height: 16px;
  color: rgb(70, 0, 115);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
  cursor: pointer;
}

.notif-added span .svg-inline--fa.fa-w-10 {
  width: 15px;
  height: 15px;
  border: 1px solid rgb(70, 0, 115);
  border-radius: 10px;
  margin: -2.5px 0;
}

.show.notif-added {
  display: block;
}

.no-notif {
  height: 60px;
  background: rgb(255, 255, 255);
  border: 1.5px dashed rgb(161, 0, 255);
  border-radius: 16px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.no-notif span {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.comment-expand {
  height: 100% !important;
}

#bell-link.disable-notif,
#bell-link.disable-notif .bell-notification svg.svg-inline--fa.fa-w-14 {
  cursor: not-allowed;
  filter: grayscale(1);
  opacity: 0.9;
}

#notif-goTo .tooltip-inner {
  font-size: 11px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  vertical-align: middle;
  max-width: 250px;
}

/* Profile Menu CSS */

.profile-main-section>div:first-child {
  background: rgba(161, 0, 255, 0.1);
  border-radius: 4px;
  margin-right: 5px;
  width: 35px;
  text-align: center;
  padding: 5px;
}

.profile-main-section>div:nth-child(2)>div:first-child {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 14px;
  font-weight: 600;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.profile-main-section>div:nth-child(2)>div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular';
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.profile-main-section>div:nth-child(3) {
  margin-left: auto;
  margin-top: auto;
  margin-right: 13px;
}

.profile-sub-section>div>div:nth-child(2) {
  margin-left: auto;
  margin-bottom: auto;
}

.profile-sub-section {
  background: rgb(231, 234, 238);
  border-radius: 8px;
  padding: 12px;
  margin: -5px -5px 10px -5px !important;
  display: block;
}

.profile-main-section {
  padding: 10px;
  cursor: pointer;
  margin-right: -15px;
  margin-left: -15px;
}

.profile-sub-section .sub-section-container {
  display: flex;
  cursor: pointer;
}

.profile-sub-section>div {
  width: 100%;
}

.profile-sub-section .profile-menu-chevron {
  margin-bottom: 6px;
}

.profile-sub-section>div>div>div:first-child {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Medium';
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.profile-sub-section>div>div>div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular';
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.profile-sub-section>div>div>div:nth-child(3) {
  color: rgb(117, 0, 192);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.profile-menu-chevron {
  color: rgb(99, 115, 129);
  height: 10px;
  width: 6px;
}

.profile-cursor {
  cursor: default;
}

/* END Profile Menu CSS */

/* icons */
.svg-inline--fa {
  width: 1em;
}

.groove-required-field {
  color: #637381 !important;
}

.add-boi-box-container #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  width: 75% !important;
}

.addedBOI-dropdown #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  width: 73% !important;
}

.select-boi-dropdown #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content {
  margin: 10px 8px !important;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  width: 85% !important;
}

.program-config-link {
  color: #637381;
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.25px;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 9px;
}

.program-config-link-span {
  color: #a100ff;
  cursor: pointer;
}

.modal-body .cvm-tab-content.add-boi-modal {
  padding-top: 0px !important;
}

.rvd-icon {
  height: 12.8px;
  width: 16px;
}

.rvd-link-span {
  color: #a100ff;
  cursor: pointer;
}

/* For invalid project name input field */
.selected-menu-basic.invalid-project-name-field {
  border: 1px solid #aa0025 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.invalid-project-desc-field:not(:placeholder-shown) {
  outline: 1px solid #aa0025 !important;
}