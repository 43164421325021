.check-box {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.labels {
  position: relative;
  display: -webkit-inline-box;
}

.cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

#mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 18px 13px 18px 13px;
}

#mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}

.dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.dropdown-cancel {
  margin: 0px;
}

.dropdown-apply {
  margin: 0px;
}

.dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}

#mt-count-tooltip .search-bar-container .search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}

#mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  flex-wrap: nowrap;
  white-space: nowrap;
}

#mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}

#mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}

.arigato {
  background-color: blue;
}

#mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

#mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

#mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  box-shadow: none;
}

#mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}

#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}

#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}

#mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}

#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>svg {
  background-color: blue;
}

#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>div>div {
  background-color: rgb(0, 255, 34);
}

#mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .multi-select-dropdown-with-count {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  cursor: pointer;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer']>svg {
  height: 30px !important;
  width: 50px !important;
  color: #919eab;
}

.multi-select-dropdown-with-count .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.requiredText {
  color: red;
}

.required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.multi-select-dropdown-with-count .required-multi-select .css-1nszmjf::after {
  content: '*';
  color: #637381 !important;
}

.placeholder-title {
  color: #637381;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 0 0 10px 0;
  padding: 0 7px;
  opacity: 1;
  top: -9px;
  position: relative;
  left: 7px;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover,
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control],
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover {
  margin-top: -16px;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover,
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control],
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover {
  margin: auto 0;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer'] {
  top: -12px;
}

.select-profile .multi-select-dropdown-with-count,
.added-profiles .multi-select-dropdown-with-count {
  height: 60px;
  padding: 0.375rem 0 0 0;
}

.add-new-program-container .multi-select-dropdown-with-count div[class*='-multiValue']>div:first-child {
  font-size: 10px;
}

.add-new-program-container #mt-count-tooltip div[class*='-container']>div[class*='-menu'] {
  width: 420px;
}
.multi-select-dropdown-with-count.multiselectcheck{
  background: #fff;
    border-radius: 20px;
    border: 1.5px solid #a100ff;
    color: #a100ff;
    font-family: Graphik-Medium-Web;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.25px;
    line-height: 14px;
    padding: 0 5px 0 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.column-search-container.client-details-button .multi-select-dropdown-with-count.multiselectcheck{
  width: 215px;
  height: 40px;
}
.multi-select-dropdown-with-count.multiselectcheck div[class*='-indicatorContainer'] > svg{
  width: auto !important;
  height: auto !important;
  color: #a100ff;
}
.multi-select-dropdown-with-count.multiselectcheck > #mt-count-tooltip div[class*='-control'] > div:nth-child(2){
  width: auto;
}
.multi-select-dropdown-with-count.multiselectcheck #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer']{
  top: 2px;
  right: 0px;
}


.multiselectcheck #mt-count-tooltip div[class*=-container]>div[class*=-control]>div:first-child{
height: auto;
padding: 0;
width: 70px;
flex-wrap: nowrap;
}
.multi-select-dropdown-with-count.multiselectcheck > #mt-count-tooltip > div[class*='-container'] > div[class*='-control'] > div > div[class*='-multiValue']{
border: none;
background: transparent;
height: auto;
margin: 0;
padding: 0px 0px 0px 0px;
}
.multiselectcheck #mt-count-tooltip div[class*='-container'] > div[class*='-menu']{
  min-width: 172px;
  left: -105px;
}
.multi-select-dropdown-with-count.multiselectcheck div[class*='-multiValue']>div:nth-child(2) svg{
  display: none;
}

.multi-select-dropdown-with-count.multiselectcheck div[class*='-multiValue']>div:first-child span{
  font-weight: 500;
  font-family: 'Graphik-Medium';
  font-size: 14px;
  top: 1.5px;
  position: relative;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checkbox{
  height: 20px;
  width: 20px;
  border: 1px solid rgb(156, 163, 175);
  background: #ffffff;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checked{
  background: #ffffff;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checked .svg-container{
  position: relative;
  top: -2px;
  font-size: 10px !important;
  padding: 0;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checked .svg-container svg{
  color:#111827;
}
.menulist-class .labels{
top: 4px;
}
.menulist-class{
  padding: 4px 10px;
}
.multiselectcheck #dropdown-popover{
  max-width: 200px;
}
.multiselectcheck .filtered-label {
  margin-right: 4px;
}