.hr-access {
  width: 96%;
  border: solid 1px #a0a0a0;
}

.title-access {
  margin-left: 60px;
  margin-bottom: 20px;
  color: #008dfd;
  margin-top: 30px;
  font-size: 2.3em;
  font-family: Graphik-Regular-Web, sans-serif;
}

/* .tab-content {
  margin: 10px;
} */

#left-tabs-access .li {
  margin-top: 50px;
  background-color: transparent;
  width: 80%;
  color: black;
}

#left-tabs-access > div > ul > li {
  background-color: transparent;
  color: black;
}

.nav-pills > li.active > a {
  background-color: transparent;
  color: #008dfd;
  font: bold 1.2em Graphik-Regular-Web;
  border-right: 4px solid #008dfd;
  border-radius: 0;
  outline: none;
}
.nav > li > a:focus {
  background-color: transparent;
}

.nav-pills > li.active > a:hover {
  background-color: transparent;
}

.nav-pills > li > a {
  text-align: right;
  font: normal 1.2em Graphik-Regular-Web;
}

.nav > li > a {
  color: #000;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #008dfd;
  background-color: #fff;
}

.nav > li > a:hover,
.nav > li > a:focus,
.nav .open > a:hover,
.nav .open > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #fff;
  color: #008dfd;
}

.page-title-container {
  padding: 20px 0;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(227, 234, 237);
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
}

.page-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Bold-web;
  font-size: 24px;
  letter-spacing: -0.32px;
  line-height: 26px;
}

.container-access {
  margin: 15px 90px;
  background-color: #fff;
  border-radius: 8px !important;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 0px 0px 0px;
}

.container-access .nav {
  padding: 0 45px !important;
}

.container-access .tab-content {
  margin: 20px 45px 0 45px !important;
}

.um-head {
  display: flex;
}

.um-head > div {
  width: 100%;
  display: flex;
  align-items: center;
}

.um-head > div:nth-child(2) {
  justify-content: flex-end;
}

.user-management-searchbar {
  position: relative;
  width: 100%;
}

.pa-head,
.rm-head {
  display: flex;
}

.pa-head > div,
.rm-head > div {
  width: 100%;
  display: flex;
  align-items: center;
}

.pa-head > div:nth-child(2),
.rm-head > div:nth-child(2) {
  justify-content: flex-end;
}

.pending-approvals-searchbar,
.role-management-searchbar {
  position: relative;
  width: 100%;
}

.management-btn {
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(196, 205, 213);
  height: 40px;
  justify-content: center;
  margin-left: 20px;
  padding: 8px 20px;
  cursor: pointer;
  font-family: "Graphik-Medium-Web";
  color: #637381;
}

.fetch-btn {
  cursor: not-allowed !important;
}

.add-btn.management-btn {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(0, 186, 255) 100%
  );
  border-radius: 20px;
  height: 40px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium-Web;
  font-size: 14px !important;
  letter-spacing: -0.27px;
  line-height: 13px;
  text-align: center;
  cursor: pointer;
  border: none !important;
}

.page-title-container .extract-btn.management-btn.export-user-report {
  margin: 0 30px;
}

.pa-btn-new.management-btn,
.extract-btn.management-btn {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 2px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
}

.extract-btn.management-btn > a {
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
}

.management-icon {
  width: 15px;
  margin-right: 7px;
}

.management-fetch-icon {
  height: 15px;
  display: flex;
  margin-right: 7px;
  float: left;
  animation: spin 2s linear infinite;
}

.extract-btn > .management-icon {
  width: 17px;
}

.button-pendingapp,
.button-userManagement {
  height: 25px;
  width: 110px;
  border-radius: 0;
  padding: 0;
}

.refresh-logo,
.add-logo {
  width: 15px;
  margin-right: 5px;
  margin-bottom: 2px;
}

th > .checkbox-inline,
td > .checkbox-inline {
  margin-bottom: 20px;
  margin-left: 20px;
}

#table-rm,
#table-pa,
#table-um,
#table-foreport,
#table-ra,
#table-pm {
  margin-top: 15px;
  margin-bottom: 0 !important;
}

.dropdown-pa > select {
  height: 20px;
  padding: 0;
  font: normal 0.87em OpenSans-Regular;
  border-radius: 0px;
}

#table-pa .svg-inline--fa.fa-w-16,
#table-um .svg-inline--fa.fa-w-16 {
  width: 0.8em;
  margin: 0.5px 2px;
  color: #dbdbdb;
}

#table-pa .sort-direction .glyphicon,
#table-um .sort-direction .glyphicon {
  color: #dbdbdb;
  font-size: 0.88em;
}

/* Access Management Buttons */
/* .refresh-btn,
  .refresh-btn:hover,
  .refresh-btn:focus,
  .refresh-btn.btn-default,
  .refresh-btn.btn-default:hover,
  .refresh-btn.btn-default:focus,
  .add-btn,
  .add-btn:hover,
  .add-btn:focus,
  .add-btn.btn-default,
  .add-btn.btn-default:hover,
  .add-btn.btn-default:focus,
  .extract-btn,
  .extract-btn:hover,
  .extract-btn:focus,
  .extract-btn.btn-default,
  .extract-btn.btn-default:hover,
  .extract-btn.btn-default:focus,
  .extract-btn a {
    background-color: #fff;
    font: normal 0.9em OpenSans-Regular;
    color: #000;
    border-radius: 0px;
    border: 0px;
    width: 100px;
    border-style: outset;
    box-shadow: none;
    padding: 0px;
    outline: none;
    height: 25px;
    text-align: center;
  } */

/* .extract-btn,
  .extract-btn:hover,
  .extract-btn:focus,
  .extract-btn.btn-default,
  .extract-btn.btn-default:hover,
  .extract-btn.btn-default:focus,
  .extract-btn a {
    background-color: #fff;
    font: normal 0.9em OpenSans-Regular;
    color: #000;
    border-radius: 0px;
    border: 0px;
    width: 100px;
    border-style: outset;
    box-shadow: none;
    padding: 0px;
    outline: none;
    height: 25px;
    text-align: center;
  } */

.btn-text {
  padding: 0px 3px;
}

.search-btn,
.search-btn:hover,
.search-btn:focus,
.search-btn.btn-default,
.search-btn.btn-default:hover,
.search-btn.btn-default:focus {
  background-color: #008dfd;
  font: normal 0.9em OpenSans-Regular;
  color: #fff;
  border-radius: 0px;
  border: 0px;
  width: 100px;
  height: 25px;
  box-shadow: none;
  padding: 0px;
  float: right;
  text-align: center;
  margin: 0;
  top: 0 !important;
}

/* #pa_container .pa-btn,
  #pa_container .pa-btn:hover,
  #pa_container .pa-btn:focus,
  #pa_container .pa-btn.btn-default,
  #pa_container .pa-btn.btn-default:hover,
  #pa_container .pa-btn.btn-default:focus {
    font: normal 1.2em OpenSans-Regular;
    color: #fff;
    border-radius: 0px;
    border: 0px;
    width: 150px;
    height: 30px;
    box-shadow: none;
    padding: 0px;
    margin-left: 20px;
    float: right;
    margin-top: 25px;
    margin-bottom: 60px;
  } */

#pa_container .pa-footer .management-btn {
  width: 150px;
  height: 30px;
  margin-left: 20px;
  float: right;
  margin-top: 25px;
  margin-bottom: 60px;
}

#pa_container .pa-footer div {
  /* background: rgb(249, 250, 251); */
  background: red;
}
.no-padding {
  padding: 0px;
}

.container-access .tab-content .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

#pa_search,
#searchBox {
  height: 40px;
  padding: 0 15px 0 50px;
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(223, 227, 232);
  width: 60%;
}

.search-input,
.search-userManagement {
  height: 25px;
  border-radius: 0;
  border: 1px solid #dbdbdb;
  width: 70%;
  background-color: #fbfbfb;
  float: left;
  font: normal 0.9em OpenSans-Regular;
  padding-left: 6px;
}

select#showEntries {
  cursor: pointer;
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  padding: 5px 3px;
  margin: 0 10px;
  outline: none;
}

.no-padding label {
  font: normal 0.9em OpenSans-Regular;
  color: black;
}

.total-label {
  /* font: normal 0.9em OpenSans-Regular;
    color: #b6b6b6;
    height: 25px;
    padding: 3.5px 0px; */
  padding: 8px 0;
}

.userButton  {
  font-family: OpenSans-Regular;
  font-size: 0.9em;
  color: black;
  border: none;
  background: transparent;
  width: 120px !important;
  outline: none !important;
}
.alignEntries {
  margin-bottom: 55px;
  background: rgb(243, 243, 243);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #c8d5db;
  border-top: none;
  padding: 0 20px;
  display: flex;
  width: 100%;
  position: relative;
}

.alignEntries label {
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 10px;
}
.alignEntries .no-padding svg {
  fill: #7b8e98 !important;
}

.alignEntries > div {
  display: flex;
  padding: 15px 0 5px 0;
  margin-right: 20px;
}

.alignEntries > div:nth-child(2) > div {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.alignEntries .disabled svg {
  margin: 0 !important;
}

.alignEntries .pagination {
  margin: 0 !important;
}

.alignEntries select option:hover,
.alignEntries select option:focus,
.alignEntries select option:active {
  box-shadow: 0 0 10px 100px rgb(161, 0, 255) inset;
  color: #ffed00 !important;
}

.alignEntries select option:checked {
  background-color: rgb(161, 0, 255) !important;
  color: #fff !important;
}

div#showEntries {
  margin: 0 5px 5px 10px;
}

div#showEntries > div {
  cursor: pointer;
}

.tabulator {
  position: relative;
  background-color: #fff;
  /* overflow: hidden; */
  font-size: 14px;
  text-align: left;
  transform: translatez(0);
  z-index: 1;
}

/* .tabulator-row {
    height: 29px;
  } */

.tabulator[tabulator-layout="fitDataFill"]
  .tabulator-tableHolder
  .tabulator-table {
  min-width: 100%;
}

.tabulator.tabulator-block-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabulator .tabulator-header {
  width: 100%;
  border: 1px solid #c8d5db;
  color: #000;
  white-space: nowrap;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  font: bold 0.87em OpenSans-Regular;
}

.tabulator .tabulator-header,
.tabulator .tabulator-header .tabulator-col {
  position: relative;
  box-sizing: border-box;
  background-color: #f3f3f3;
  overflow: hidden;
  display: flex;
}

.tabulator .tabulator-header .tabulator-col {
  display: flex;
  text-align: left;
  vertical-align: bottom;
}

.tabulator-col.contact-header .tabulator-col-title-holder {
  display: flex;
  justify-content: center;
}

.tabulator-headers {
  display: flex;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  position: absolute;
  border: 1px solid #000;
  background: #e6e6e6;
  pointer-events: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.tabulator-col-title-holder {
  display: flex;
  width: 100%;
  padding: 0 20px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  font-weight: 100;
  text-align: center;
  color: rgb(82, 101, 127);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  letter-spacing: -0.43px;
  line-height: 24px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title
  .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;
  padding: 1px;
  background: #fff;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #000;
  overflow: hidden;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols
  .tabulator-col:last-child {
  margin-right: -1px;
}

.tabulator
  .tabulator-header
  .tabulator-col:first-child
  .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col.ui-sortable-helper {
  position: absolute;
  background-color: #e6e6e6 !important;
  border: 1px solid #000;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  position: relative;
  box-sizing: border-box;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter
  input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
  font-weight: 100;
  text-align: center;
  color: rgb(82, 101, 127);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  letter-spacing: -0.43px;
  line-height: 24px;
  margin-right: 10px;
}

.tabulator-col-title-holder > div {
  width: auto !important;
  display: flex;
  align-items: center;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-arrow {
  border: 4px solid #dbdbdb;
  border-radius: 10px;
  display: none;
}

.arrow-5 {
  /* more triangle */
  position: relative;
  height: 0px;
  width: 0px;
  border: 12px solid;
  border-color: #dbdbdb #dbdbdb transparent transparent;
  transform: rotate(135deg);
}

.arrow-5:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 12px;
  width: 16px;
  background-color: darkcyan;
  transform: rotate(-45deg) translate(2px, 1px);
}
/* .tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="asc"]
    .tabulator-col-content
    .tabulator-col-title,
  .tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="desc"]
    .tabulator-col-content
    .tabulator-col-title {
    font-weight: bold;
  } */

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="asc"]
  .tabulator-col-content
  .tabulator-arrow {
  height: 0px;
  width: 0px;
  border: 3px solid;
  border-color: #52657f #52657f transparent transparent;
  transform: rotate(-45deg);
  position: absolute;
  top: 1px;
  bottom: 0;
  margin: auto;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="asc"]
  .tabulator-col-content
  .tabulator-arrow:before,
.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="desc"]
  .tabulator-col-content
  .tabulator-arrow:before {
  /* content: ""; */
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 4px;
  width: 7px;
  background-color: #000;
  transform: rotate(-45deg) translate(2px, 1px);
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="desc"]
  .tabulator-col-content
  .tabulator-arrow {
  height: 0px;
  width: 0px;
  border: 3px solid;
  border-color: #52657f #52657f transparent transparent;
  transform: rotate(135deg);
  position: absolute;
  top: 0;
  bottom: 5px;
  margin: auto;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical
  .tabulator-col-content
  .tabulator-col-title {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip
  .tabulator-col-title {
  transform: rotate(180deg);
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-col-title {
  padding-right: 0;
  padding-top: 20px;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip
  .tabulator-col-title {
  padding-right: 0;
  padding-bottom: 20px;
}

.tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-title,
.tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-title {
  font-family: "Graphik-Bold-Web" !important;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-arrow {
  right: calc(50% - 6px);
}

.tabulator .tabulator-header .tabulator-frozen {
  display: inline-block;
  position: absolute;
  z-index: 1;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #000;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #000;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  min-width: 400%;
  background: #f2f2f2 !important;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: #f2f2f2 !important;
}

.tabulator
  .tabulator-header
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 400%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableHolder {
  /* position: relative; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid #c8d5db;
  border-left: 1px solid #c8d5db;
}

.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder {
  width: 100% !important;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #cbcbcb;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-placeholder[tabulator-render-mode="virtual"] {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  color: #000;
  font-weight: 700;
  font-size: 20px;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  /* position: relative; */
  display: inline-block;
  background-color: #fff;
  white-space: nowrap;
  overflow: visible;
  color: #000;
  font: normal 0.87em OpenSans-Regular;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs {
  font-weight: 700;
  background: #f2f2f2 !important;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #000;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #000;
}

.tabulator .tabulator-col-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-footer {
  padding: 15px 0px;
  background-color: #fff;
  text-align: right;
  color: #000;
  font-weight: 100;
  white-space: nowrap;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: calc(100% + 20px);
  margin: -5px -10px 5px;
  text-align: left;
  background: #f2f2f2 !important;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #000;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  background: #f2f2f2 !important;
}

.tabulator
  .tabulator-footer
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
  border-bottom: none;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  border: 0px;
  background: hsla(0, 0%, 100%, 0.2);
  color: #a0a0a0;
  font-family: inherit;
  font-weight: inherit;
  font-size: 1.3em;
  outline: none;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: #008dfd;
  font-weight: 700;
}

.tabulator-page:disabled {
  opacity: 0.5;
  color: #a0a0a0;
}

.tabulator-page:not(.disabled):hover {
  cursor: pointer;
  color: #008dfd;
}

.tabulator-page:not(.disabled) {
  cursor: pointer;
  color: #666;
}

.tabulator-page:disabled:hover {
  cursor: not-allowed;
  color: #666;
}

.tabulator .tabulator-loader {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  font-weight: 700;
  font-size: 16px;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-loading {
  border: 4px solid #000;
  color: #000;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-error {
  border: 4px solid #d00;
  color: #590000;
}

.tabulator-row {
  /* position: relative; */
  box-sizing: border-box;
  min-height: 22px;
  border-bottom: 1px solid #c8d5db;
  color: #52657f;
  display: flex;
}

.tabulator-row,
.tabulator-row:nth-child(2n) {
  background-color: #fff;
}

.tabulator-row.tabulator-selectable:hover {
  background-color: #a100ff;
  cursor: pointer;
  color: #fff;
}

.tabulator-row.tabulator-selected {
  /* background-color: #9abcea; */
}

.tabulator-row.tabulator-selected select {
  color: black;
}

.tabulator-row.tabulator-selected:hover {
  /* background-color: #008dfd; */
  cursor: pointer;
}

.tabulator-row.tabulator-moving {
  position: absolute;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none !important;
  z-index: 2;
}

.tabulator-row .tabulator-row-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  display: inline-block;
  position: absolute;
  background-color: inherit;
  z-index: 1;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #000;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #000;
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 14px;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  /* display: flex;
    color: inherit;
    font-family: "Graphik-Regular-Web";
    font-size: 12px;
    letter-spacing: -0.43px;
    justify-content: flex-start;
    align-items: center;
    margin: 12px 0 !important;
    padding: 0 20px; */

  color: inherit;
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  letter-spacing: -0.43px;
  align-items: center;
  margin: 12px 0 !important;
  padding: 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  height: 24px !important;
  line-height: 22px;
  outline: none;
}

.tabulator-cell[tabulator-field="roleCell"] {
  padding: 0;
}

.tabulator-cell[tabulator-field="contact"] {
  justify-content: center;
  padding: 0;
}

.selectChkBx {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin: 0 !important;
}

img.edit-pen {
  width: 16px;
}

.role-text-container {
  height: 22px;
  width: 100%;
  padding: 0 5px 0 0;
}

.role-text {
  width: 100%;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 10;
  display: flex;
  justify-content: space-around;
}

img.role-dropdown-icon {
  width: 9px;
  height: 9px;
  position: relative;
  right: -13px;
  top: 6px;
  z-index: 10;
}

.pa-role-select,
.pa-role-select:focus,
.pa-role-select:hover {
  padding: 3px;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  color: #52657f;
  border: 1px solid #a100ff !important;
  border-radius: 4px;
  outline: none;
}

.pa-role-select .pa-role-option {
  background-color: white;
  color: #52657f;
}
/* .pa-role-select option:hover {
  background-color: #a100ff !important;
  color: white;
} */

#table-pa .tabulator-row .tabulator-cell.tabulator-editing {
  border: 0;
  padding: 0px;
  outline: 0px;
  overflow: initial;
}

.tabulator-row.tabulator-selectable:hover .edit-pen {
  filter: invert(0) sepia(0) saturate(0) hue-rotate(0deg) brightness(2);
}

.tabulator-row .tabulator-cell:last-of-type {
  border-right: none;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1d68cd;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #d00;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input,
.tabulator-row .tabulator-cell.tabulator-validation-fail select {
  border: 1px;
  background: transparent;
  color: #d00;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row
  .tabulator-cell.tabulator-row-handle
  .tabulator-row-handle-box
  .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: #666;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  border: 1px solid #000;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #000;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #000;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #000;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #666;
  color: #fff;
  font-weight: 700;
  font-size: 1.1em;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: 0.7;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-open,
.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle
  .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  padding: 5px;
  padding-left: 10px;
  background: #fafafa;
  font-weight: 700;
  min-width: 100%;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  border-bottom: 0;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 .tabulator-arrow {
  margin-left: 20px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 .tabulator-arrow {
  margin-left: 40px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 .tabulator-arrow {
  margin-left: 60px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 .tabulator-arrow {
  margin-left: 80px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 .tabulator-arrow {
  margin-left: 100px;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid #666;
  vertical-align: middle;
}

.tabulator-row.tabulator-group span {
  margin-left: 10px;
  color: #666;
}

.tabulator-edit-select-list {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  max-height: 200px;
  background: #fff;
  border: 1px solid #000;
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 4;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item {
  padding: 4px;
  color: #000;
  font-size: 0.87em;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  color: #fff;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #fff;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-group {
  border-bottom: 1px solid #000;
  padding: 4px;
  padding-top: 6px;
  color: #000;
  font-weight: 700;
}

#roleDP {
  width: 99%;
  border: white;
  text-align-last: center;
  box-sizing: border-box;
  cursor: pointer;
  color: black;
}

.tabulator-row.tabulator-selected #roleDP {
  background-color: #008dfd;
  color: white;
}

#select-all {
  text-align: center;
  vertical-align: middle;
}

/* Role Assignment */
/* select#roles,
  #pm_container select.form-control {
    height: 25px;
    border-radius: 0px;
    background-color: #fbfbfb;
    cursor: pointer;
    font: normal 0.87em OpenSans-Regular;
    width: 100%;
    padding: 0px 5px;
  } */

label.no-padding.control-label {
  padding: 3px 0px;
  margin: 0px;
  font-size: 1em;
}

label.control-label {
  padding: 3px 20px;
  margin: 0px;
  font-size: 1em;
}

.add-margin {
  margin: 10px 0px;
}

/* Checkbox on Table Header */
.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  cursor: pointer;
}
.checkmark-container {
  margin: 0% 40%;
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #000;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #000;
  left: 8px;
  top: 12px;
}

.close {
  position: absolute;
  right: 300px;
  top: 7px;
  width: 10px;
  height: 10px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 15px;
  width: 3px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.pa-modal .modal-content,
.rm_modal .modal-content,
.um_modal .modal-content {
  border-radius: 16px;
}

.pa-modal .modal-content,
.rm_modal .modal-body,
.um_modal .modal-body {
  padding: 0 !important;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
}

.pa_modal .modal-dialog,
.pa_modal_confirm .modal-dialog,
.um_modal .modal-dialog,
.um_modal_confirm .modal-dialog,
.ra_modal .modal-dialog,
.ra_modal_confirm .modal-dialog,
.rm_modal .modal-dialog,
.rm_modal_confirm .modal-dialog,
.error-modal .modal-dialog,
.dashboard-modal .modal-dialog {
  width: 400px;
  margin-top: 15%;
}

.pa_modal .modal-content,
.pa_modal_confirm .modal-content,
.um_modal .modal-content,
.um_modal_confirm .modal-content,
.ra_modal .modal-content,
.ra_modal_confirm .modal-content,
.rm_modal .modal-content,
.rm_modal_confirm .modal-content {
  /* border-radius: 0; */
  border: 0;
}

.pa_modal .button_container,
.dashboard-modal,
.um_modal_confirm .button_container,
.ra_modal_confirm .button_container,
.rm_modal_confirm .button_container {
  text-align: center;
}

.pa_modal .button_container,
.um_modal .button_container,
.rm_modal .button_container,
.ra_modal .button_container {
  background: rgb(249, 249, 249);
  border-radius: 0 0 16px 16px;
  border-top: 1px solid rgb(220, 226, 231);
  padding: 15px 40px;
  display: flex;
  justify-content: flex-end;
}

.pa_list_container li {
  list-style-type: disc;
  /* font: normal 0.87em "OpenSans-Regular"; */
  font: normal 1em "Graphik-Regular-Web";
}

.pa_modal .button_container .btn,
.pa_modal_confirm .button_container .btn,
.um_modal .button_container .btn,
.um_modal_confirm .button_container .btn,
.ra_modal .button_container .btn,
.ra_modal_confirm .button_container .btn,
.rm_modal .button_container .btn,
.rm_modal_confirm .button_container .btn {
  color: rgb(99, 115, 129);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  border: 1px solid rgb(196, 205, 213);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 10px 20px;
  margin-left: 10px;
  width: 85px;
}

.pa_list_container,
.um_list_container {
  font-size: 0.9em;
  min-height: 200px;
}

.pa_list_container {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
}

.ra_list_container,
.rm_list_container {
  font-size: 0.9em;
  min-height: 80px;
  padding-bottom: 10px;
}

.pa_modal #saveBtn,
.rm_modal #saveBtn,
.um_modal #saveBtn {
  margin-right: 0;
}

#denyBtn,
#approveBtn,
#okBtn,
#saveBtn,
#createBtn,
#yesBtn,
#addBtn {
  color: #fff;
  outline: none;
  background: rgb(161, 0, 255);
  border-radius: 25px;
  font-family: "Graphik-Medium-Web";
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 10px 20px;
  width: 85px;
  font-size: 14px;
}

.bo_create_modal_confirm #yesBtn,
.update_modal #yesBtn,
.modules_modal #yesBtn,
.uc_modal_confirm #yesBtn,
.supportMessageModal #yesBtn,
.pa_modal #approveBtn,
.pa_modal #denyBtn {
  margin-right: 15px;
}
.pa_modal #approveBtn {
  padding: 10px;
}

#deleteBtn {
  /* padding-left: 40px; */
  float: left;
  outline: none;
  flex-direction: row;
  margin-right: auto !important;
  margin-left: 0;
}

.modal_header_row {
  border-bottom: 1px solid rgb(220, 220, 220);
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.pa_modal.title,
.rm_modal_title,
.um_modal_title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Medium-Web;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 24px;
  padding: 20px 30px 17px 30px;
  position: relative;
  width: 100%;
}

.ra_modal_title-message,
.um_modal_title-message,
.rm_modal_title-message {
  /* color: rgb(45, 58, 75); */
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  letter-spacing: -0.35px;
  padding: 20px 30px 17px 30px;
  position: relative;
  width: 100%;
}

.form-horizontal {
  padding: 10px 40px;
}

.modal-field-row {
  width: 100%;
  margin: 0 !important;
  display: flex;
  position: relative;
}

.modal-field-title {
  position: absolute;
  z-index: 1;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium-Web;
  font-size: 11px;
  letter-spacing: 0.2px;
  padding: 10px 15px !important;
}

.modal-field {
  padding: 25px 13px 5px 13px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  width: 100%;
}

.modal-field > #contact {
  cursor: pointer;
}

.radio-field .modal-field {
  border: none;
  padding: 0 !important;
  width: 20px;
  margin-right: 10px;
}

.modal-field-row.radio-field {
  padding-top: 20px !important;
}

.radio-field .modal-field-title {
  color: rgb(33, 43, 54);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  align-self: center;
  position: relative;
  padding: 8px 0 !important;
}

.modal-field-blank {
  padding: 15px 13px 15px 13px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  width: 100%;
}

.modal-field > input,
.modal-field-blank > input {
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  color: rgb(33, 43, 54);
  font-size: 16px !important;
  letter-spacing: -0.35px;
  border-radius: 4px !important;
  font-family: Graphik-Regular-Web !important;
}

/* .pa_modal_title,
  .um_modal .um_modal_title,
  .ra_modal .ra_modal_title,
  .rm_modal .rm_modal_title {
    font-family: OpenSans-Regular;
    color: #008dfd;
  } */

.rm_modal .ra_modal_prompt,
.ra_modal .ra_modal_prompt,
.um_modal .um_modal_prompt {
  font-family: OpenSans-Regular;
  color: #000;
}

.um_modal_confirm .modal-content,
.ra_modal_confirm .modal-content,
.rm_modal_confirm .modal-content,
.error-modal .modal-content {
  border: 0;
  border-radius: 16px;
}

.error-modal .modal-body svg.svg-inline--fa.fa-times-circle,
.rm_modal_confirm .modal-body svg.svg-inline--fa.fa-exclamation-circle {
  color: #a100ff;
}

.um_modal_confirm .modal-body,
.ra_modal_confirm .modal-body,
.rm_modal_confirm .modal-body,
.error-modal .modal-body {
  text-align: center;
}

.error-modal .error-title {
  font: normal 1.5em OpenSans-Regular;
}

.pa_modal_confirm .pa_modal_title,
  .rm_modal_confirm .rm_modal_title,
  .um_modal_confirm .um_modal_title,
/* .ra_modal_confirm .ra_modal_title,
.rm_modal_confirm .rm_modal_title, */
.error-modal .content_container {
  font-family: Graphik-Regular-Web;
  padding: 25px 0px;
  word-break: break-word;
}

.pa_modal_confirm .pa_modal_title,
.ra_modal_confirm .ra_modal_title,
.ra_modal .ra_modal_title {
  color: rgb(45, 58, 75) !important;
  font-size: 20px !important;
  font-family: Graphik-Medium-Web !important;
  font-weight: 500 !important;
  letter-spacing: -0.35px;
  line-height: 24px;
  word-break: break-word;
  margin-left: 35px !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.message_modal_header_row {
  text-align: center;
  padding: 15px;
}

.um_modal_confirm .fa-thumbs-up,
.um_modal_confirm .fa-thumbs-down,
.ra_modal_confirm .fa-thumbs-up,
.ra_modal_confirm .fa-thumbs-down,
.rm_modal_confirm .fa-thumbs-up,
.rm_modal_confirm .fa-thumbs-down,
.pa_modal .fa-thumbs-up,
.pa_modal .fa-thumbs-down {
  color: #008dfd;
  font-size: 2em;
  padding-top: 15px;
}

.um_modal_confirm .fa-check-circle,
.ra_modal_confirm .fa-check-circle,
.rm_modal_confirm .fa-check-circle {
  color: #008dfd;
}

.um_modal_confirm .fa-times-circle,
.ra_modal_confirm .fa-times-circle,
.rm_modal_confirm .fa-times-circle {
  color: red;
  font-size: 3em;
  padding-top: 15px;
}

.um_list_container .svg-inline--fa.fa-fw,
.ra_list_container .svg-inline--fa.fa-fw,
.rm_list_container .svg-inline--fa.fa-fw {
  width: 0.5em;
  color: #61b1ff;
  margin-bottom: 2px;
}

.rm_list_container .svg-inline--fa.fa-fw {
  margin-right: -3px;
}

.um_list_container div.form-group,
.um_list_container .form-group label,
.ra_list_container div.form-group,
.ra_list_container .form-group label,
.rm_list_container div.form-group,
.rm_list_container .form-group label {
  text-align: left;
  margin-bottom: 5px;
  font-weight: normal;
  padding-top: 10px;
}

/* .ra_list_container .form-group label, */
.rm_list_container .form-group label {
  padding-left: 0px;
}

.ra_list_container .form-group label {
  /* .rm_list_container .form-group label { */
  /* padding-left: 0px; */
}

.um_list_container .form-group div,
.ra_list_container .form-group div,
.rm_list_container .form-group div {
  padding: 0px;
}

.um_list_container .form-group label,
.um_list_container .form-group input .ra_list_container .form-group label,
.ra_list_container .form-group input .rm_list_container .form-group label,
.rm_list_container .form-group input {
  font: normal 0.87em "OpenSans-Regular";
  color: #000;
  padding-top: 5px;
}

.um_list_container .form-group input,
/* .ra_list_container .form-group input, */
.rm_list_container .form-group input {
  height: 25px;
  border: 1px solid #dbdbdb;
  background-color: #fbfbfb;
  border-radius: 0px;
  padding: 3px;
  width: 190px;
  box-shadow: none;
  font: normal 1em OpenSans-Regular;
}

.rm_list_container .form-horizontal .form-group {
  margin: 0;
}

.ra_list_container .form-group input {
  width: 350px;
  height: 56px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;

  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  outline: none;
  padding-left: 14px;
}

#contact {
  width: 15px;
  padding: 0px;
  height: 20px;
}

.um_list_container .form-group div .requiredMessage,
.ra_list_container .form-group div .requiredMessage,
.rm_list_container .form-group div .requiredMessage {
  font: normal 0.87em "OpenSans-Regular";
  color: red;
  height: 1em;
}

.um_list_container .form-group input.required {
  border: 1px solid #008dfd;
}

.um_list_container .form-group div .requiredMessage {
  padding-left: 15px;
}

#RAValidationMsg .hide,
#UMValidationMsg .hide,
#RMValidationMsg .hide {
  display: none;
}
.ra_list_container .react-autosuggest__suggestions-container--open {
  width: 88%;
}

.ra_list_container ul.react-autosuggest__suggestions-list {
  padding: 0px;
  background-color: #fbfbfb;
  border: 1px solid #dbdbdb;
  font-family: OpenSans-Regular;
  font-style: normal;
  color: #000;
  max-height: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.react-autosuggest__suggestions-list li:hover {
  background-color: #dbdbdb75;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  z-index: 2;
}

.message_modal_header_row .svg-inline--fa.fa-fw {
  width: 1.5em;
  color: #a100ff;
  margin: 15px;
  margin-bottom: 2px;
  border: 1px solid;
  border-radius: 100%;
  border-width: 3px;
  height: 1.5em;
  padding: 14px;
}

.required {
  border: 1px solid #a100ff;
  box-shadow: 0 0 3px #a100ff6e;
}

button.pa-btn,
button.pa-btn:hover,
button.pa-btn:focus,
button.pa-btn:active,
button.pa-btn:active:focus,
button.pa-btn:active:hover {
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  text-align: center;
  letter-spacing: -0.27px;
  padding: 8px 22px;
  float: right;
  margin: 0 10px;
  color: #a100ff;
  background: #fff;
  border-radius: 20px;
  border: 2px solid #a100ff;
}

button.pm-btn,
button.pm-btn:hover,
button.pm-btn:focus,
button.pm-btn:active,
button.pm-btn:active:focus,
button.pm-btn:active:hover {
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 25px 12px 25px;
}

button.pm-btn.btn.btn-default:disabled,
button.pm-btn.btn.btn-default:disabled:hover {
  /* font: normal 1.2em OpenSans-Regular; */
  color: #fff;
  /* border-radius: 0;
  border: 0;
  width: 150px;
  height: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin-left: 20px;
  float: right;
  margin-top: 25px;
  margin-bottom: 60px; */
  background-color: #a0a0a0;
  border-color: #a0a0a0;

  font-size: 14px !important;
  font-family: Graphik-Regular-Web !important;
  font-weight: 500 !important;
  text-align: center !important;
  letter-spacing: -0.25px !important;
  line-height: 14px !important;
  padding: 12px 25px 12px 25px !important;
}

button.pa-btn.btn.btn-default:disabled,
button.pa-btn.btn.btn-default:disabled:hover {
  background: #a0a0a0 !important;
  color: rgb(255, 255, 255) !important;
  cursor: not-allowed;
  border: 2px solid #a100ff;
}

/* #pa_container .pa-btn.btn-default:disabled,
#pa_container .pa-btn.btn-default:disabled:hover {
  background-color: #a0a0a0;
} */

.ra_list_container .form-group input.error,
.rm_list_container .form-group input#RoleDescription.required {
  border: 1px solid #a100ff;
  box-shadow: 0 0 3px #a100ff6e;
}

#pm_container select.form-control[disabled] {
  background-color: #dbdbdb;
}

.user-report-modal .modal-dialog {
  border-radius: 16px;
}

.user-report-modal .modal-content {
  background: rgb(255, 255, 255);
  border-radius: 16px !important;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.user-report-modal-header {
  color: rgb(45, 58, 75);
  font-family: "Graphik-Medium-Web";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 24px;
  display: flex;
}

.user-report-modal-header > div {
  width: 100%;
}

.user-report-modal .modal-body {
  padding: 0;
}

.user-report-modal-body {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Regular-Web";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 24px;
  border-top: 1px solid rgb(220, 220, 220);
  border-bottom: 1px solid rgb(220, 220, 220);
  padding: 20px 40px;
}

.modal-header-title {
  padding: 20px 40px;
}

.user-report-modal .create-project-header-parent-img {
  margin: 0;
  padding: 20px 40px;
}

.button_container.user-report-modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  background: rgb(249, 250, 251);
  border-radius: 0 0 16px 16px;
}

.button_container.user-report-modal-footer > div {
  background: rgb(255, 255, 255) !important;
  border-radius: 24px;
  border: 2px solid rgb(145, 158, 171);
  height: 40px;
  width: 90px;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  margin-left: 15px;
}

.button_container.user-report-modal-footer > div > button {
  color: rgb(99, 115, 129);
}

.user-report-modal .user-report-modal-footer > div {
  margin: 0 !important;
}

#uReport_container {
  padding: 25px 90px;
}

#cancelBtn {
  margin: 0;
}

.ra-dd-search,
.ra-refresh-add {
  display: flex;
}

.ra-refresh-add {
  justify-content: flex-end;
}

select#roles.form-control {
  width: 200px;
  height: 40px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 20px;

  /*text*/
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 14px;
}

.ra-search-input {
  width: 300px !important;
}

.ra-search-container {
  padding-left: 15px;
}

img.ra-search-bar-icon {
  width: 16px;
  position: absolute;
  top: 11px;
  left: 237px;
  display: flex;
  float: left;
}

.pa_modal .modal-body,
.pa_modal .modal-content,
.ra_modal .modal-body,
.ra_modal .modal-content {
  padding: 0 !important;
  border-radius: 16px;
  border: 1px solid #dce2e7;
  width: 426px;
  /* height: 276px; */
  background: rgb(255, 255, 255);
  /* border: 1px solid rgb(220, 226, 231); */
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.pa_list_container,
.ra_list_container {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}

.pa_list_container h1 {
  font-size: 16px;
  padding-left: 20px;
}

.pa_modal-value,
.ra-modal-value {
  padding-left: 14px;
  padding-top: 17px;
}

.pm-header {
  display: flex;
}

.pm-dd-ar,
.pm-dd-an {
  padding-left: 15px;
}

#table-pm .tabulator-col-title {
  overflow: inherit !important;
}

.pa-footer-cut-container {
  z-index: 10;
  /* background: red; */
  margin-left: -45px;
  margin-right: -45px;
  border-radius: 0px 0px 5px 5px;
  background: rgb(243, 243, 243);
  border-top: 1px solid #c8d5db;
  height: 80px;
  padding-top: 20px;
  /* padding: 20px 45px; */
}
#pending-buttons {
  margin-right: 40px;
}

.pa-footer-btn-container {
  float: right;
  padding-right: 47px;
}

.ra-add-user-btn,
.ra-add-user-btn:hover {
  cursor: not-allowed;
}

.ra-delete-icon {
  font-size: 14px;
  color: grey;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  opacity: 0.8;
}

/* Custom Select START */
/*the container must be positioned relative:*/
.custom-select {
  /* position: relative; */
  font-family: "Graphik-Regular-Web";
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: #fff;
  color: black;
  border: 1px solid #dfe3e8;
  padding: 0 10px;
  position: relative;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  right: 10px;
  border: 5px solid transparent;
  border-color: #000 transparent transparent transparent;
  top: 10px;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #000 transparent;
  top: 5px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .select-selected {
  border: 1px solid #dfe3e8;
  padding: 0 10px;
      z-index: 999; 
  position: relative;
} */

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  z-index: 99;
  border: 1px solid #dfe3e8;
  box-shadow: 0 0 10px #dfe3e8;
  border-radius: 0 0 5px 5px;
  padding: 5px 10px;
  width: 150px;
  height: 250px;
  overflow: auto;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: #a100ff;
  color: #fff !important;
  padding: 0 5px;
}

/* Custom Select END */
