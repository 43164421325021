.single-select-dropdown-with-search {
  padding: 0.375rem 0;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik-Medium;
  line-height: 1;
  color: #A100FF;
  background: #fff;
  border-radius: 16px;
  height: 32px;
  width: 100%;
  border: 1.5px solid #A100FF;
  cursor: pointer;
}

.single-select-dropdown-with-search .multi-select-label {
  margin-top: -26px;
  display: flex;
  align-items: center;
}

.single-select-dropdown-with-search .check-box {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.single-select-dropdown-with-search .cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.single-select-dropdown-with-search .cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.single-select-dropdown-with-search .cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.single-select-dropdown-with-search .cvm-checked .svg-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'] > div[class*='-control'],
.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'] > div[class*='-control']:hover
{
  /* background-color: #fff !important; */
  border-style: none;
  box-shadow: none !important;
  /* margin-top: -16px; */
  /* margin-top: -22px !important; */
  height: 35px;
  display: flex;
  align-content: center;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'],
.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container']:hover
{
  border-radius: 5px;
  box-shadow: none;
  padding: 0 ;
  z-index: 2;
  /* width: 100%; */
  font-size: 16px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'] > div[class*='-menu'] {
  background: white !important;
  margin-top: 0;
  z-index: 100000 !important;
}

.single-select-dropdown-with-search .labels {
  position: relative;
  display: -webkit-inline-box;
}

.single-select-dropdown-with-search .cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: row;
}

.single-select-dropdown-with-search .cont> div[class*='-singleValue'] {
  background-color: white;
  border-radius: 16px;
  display: flex;
  margin: 3px 4px;
  box-sizing: border-box;
  border: 0;
  height: 24px;
  padding: 0px 0px 0px 0px;
  align-items: center;
  width: 100%;
  /* max-width: 235px; */
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #A100FF;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik-Medium;
}

.single-select-dropdown-with-search #mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 5px 8px 5px 10px;
}

.single-select-dropdown-with-search #mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}

.single-select-dropdown-with-search .dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.single-select-dropdown-with-search .dropdown-cancel {
  margin: 0px;
}

.single-select-dropdown-with-search .dropdown-apply {
  margin: 0px;
}

.single-select-dropdown-with-search .dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}

.single-select-dropdown-with-search #mt-count-tooltip .search-bar-container .search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}

.single-select-dropdown-with-search #mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}

.single-select-dropdown-with-search .arigato {
  background-color: blue;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  box-shadow: none;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>svg {
  background-color: blue;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>div>div {
  background-color: rgb(0, 255, 34);
}

.single-select-dropdown-with-search #mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .single-select-dropdown-with-search {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.single-select-dropdown-with-search .data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .single-select-dropdown-with-search {
  cursor: pointer;
}

.single-select-dropdown-with-search div[class*='-indicatorContainer']>svg {
  height: 25px !important;
  width: 25px !important;
  color: #A100FF;
}

.single-select-dropdown-with-search .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.single-select-dropdown-with-search .requiredText {
  color: red;
}

.single-select-dropdown-with-search .required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.single-select-dropdown-with-search .placeholder-title {
  color: #637381;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 0 0 10px 0;
  padding: 0 7px;
  opacity: 1;
  top: -9px;
  position: relative;
  left: 7px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover,
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control],
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover {
  margin-top: -22px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover,
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>div[class*=-control],
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover {
  margin: auto 0;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer'] {
  top: -12px;
}

.single-select-dropdown-with-search .cont>div[class*=-Placeholder] {
  color: #A100FF;
}

.select-profile .single-select-dropdown-with-search,
.added-profiles .single-select-dropdown-with-search {
  height: 60px;
  padding: 0.375rem 0 0 0;
}

.add-new-program-container .single-select-dropdown-with-search div[class*='-multiValue']>div:first-child {
  font-size: 10px;
}

.add-new-program-container .single-select-dropdown-with-search #mt-count-tooltip div[class*='-container']>div[class*='-menu'] {
  width: 420px;
}