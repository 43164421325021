.stage-container {
  width: 100%;
  padding: 43px 15px 20px 15px;
}

.configure-client-collapse, .subdimension-section-collapse {
  width: 100%;
  margin: 10px 0px !important;
}

.configure-client-collapse > .card,
.subdimension-section-collapse > .card {
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
}

.configure-client-collapse .card-header,
.customboi-card-collapse .card-header,
.subdimension-section-collapse .card-header {
  background: #fff;
  padding: 10px 20px;
  border: none;
}

.configure-client-collapse .card-header > button,
.customboi-card-collapse .card-header > button,
.subdimension-section-collapse .card-header > button {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 0;
}

.configure-client-collapse .card-header > button:focus,
.customboi-card-collapse .card-header > button:focus,
.subdimension-section-collapse .card-header > button:focus {
  outline: none !important;
  box-shadow: none;
}

.configure-client-collapse .card-header > button .svg-container,
.customboi-card-collapse.card-header > button .svg-container,
.subdimension-section-collapse .card-header > button .svg-container {
  /* height: 20px; */
  display: flex;
}

.configure-client-collapse .card-body,
.customboi-card-collapse .card-body,
.subdimension-section-collapse .card-body {
  padding: 0px 20px;
}

.add-project-button {
  margin-left: auto;
}

.add-project-button button > * {
  font-family: "Graphik-Medium";
  font-size: 14px;
  margin: 0 5px;
}

.add-project-button button svg {
  width: 13px !important;
}

.rvd-proj-btn {
  margin: auto 0 !important;
  justify-content: center;
}

.add-new-program-container {
  padding: 25px 40px;
  max-height: initial !important;
  overflow-y: visible !important;
}

.add-program-buttons,
.notifyModal-buttons,
.add-boi-buttons,
.CVMSideModal-footer,
.add-program-buttons.enable-new-client {
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px;
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border: 1px solid rgb(223, 227, 232);
}

.add-program-buttons {
  border: none;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(223, 227, 232);
}

.configure-client-buttons {
  display: flex;
  justify-content: flex-end;
}

.add-program-buttons button,
.configure-client-buttons button,
.notifyModal-buttons button,
.CVMSideModal-footer button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 6px 20px;
  margin: 0 0 0 25px;
}

.added-program-container {
  width: 100%;
}

.empty-program-container {
  width: 100%;
  padding: 50px;
}

.empty-program-container {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-program-container > span {
  margin: 32px 0 24px 0;
}

.added-program-table-container {
  height: 400px;
}
.saveDraft-toast,
.notify-toast {
  position: fixed;
  top: 70px;
  margin-top: inherit;
  right: 40px;
  z-index: 1070;
}
.saveDraft-toast .close,
.saveDraft-toast.close:before,
.saveDraft-toast .close:after,
.notify-toast .close,
.notify-toast.close:before,
.notify-toast .close:after {
  position: inherit;
}
.saveDraft-toast h5,
.notify-toast h5 {
  font-size: 14px;
  font-family: "Graphik-Medium-Web";
  margin-bottom: 0px;
}
.saveDraft-toast .sr-only,
.saveDraft-toast .close:after,
.saveDraft-toast .close::before,
.notify-toast .sr-only,
.notify-toast .close:after,
.notify-toast .close::before {
  display: none;
}
.saveDraft-toast .toast,
.notify-toast .toast {
  flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}
.saveDraft-toast .toast-header,
.notify-toast .toast-header {
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.saveDraft-toast .toast-header svg,
.notify-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}
.saveDraft-toast .close,
.notify-toast .close {
  font-size: 2rem;
  opacity: 1;
}
.saveDraft-toast .close.ml-2,
.notify-toast .close.ml-2 {
  margin-left: 2rem !important;
}
.saveDraft-toast .close.mb-1,
.notify-toast .close.mb-1 {
  margin-bottom: 1.2rem !important;
}
@media (min-width: 992px) {
  .notifyModal-dialog.modal-dialog.modal-lg,
  .notifyModal-dialog.modal-dialog.modal-xl,
  .modal65.modal-dialog.modal-lg,
  .modal65.modal-dialog.modal-xl,
  .notifyModal-dialog.modal-dialog,
  .submitSettings-dialog.modal-dialog,
  .modal-dialog.select-project-modal {
    max-width: 51.2% !important;
  }
}
@media (min-width: 1200px) {
  .submitSettings-dialog.modal-dialog {
    max-width: 70% !important;
  }
}
.notifyModal .tab-component-purple .react-tabs__tab,
.notifyModal .tab-component-purple .react-tabs__tab--selected,
.notifyModal .tab-component-purple ul.react-tabs__tab-list {
  margin-bottom: 16px;
}
.notifyModal-dropdown .multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  max-width: inherit;
  width: inherit;
  display: block;
}
.notifyModal-dropdown .css-oq3rz7-multiValue {
  width: 190px;
}
.notifyModal-dropdown .css-14s1juc-control {
  width: 530px;
  max-width: 540px;
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-du0ed4-indicatorContainer {
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-1csqycf-control {
  max-width: inherit;
  width: inherit;
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-gvyuxe-indicatorContainer {
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-1hwfws3 {
  padding-left: 0px;
}
.notifyModal-dropdown .css-oq3rz7-multiValue {
  color: rgb(161, 0, 255);
}
.notifyModal-textarea #groove-textarea .floating-label-textbox {
  margin-top: 32px;
}
.notifyModal-textarea #groove-textarea .groove-textbox {
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  outline: none;
  padding-left: 24px;
  padding-top: 12px;
}
.notifyModal-textarea {
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  outline: none;
  padding: 12px 24px;
  margin-top: 32px;
}
.notifyModal-textarea .textareaLabel {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 0.5rem;
}
.notifyModal-textarea textarea {
  border: none;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  letter-spacing: -0.25px;
  line-height: 12px;
  font-weight: normal;
  outline: none;
  color: #212b36;
  font-size: 1rem;
  width: 100%;
}
.padding-b15 {
  padding-bottom: 15px;
}

.config-client-button-container {
  text-align: right;
}

.config-client-button-container > div {
  display:inline-flex;
}

.error-message {
  color: red;
}

.add-program-buttons.client-config {
  padding: 20px;
}

.notifyModal-dropdown > div:first-child > div:first-child > div:first-child {
  display: none;
}
#groove-textarea .groove-textbox{
  font-family: "Graphik-Regular";
  font-size: 12px !important;
  color: #212b36;
}