.single-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
}


.single-select-dropdown
  > div[class*="-container"]
  > div[class*="-control"]
  > div {
  max-height: 65px;
  overflow-y: auto;
}

.single-select-dropdown > div:nth-child(2), .single-select-dropdown > div:nth-child(2) > div {
  width: 100%;
}