.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Table headers */
.ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(33, 43, 54);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

/* cell style */
.ag-theme-alpine .ag-ltr .ag-cell {
  border-right-width: 1px;
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
  display: inline;
  margin: 10px 0;
}

.ag-theme-alpine .ag-root-wrapper {
  border: solid 1px;
  border-color: #e7eaee;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}

.ag-header,
.ag-pinned-left-header,
.ag-pinned-right-header {
  display: inline-block;
  overflow: hidden;
  position: relative;
  background: #f9fafb !important;
  border-bottom: 1px solid #e7eaee !important;
}

.ag-row-odd {
  background: #f9fafb !important;
  border: 1px solid #e7eaee !important;
  border-width: 0.5px 0 0.5px 0 !important;
}

.ag-row-even {
  background: #ffffff !important;
  border: 1px solid #e7eaee !important;
  border-width: 0.5px 0 0.5px 0 !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-theme-alpine .ag-icon {
  font-weight: 900 !important;
}

.ag-theme-alpine .ag-icon-asc:before {
  content: "\f12b";
  font-size: 12px;
}

.ag-theme-alpine .ag-icon-desc:before {
  content: "\f128";
  font-size: 12px;
}
.table-container .ag-theme-alpine .ag-icon-desc:before {
  width: 9px;
  height: 9px;
  background: url(../../../Static/images/360cvm/caret-down.svg) no-repeat center center;
  background-size: 100%;
  font-size: 0;
  display: inline-block;
  position: absolute;
  top: 42%;
}
.table-container .ag-theme-alpine .ag-icon-asc:before {
  width: 9px;
  height: 9px;
  background: url(../../../Static/images/360cvm/caret-down.svg) no-repeat center center;
  background-size: 100%;
  font-size: 0;
  display: inline-block;
  position: absolute;
  top: 42%;
  transform: rotate(180deg);
}
.table-container .ag-theme-alpine .ag-icon-menu:before {
  display: none;
}
.ag-paging-panel {
  height: 40px !important;
  background: #f9fafb;
  display: none;
}

/* Pagination */
.cvm-table-pagination-container {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid rgb(231, 234, 238);
  padding: 5px;
  display: flex;
}

.pagination {
  margin: 0 !important;
}

li#prevPage > button {
  margin-right: 15px;
}
li#nextPage > button {
  margin-left: 15px;
}

.pagination > li > button {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  min-width: 30px;
  color: #7b8e98;
  outline: none;
  margin: 0 5px;
  line-height: 1;
}
.pagination > li > button:hover {
  border: 1px solid #a100ff;
}

button.pagination-btn-disable {
  color: rgb(220, 226, 231) !important;
  cursor: not-allowed;
  border: none !important;
}

button.pagination-btn-pages {
  background: none !important;
  border: none !important;
  min-width: 24px !important;
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
  margin: 0 !important;
}
button.pagination-btn-pages:hover {
  border: 1px solid #a100ff !important;
  margin-left: -2px !important;
}

button.pagination-btn-active.pagination-btn-pages {
  background: rgb(161, 0, 255) !important;
  border-radius: 3px !important;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
}
button.pagination-btn-active.pagination-btn-pages:hover {
  margin: 0;
}

/* Page Size dropdown */
.page-size {
  display: -ms-flexbox;
  display: flex;
  margin: 0 10px;
  align-items: center;
}

.page-size > label {
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 0 10px;
}

.page-size {
  display: flex;
}

.page-size svg {
  width: 12px;
  fill: #7b8e98;
}

/* width */
::-webkit-scrollbar {
  height: 29px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border: 5px solid transparent;
  border-width: 10px 0 10px 0;
  border-radius: 14px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
