.dashboard-commentary-modal {
  max-width: 600px !important;
}

.dashboard-commentary-history-modal {
  max-width: 800px !important;
}

.dashboard-commentary-history-modal .dashboard-commentary-comments {
  border: none;
}

.dashboard-commentary-modal .commentary-comment > div {
  width: 100% !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.dashboard-commentary-history-modal .dashboard-commentary-comments div.comment-card {
  padding: 10px 0px 0px;
}

.dashboard-commentary-comments .commentary-history-label {
  margin-bottom: 0px !important;
  background: white !important;
  cursor: default !important;
}

.commentary-boidetails-container .commentary-boi-details {
  display: flex;
  width: 100%;
}

.commentary-boidetails-container .commentary-boi-details > div {
  width: 100%;
  font-size: 12px;
  padding-right: 20px;
}

.commentary-boi-details > div > div:first-child {
  font-weight: 600;
}

.dashboard-commentary-modal .modal-header,
.dashboard-commentary-history-modal .modal-header {
  padding: 20px 20px 0px !important;
}

.dashboard-commentary-modal .modal-body > div:first-child {
  padding: 20px !important;
}

.dashboard-commentary-modal .commentary-add-comments {
  display: flex;
  margin-top: 10px;
}

.commentary-add-comments > div {
  padding: 0px 10px;
}

.dashboard-commentary-modal .commentary-add-comments > div:nth-child(3) {
  margin-top: 7px !important;
  margin-right: 7px;
  cursor: pointer;
}

.dashboard-commentary-modal .project-detail-tabs button[aria-selected='false'] span {
  color: rgb(99, 115, 129);
}

.dashboard-commentary-modal .commentary-add-comments #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
}

.commentary-add-comments #addComment::placeholder {
  font-size: 12px;
}

.dashboard-commentary-modal .commentary-add-comments #addComment:focus-visible {
  box-shadow: none !important;
  background: #fff !important;
  border: 1px solid rgb(161, 0, 255);
  outline: none;
}

.dashboard-commentary-modal .modal-title > div,
.dashboard-commentary-history-modal .modal-title > div > div {
  font-size: 20px !important;
}

.commentary-comments {
  min-height: 200px;
  max-height: 300px;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
  margin: 9px 0px;
  overflow-y: auto;
}

.dashboard-commentary-comments {
  padding: 10px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
}

.dashboard-commentary-comments div.comment-card {
  padding: 10px 0px;
}
.dashboard-commentary-comments div.edit-comment-card {
  padding: 0px 0px 10px 0px;
}

.dashboard-commentary-comments div.comment-card > div,
.dashboard-commentary-comments div.edit-comment-card > div {
  display: flex;
}

.dashboard-commentary-comments div.comment-card > div > div > div,
.dashboard-commentary-comments div.edit-comment-card > div > div > div {
  padding: 0px 5px;
}

.dashboard-commentary-comments div.comment-card > div > div:nth-child(2),
.dashboard-commentary-comments div.edit-comment-card > div > div:nth-child(2) {
  padding: 5px 0px;
}

.dashboard-commentary-comments div.comment-card > div > div:nth-child(2) > div:first-child,
.dashboard-commentary-comments div.edit-comment-card > div > div:nth-child(2) > div:first-child {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Medium-Web';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.dashboard-commentary-comments div.comment-card > div > div:nth-child(2) > div:nth-child(2),
.dashboard-commentary-comments div.edit-comment-card > div > div:nth-child(2) > div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular-Web';
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.dashboard-commentary-comments div.edit-comment-card .agnostic-disclaimer-container {
  height: 60px;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 16px;
}

.dashboard-commentary-comments .commentary-comment > div {
  background: rgb(255, 255, 255) !important;
  border-radius: 6px !important;
  box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%) !important;
  min-height: 40px !important;
  padding: 10px !important;
  width: 93% !important;
  margin-left: auto !important;
  margin-right: 5px !important;
  cursor: unset !important;
}

.dashboard-commentary-history-modal
  .dashboard-commentary-comments
  .commentary-comment
  > div:not(.projectHistory) {
  width: 100% !important;
  margin-left: unset !important;
  padding: 0 !important;
  box-shadow: unset !important;
}

.commentary-comment > div > span {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
}

.dashboard-commentary-comments .commentary-comment > div > div.btn-icon {
  white-space: nowrap;
  float: right;
}

.dashboard-commentary-comments .commentary-comment > div > div.btn-icon > span {
  float: right;
  padding-top: 5px;
  color: rgb(161, 0, 255);
  cursor: pointer;
  margin-left: 10px;

  font-size: 12px;
  line-height: 16px;
}

.send-disabled {
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events: none;
}

.commentary-no-comment {
  width: 100%;
  text-align: center;
  font-size: 13px;
  padding: 40px 0px !important;
}

.commentary-no-comment > div {
  display: block !important;
}

.commentary-update-comment #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 93%;
  margin-left: auto;
  margin-right: 5px;
}

.commentary-update-comment #addComment::placeholder {
  font-size: 12px;
}

.dashboard-commentary-modal .commentary-update-comment #addComment:focus-visible {
  box-shadow: none !important;
  background: #fff !important;
  border: 1px solid rgb(161, 0, 255);
  outline: none;
}

.dashboard-commentary-tabs .update-comments-buttons {
  display: flex;
  float: right;
  margin-right: 10px;
  margin-top: 15px;
}

.update-comments-buttons > div:first-child {
  margin-right: 15px;
}

.comment-edit-header > div {
  font-size: 20px;
  margin-left: 10px;
}

.comment-edit-header > svg {
  padding-top: 5px;
}

/* start */
/* .commentary-popup .commentary-dropdown {
    flex: 1;
  } */

.commentary-dropdown {
  margin: 0 20px;
}

.commentary-dropdown .single-select-dropdown,
.commentary-dropdown .single-select-label {
  width: inherit;
}

.commentary-dropdown .css-dvua67-singleValue {
  color: rgb(161, 0, 255);
}

.dIL-dropdowns .commentary-dropdown .css-mf9lpj-control {
  width: 200px;
}

.commentary-dropdown .css-144vaf5-menu {
  width: 190px;
  position: absolute;
  right: 0;
}

.commentary-dropdowns {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.dashboard-commentary-modal
  .commentary-comment
  #groove-input
  .floating-label
  input.form-control.groove-input.commentary-label {
  padding: 10px !important;
}

.dashboard-commentary-comments > div {
  margin: 2px !important;
}

.dashboard-commentary-history-modal .back-modal-btn,
.dashboard-commentary-modal .back-modal-btn {
  margin-right: 15px;
  cursor: pointer;
}

.comment-loader {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-loader .lds-ellipsis {
  position: relative;
  margin: 0;
}

.dashboard-commentary-modal .card-header > button > div {
  word-break: break-all;
  text-align: left;
}

.commentary-tab-panel {
  left: 0 !important
}