.Btns {
  float: right;
  margin-left: 10px;
}
.selectBtnBOI {
  height: 40px;
  width: 147px;
  float: right;
}
.headerAlign {
  display: flex;
    align-items: baseline;
    min-height: 45px;
}

.toggle-dimensions {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: right;
  width: 168px;
}

.viewComment-button > :disabled {
  cursor: not-allowed;
}

.infoIcon {
  margin: 1px 4px;
}
.missing {
  margin: 0;
  color: red;
  padding: 0 40px;
  margin-right: auto;
}

#groove-input .input-box > input.input-invalid {
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}
