/* Master Config Landing */
.master-config-contents {
  margin: 20px 0;
  height: 100%;
}
.management-table-container .table-container {
  height: 500px;
  width: 100%;
}

@media (min-width: 992px){
  
  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:first-child,
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:first-child{
    max-width: 14% !important;
    padding-left: 30px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(2),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(2){
    max-width: 17% !important;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(3),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(3){
    min-width: 25% !important;
    padding-right: 25px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(4),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(4){
    min-width: 30% !important;
    padding-left: 80px;
    padding-right: 20px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(5),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(5){
    width: 15% !important;
    margin-left: 110px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(6),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(6){
    padding-left: 100px;
  }
}
/* Search */

.search-bar-container {
  position: relative;
}
.search-bar {
  position: absolute;
  right: 0;
  margin: 10px 0 0 0;
}

.search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(231, 234, 238);
  outline-style: none;
}

.search-bar svg {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

.master-config-contents .search-bar-container {
  height: 80px;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.master-config-contents .search-bar-container > .search-bar,
.master-config-contents .search-bar-container > #groove-btn-id {
  position: relative;
  height: max-content;
  margin: 0;
  display: flex;
  align-self: center;
}

.master-config-contents .search-bar-container > .search-bar input,
.master-config-contents .search-bar-container input#searchBox {
  width: 300px;
  padding: 8px 10px 7px 45px;
}
