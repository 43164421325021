#groove-btn-id > button > * {
    font-size: 12px;
    margin: 0 5px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:focus,
.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:focus:not(:placeholder-shown) {
    border-top: 1px solid #a100ff!important;
    padding: 1.3rem 16px 15px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown) {
    border-top: 1px solid #dfe3e8 !important;
    padding: 1.3rem 16px 15px;
}

.deleted-item {
    max-width: 72%;
    word-break: break-word;
}

.info-icon > span.tooltiptext {
   width: 250px;
}

#groove-textarea #deleted-boi.groove-textbox {
    border: 1px solid rgb(181, 192, 202);
}

#groove-textarea #deleted-boi.groove-textbox:focus, 
#groove-textarea #deleted-boi.groove-textbox:focus:not(:placeholder-shown) {
    border: 1px solid rgb(181, 192, 202) !important;
}

.ppc-sfa-modal-container {
    overflow-y: visible!important;
}