.bodytext12-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.bodytext12-regular {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 5px;
}

.bodytext14 th {
  color: black;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.iouser-table .fa-plus-circle {
  cursor: pointer;
}

td .delete-icon {
  /* margin: 0 60px 0 0; */
  cursor: pointer;
  opacity: 0;
}

tr:hover td .delete-icon {
  opacity: 1;
}

.iomanagement-table-container .subdimension-row .card-header {
  padding: 10px 8px;
}

.iomanagement-table-container .card-header .row {
  padding-left: 0;
}

.iomanagement-table-container .subdimension-header>.row {
  padding: 12px;
  padding-left: 50px;
}

.iomanagement-table-container .collapse.show~div.card-header div.svg-container svg {
  transform: rotate(90deg);
}

.iomanagement-table-container .svg-container.tiny {
  font-size: 0.9em;
}

.iomanagement-table-container .svg-container {
  margin-left: 5px;
}

.iomanagement-table-container .iouser-table tr td .svg-container {
  margin-left: 10px;
}

.iomanagement-table-container .svg-container.small {
  height: 14px;
  width: 14px;
}

.iomanagement-table-container .svg-container .fa-caret-right {
  margin-left: 0px;
}

.iomanagement-table-container .card-header .bodytext14 {
  color: rgb(161, 0, 255);
}

.iomanagement-table-container .subdimension-row.accordion .card-header .col-lg-3:nth-child(2) {
  padding-left: 8px;
}

.iomanagement-table-container .subdimension-row.accordion .card-header .btn:focus, .iomanagement-table-container .subdimension-row.accordion .card-header .btn.focus {
  box-shadow: none;
}

.iomanagement-table-container .subdimension-row.accordion .card-header .btn {
  padding: 0.375rem 0.5rem;
  width: 50px;
}

.iomanagement-table-container .iouser-table {
  width: 100%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(1), .iomanagement-table-container .iouser-table tr td:nth-child(1) {
  width: 50%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(2), .iomanagement-table-container .iouser-table tr td:nth-child(2) {
  width: 16%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(3), .iomanagement-table-container .iouser-table tr td:nth-child(3) {
  width: 29%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(4), .iomanagement-table-container .iouser-table tr td:nth-child(4) {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* text-align: center; */
}

.iomanagement-table-container .subdimension-header .col-lg-3 {
  display: flex;
  align-items: center;
}

.iomanagement-table-container .subdimension-header .svg-container.small {
  width: 16px;
  height: 16px;
  font-size: 1em;
}

@media only screen and (max-width: 988px) {
  .iomanagement-table-container .subdimension-header .col-lg-3 {
    width: 50%;
  }
}

.subdimension-body-container {
  max-height: 420px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 420px;
  background: white;
}

.iomanagement-table-container .subdimension-table {
  border: none;
  background: transparent;
}

.iomanagement-table-container .subdimension-header {
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgb(231, 234, 238);
  border-bottom: 1px solid rgb(231, 234, 238);
}

.iomanagement-table-container .subdimension-row .card {
  background: transparent;
}

.iomanagement-table-container .subdimension-row .card-header {
  background: rgb(255, 255, 255);
  border-radius: 0px !important;
  border: 1px solid rgb(231, 234, 238);
}

.iomanagement-table-container .subdimension-row .card-body {
  padding: 0 1.5rem;
}

.iomanagement-table-container .iouser-table tr:first-child {
  background: rgba(0, 186, 255, 0.06);
  border-radius: 4px;
}

.iomanagement-table-container .iouser-table tr:nth-child(even) {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding-left: 100px;
}

.iomanagement-table-container .iouser-table tr th, .iomanagement-table-container .iouser-table tr td {
  padding: 10px;
}

#filter-popover {
  width: 21.5rem;
  max-width: inherit;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  left: -64px !important;
}

#filter-popover.popover .arrow {
  display: none;
}

#filter-popover .popover-body {
  padding: 0.75rem 1.25rem;
}

#popover-searchbox .search-bar {
  left: 0;
  top: 0;
  margin: 0;
}

#popover-searchbox #searchBox {
  width: 82%;
}

#filter-popover .search-bar-container .search-bar {
  position: relative;
}

.filter-select-table ul {
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0;
  max-height: 10rem;
  overflow-y: auto;
}

.filter-select-table ul li {
  padding: 13px 16px;
}

.filter-select-table ul li:hover {
  background: rgb(244, 246, 248);
  border-radius: 0px;
}

.filter-action-btns {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 8px 8px;
}

.delete-profile {
  cursor: pointer;
}

.undo-delete-btn {
  margin-left: 92px;
  text-decoration: underline;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.deleteToast.saveDraft-toast .toast {
  border: 1px solid rgb(234, 128, 151);
  background-color: rgb(255, 245, 247);
  font-size: 16px;
}

.deleteToast.saveDraft-toast .toast-header {
  background-color: rgb(255, 245, 247);
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.userRemovedText {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  margin-bottom: 0px;
}

.deleteToast.saveDraft-toast .toast-header>svg {
  color: rgb(213, 0, 47);
}

.panel {
  display: none;
  overflow: hidden;
}

.checkbox-label {
  margin-top: 60px;
  margin-left: 37px;
  font-weight: 500;
  margin-right: 20px;
}

.filter-btn {
  cursor: pointer;
}

.search-container {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  height: 32px;
  width: 261px;
}

.search-container {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
}

.filter-container {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 266px;
  width: 341px;
}

.buttonFrame {
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 8px 8px;
  height: 50px;
  width: 275px;
  margin-top: 120px;
}

.clearButton {
  margin-left: 20px;
  margin-top: 10px;
}

.applyButton {
  margin-top: 10px;
  margin-left: 20px;
}

.searchbar {
  width: 350px;
  height: 32px;
}

.add-new-program-container div.groove-input-custom-label {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
}

.sort-io {
  cursor: pointer;
  display: inline-flex;
}

.sort-btn-container {
  width: 20px;
  height: 16px;
}

.replacement-modal #groove-input .groove-input:disabled {
  background: #f2f2f2;
}

.replacement-modal #groove-input .groove-input,
.replacement-modal #groove-input .groove-input:disabled {
  border: 1px solid rgba(0, 0, 0, .2);
}

.replacement-modal #groove-input .groove-input:focus {
  border-radius: 0.3rem;
}