.card-title {
  max-width: 85%;
}

.cardH {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.titleText-medium-20 {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
}

.titleText-medium-18 {
  color: rgb(161, 0, 255);
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
}

.titleText-medium-14 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.titleText-medium-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
}

/* new rvd starts here */

.rvd-titleText-medium-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 15px;
  height: 20px;
  width: 96px;
}

.rvd-program-card-box {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 178px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid transparent;
}

.rvd-titleText-medium-14 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rvd-titleText-regular-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.rvd-program-card-wrap {
  padding: 15px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  /* height: 178px; */
  height: 78%;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 45px 30% 19% auto auto auto;

}

/* new rvd ends here */

.titleText-regular-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

#createdby-text {
  width: 67px;
  min-width: max-content;
  padding-right: 35px;
}

#created-text {
  width: 67px;
  min-width: max-content;
  padding-right: 32px;
}

.ioa-mapped-suboffering-container {
  color: #ffc82c;
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.applyFlex-align-center {
  display: flex;
  align-items: center;
}

.applyFlex-align-center.apply-relative .single-select-dropdown .single-select-label {
  width: 100px;
}

.program-dropdown-project-landing .single-select-label {
  width: 13%;
}

.program-dropdown-project-landing .css-1hwfws3,
.program-lead-landing .css-1hwfws3 {
  padding-left: 0px;
}

.applyFlex-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.applyFlex-justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.applyFlex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.landing-img-box {
  border-radius: 0.5px;
  border-left: 1px solid rgb(151, 151, 151);
  line-height: 1.5rem;
  margin-left: 1.5rem;
  padding: 0;
  /* display: none; */
}

.landing-img-box img:first-child {
  margin: -10px 5px -10px 5px;
  cursor: pointer;
}

.landing-img-box img:nth-child(2) {
  margin: -4px 0 0 0;
  cursor: pointer;
  /* color :rgb(99, 115, 129); */
  /* opacity: .4; */
}

.landing-img-box img.disabled-view {
  cursor: not-allowed;
}

.edited-program-project {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 8px;
  border: none;
  margin-top: 0.1em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 50px;
  text-align: center;
  display: inline-block;
  margin-left: 3px;
  align-self: flex-start;
}

/* clientDetails-popover css starts here */

@media only screen and (max-width: 995px) {
  .program-tooltip {
    background: #fff;
    position: fixed;
    top: 100px;
    left: 600px;
    transform: translate(-50%);
  }

  .bs-popover-right>.arrow {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin: 0.3rem 1.1rem !important;
  }
}

#clientDetails-popover,
#programDetails-popover {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  max-width: 470px;
  width: max-content;
  z-index: 2000;
}

#clientDetails-popover .popover-body>div,
#programDetails-popover .popover-body>div {
  display: flex;
}

#clientDetails-popover .popover-body>div>.sub-section {
  display: flex;
  flex-direction: column;
  margin: 0 50px 0 0;
}

#programDetails-popover .popover-body>div>.sub-section {
  display: flex;
  flex-direction: column;
  margin: 0;
}

#clientDetails-popover.bs-popover-auto[x-placement^='right']>.arrow:before,
#clientDetails-popover.bs-popover-right>.arrow:before,
#programDetails-popover.bs-popover-auto[x-placement^='right']>.arrow:before,
#programDetails-popover.bs-popover-right>.arrow:before {
  border-right-color: rgba(0, 0, 0, 0.05);
}

#clientDetails-popover .close-btn,
#programDetails-popover .close-btn {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #637381;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

#clientDetails-popover .popover-header,
#programDetails-popover .popover-header {
  background: #fff;
  border-bottom: none;
  padding: 0;
}

#clientDetails-popover .popover-body,
#programDetails-popover .popover-body {
  padding: 0.5rem 1rem 0.5rem;
  color: rgb(78 78 78);
  font-size: 12px;
  letter-spacing: -0.21px;
  line-height: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.detail-popup-section {
  margin-bottom: 1rem;
}

.detail-popup-section .section-title {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.detail-popup-section .section-desc {
  font-family: Graphik-Regular;
  font-weight: normal;
  margin-bottom: 10px;
}

.detail-popup-section .section-desc:last-child {
  margin-bottom: 0;
}

.detail-popup-section .section-desc.ppc-multiple-users {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 190px;
}

.sub-section {
  display: flex;
}

#clientDetails-popover .stage-status span,
#programDetails-popover .stage-status span {
  padding: 4px 9px;
  background: rgb(126, 195, 68);
  border-radius: 3px;
  line-height: 10px;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.18px;
}

#clientDetails-popover .stage-status .stage-completed,
#programDetails-popover .stage-status .stage-completed {
  background: rgb(126, 195, 68);
  color: rgb(255, 255, 255);
}

/* clientDetails-popover css ends here */

.back-icon {
  font-size: 24px;
  color: rgb(161, 0, 255);
}

.dimension-table-row .info-icon {
  margin: -10px 10px 0 10px;
}

.info-icon {
  height: 13px;
  width: 13px;
}

.client-details-button {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;
}

.client-details-button img {
  margin: 0 -8px 0 -15px;
}

.add-program-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.add-program-line>div:nth-child(2) {
  display: flex;
  align-items: center;
}

.apply-relative {
  position: relative;
}

/* program cards screen css starts here */

.program-card-box {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.program-card-wrap {
  padding: 15px;
  cursor: pointer;
  width: 100%;
  height: 78%;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 45px 30% 19% auto auto auto;
}

.project-card-wrap {
  padding: 15px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 33px 26% 21% auto auto auto;
}

.project-card-wrap.project-disabled {
  cursor: not-allowed;
  opacity: .5;
}

.bottom-item {
  display: inline-block;
  white-space: nowrap;
  flex: 1;
}

#banIcon {
  position: relative;
  top: -5px;
}

.rejectedGenTooltip {
  z-index: 3010;
  margin-top: 3px;
}

.rejectedGenTooltip .tooltip-inner {
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
  text-align: left;
  max-width: 300px;
  padding: 10px;
}

@media only screen and (min-width: 1400px) {
  .program-card-wrap {
    grid-auto-rows: 45px 25% 21% auto auto auto;
  }
}



@media only screen and (max-width: 900px) {
  .program-card-wrap {
    grid-auto-rows: 45px 25% 21% auto auto auto;
  }

  .project-card-wrap {
    grid-auto-rows: 33px 26% 23% auto auto auto;
  }
}

.program-card-detail {
  display: flex;
  margin-bottom: 7px;
  /* min-height: 26px; */
}

.program-card-detail>div:first-child {
  margin-right: 5px;
  width: min-content;
}

.program-card-detail>img {
  padding: 5px 0;
  width: 25px;
  cursor: pointer;
  margin: auto 0;
}

.text-gap {
  margin-top: 5px;
}

.program-card-detail .ppc-text-ellipsis,
.project-card-title .ppc-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.program-card-detail .title-ellip {
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  min-inline-size: -webkit-fill-available;
  min-inline-size: -moz-available;
  white-space: unset;
  margin-bottom: 6px;
  line-height: 115%;
}

.program-card-detail .action-owner-list {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.program-card-container {
  margin-top: 12px;
}

.program-card-container>div>div {
  margin-bottom: 20px;
}

#program-details-status {
  margin: 0;
  width: auto;
}

.card-row-status {
  border-radius: 3px;
  line-height: 11px;
  font-size: 11px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.2px;
  padding: 3px 8px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  height: fit-content;
}

.text-tile {
  min-width: max-content;
}

.status-new {
  background: #018EFF;
}

.status-completed {
  background: rgb(126, 195, 68);
}

.status-rejected {
  background: rgb(213, 0, 47);
}

.status-inProgress {
  background: rgb(255, 200, 44);
}

.status-notStarted {
  background: rgb(145, 158, 171);
}

.status-conditionallyApproved {
  background: rgb(244, 147, 66);
}

.dim-status-completed {
  color: rgb(126, 195, 68);
  background-color: white;
}

.dim-status-rejected {
  color: rgb(213, 0, 47);
  background-color: white;
}

.dim-status-inProgress {
  color: rgb(255, 200, 44);
  background-color: white;
}

.dim-status-notStarted {
  color: rgb(145, 158, 171);
  background-color: white;
}

.dim-status-conditionallyApproved {
  color: rgb(244, 147, 66);
  background-color: white;
  padding: 0px;
}

.dim-no-status {
  background-color: white;
}

.dim-no-status {
  color: transparent;
  background-color: transparent;
}

#groove-btn-id button {
  display: flex;
}

.program-card-box #groove-btn-id {
  display: flex;
  justify-content: center;
}

.program-card-box .detail-row {
  width: 100%;
  background: rgb(249, 250, 251);
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin-top: 5px;
  z-index: 100;
}

/* .program-card-box .program-card-wrap .wrap-bottom {
  margin-bottom: 42px;
} */

/* .program-card-completed + .wrap-bottom {
  margin-bottom: 100px !important ;
} */

/* program cards screen css ends here */

.program-lead-landing .single-select-label,
.program-lead-landing .css-1pcexqc-container {
  width: inherit;
}

.program-lead-landing .close-btn {
  position: static;
}

.program-lead-landing .applyFlex-space-between div:first-child {
  width: 100%;
}

.program-lead-landing .info-icon {
  top: 0;
  right: 0;
}

.program-lead-landing .info-icon svg {
  cursor: pointer;
}

@media (min-width: 768px) {
  .program-lead-landing .col-md-6 {
    flex: 0 0 46%;
    max-width: 46%;
  }
}

/* Stepper css */

.program-lead-landing .md-step.active~div>.md-step-img-circle {
  background: #e3d1ff !important;
  color: #a100ff;
}

.program-lead-landing .md-stepper-horizontal .md-step .md-step-title,
.program-lead-landing .md-stepper-horizontal .md-step .md-step-optional {
  color: rgb(117, 0, 192);
}

textarea {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.add-program-buttons {
  background: white;
  border: none !important;
  padding: 20px 20px 20px 40px;
}

.add-program-buttons.ppc {
  justify-content: space-between;
}

.add-program-buttons.ppc.proj {
  justify-content: space-between;
  flex-direction: column;
}

.add-program-buttons.ppc>.missing-container {
  justify-content: space-between;
  max-width: 80%;
  font-size: 14px;
  font-family: 'Graphik-Medium';
}

.add-program-buttons.ppc>.missing,
.add-program-buttons.ppc>.missing-container>.missing {
  padding: 0;
}
.missing.prog-creator {
  padding: 5px 0 5px 0;
  font-size: 14px;
  font-family: Graphik-Medium;
}
.missing.prog-creator.aa {
  padding: 5px 0 5px 20px;
  font-size: 14px;
  font-family: Graphik-Medium;
}
.missing-ppc-ptvs {
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Medium;
}

.enable-new-client {
  border: 1px solid rgb(223, 227, 232) !important;
}

.program-lead-landing .md-stepper-horizontal {
  border-radius: 16px;
  margin: 24px 0px;
}

.approval-sent-time {
  width: 350px;
  height: 16px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 2px;
}

.modal-form {
  width: 379px;
  height: 56px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(181, 192, 202);
  border-radius: 8px;
}

.raise-request {
  width: 300px;
  height: 14px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.27px;
}

.SendForApproval {
  width: 773px;
  height: 32px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  line-height: 16px;
}

.client-steakholder-title {
  width: 426px;
  height: 19px;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding-top: 10px;
}

.confirm-btn {
  background-color: rgb(161, 0, 255);
  color: rgb(161, 0, 255);
}

.check-label {
  width: 342px;
  height: 16px;
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin-left: 6px;
}

.upload-btn {
  margin-top: 20%;
  margin-left: 85%;
  width: 90px;
  height: 40px;
  background: rgb(161, 0, 255);
  border-radius: 20px;
  align-items: center;
}

.confirmation {
  width: 318px;
  height: 34px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.upload-later .stepper-close {
  margin-top: 15px;
  margin-right: 50px;
}

.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

/* Program table css starts here */

.program-lead-landing .table-view-wrap {
  margin-top: 40px;
}

.program-lead-landing .dimension-table-row .info-icon {
  right: inherit;
  top: inherit;
}

/* Program table css ends here */

.BT-container {
  width: 100%;
  margin: 30px auto;
  padding: 20px 35px 50px 35px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.BT-Lead-Landing-btn .applyFlex-space-between {
  place-content: flex-end;
  margin-top: 15px;
  margin-bottom: 30px;
}

.BT-Lead-Landing-Page .stepper-bt {
  margin-top: 20px;
}

.BT-Lead-Landing-bottom-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
  right: -50px;
}

.sticky-button {
  align-items: self-end;
  padding-bottom: 12px;
  position: fixed;
  height: 64px !important;
  width: 100%;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 81px;
  box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
  box-sizing: border-box;
  z-index: 1;
}

.hide-sfa {
  align-items: self-end;
  padding-bottom: 12px;
  position: fixed;
  height: 64px !important;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  display: none;
  justify-content: flex-end;
  padding-right: 81px;
  box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
}

.sticky-button-edit {
  padding-bottom: 52px;
  position: fixed;
  height: 64px !important;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 113px;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
}

.hide-sticky {
  display: none;
}

.BT-Lead-Landing-bottom-btn button {
  width: 170px;
  display: flex;
  justify-content: center;
}

.BT-Lead-Landing-bottom-btn .applyFlex-space-between {
  margin: 5px;
  margin-right: 20px;
}

.BT-Lead-Landing-Page,
.Rejected-BOIS {
  margin: 40px 80px;
}

.input .BT-Lead-Comment {
  width: 780px;
  height: 56px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 8px;
}

.BTLead-comments .BT-Lead-Comment {
  width: 723px;
  height: 88px;
  color: rgb(181, 192, 202);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 8px;
}

.BT-Lead-Comment #groove-textarea .groove-textbox {
  height: 229px;
  color: rgb(181, 192, 202);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 8px;
}

.BT-Lead-Comment #groove-input .groove-input {
  color: rgb(181, 192, 202);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  background: rgb(239, 242, 245);
}

.modal-body>div:first-child {
  padding-bottom: 40px;
}

.Rejected-BOIS .title {
  width: 759px;
  height: 36px;
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.Rejected-BOIS .close-btn {
  right: 50px;
  top: 30px;
  color: #637381;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}

.value-title {
  width: 260px;
  height: 34px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.rejected-BOIS-edit {
  float: right;
  margin-bottom: 10px;
}

.value-edit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Rejected-BOIS .table-view-wraps {
  /* display: table;
  width: 100%; */
  margin: 0 auto;
  padding: 20px 40px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.edit-BOIS-bottom-btn {
  display: flex;
  justify-content: flex-end;
  padding: 80px 0 30px 0;
}

.edit-BOIS-bottom-btn button {
  width: 165px;
  display: flex !important;
  justify-content: center;
  margin-left: 20px;
}

.btn-between {
  margin-right: 8px;
}

.textarea-add-program {
  padding: 10px;
}

.text-field {
  background: white;
}

.CACompleteDocPending {
  display: flex;
  flex-direction: column;
}

.check-circle {
  font-size: 2px !important;
  margin-bottom: 10px;
  width: 76.5px;
  height: 76.5px;
  align-self: center;
}

.Program-Configured-text {
  height: 18px;
  color: rgb(21, 24, 27);
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
  margin: 0 auto;
  margin-bottom: 10px;
  align-self: center;
}

.CACompleteDocPending .configuration-text {
  width: 85%;
  word-break: break-all;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 24px;
  margin-bottom: 10px;
  align-self: center;
}

.CACompleteDocPending .warning-msg {
  width: 315px;
  height: 17px;
  color: rgb(170, 0, 37);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  align-self: center;
  margin-bottom: 30px;
}

/* Add BOI search bar */

.add-boi-field-container.duplicate-boi,
.duplicate-boi {
  border-color: red !important;
}

.add-boi-search {
  z-index: auto;
  display: flex;
  flex-direction: row-reverse;
}

.add-boi-search .search-bar {
  position: relative;
  width: 20%;
  min-width: 350px;
}

.add-boi-search .search-bar #searchBox {
  width: 100%;
}

.table-title {
  margin: 24px 0 15px 0;
}

.table-title>.header18-semibold-midnight {
  margin-bottom: 10px;
}

.first-line {
  display: flex;
  justify-content: space-between;
}

.program-lead-landing {
  width: 100%;
}

.page-title {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
  margin-bottom: 40px;
}

.accordion-action-btns,
.prog-acct-table-header-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.accordion-action-btns {
  margin-left: auto;
}

.expandAll-button.active>button,
.collapseAll-button.active>button {
  color: #a100ff;
}

/* Client Alignment */

.document-box {
  background: rgb(251, 249, 254);
  border-radius: 8px;
  border: 1.5px solid rgba(156, 106, 222, 0.13);
  margin-bottom: 40px;
}

.envelope {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
}

.document-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 30px;
}

.document-box .icons {
  padding: 10px 20px;
  border-top: 1px solid rgba(156, 106, 222, 0.13);
  display: flex;
  justify-content: flex-end;
}

.icons .icon-bottom {
  margin-left: 20px;
  cursor: pointer;
}

.confirmation-document .comments,
.comments {
  margin: 15px 0;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:placeholder-shown) {
  background: rgb(239, 242, 245);
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202) !important;
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: rgb(99, 115, 129) !important;
  font-size: 12px !important;
  font-family: Graphik-Medium !important;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox {
  padding-top: 4.8px;
  border-radius: 4px;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: #637381 !important;
}

.comments #groove-textarea .floating-label-textbox #projectDescription {
  max-height: 54px;
  overflow-y: scroll !important;
}

.Requestor-comments .groove-textbox-custom-label-disabled,
div.groove-textbox-custom-label {
  color: #a100ff !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 12px;
  margin: 5px 0;
  padding: 2px 7px;
  border: rgb(117, 0, 192) !important;
}

.groove-textbox-custom-label:not([hidden])~label {
  display: none;
}

/* Client Confirmarion Docs */

.check-box {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.cvm-checkbox .svg-container {
  display: none;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.check-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.client-confirmation-docs {
  padding: 24px 0;
}

.cvm-dropzone {
  background: rgb(251, 249, 254);
  border-radius: 16px;
  border: 2px dashed rgb(145, 158, 171);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  margin: 40px 0 15px;
}

.cvm-dropzone.invalid {
  border-color: red;
}

.cvm-dropzone.valid {
  border: 1px solid green;
  cursor: pointer;
  border-color: green;
}

.invalid-reason {
  color: red;
  margin-top: 8px;
}

.cvm-dropzone-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-top: 22px;
}

.cvm-dropzone-label>span {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.cvm-dropzone-sub-label {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-top: 15px;
}

.uploaded-files-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.uploaded-files {
  display: flex;
  width: 48%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
}

.uploaded-files-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  width: 300px !important;
}

.uploaded-files-progressbar {
  background: rgb(231, 234, 238);
  border-radius: 4px;
  height: 10px;
}

.upload-progress {
  height: 100%;
  width: 100%;
  background: rgb(156, 106, 222);
  border-radius: 4px;
}

.uploaded-files>div {
  width: 100%;
}

.uploaded-files-icon,
.uploaded-files-close {
  width: 15px !important;
}

.uploaded-files-close {
  padding: 0 10px;
}

.uploaded-files-close .svg-container.small>svg,
.uploaded-files-icon .svg-container.small>svg {
  width: 9px;
}

textarea.upload-file-comment {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  resize: none;
  color: rgb(33, 43, 54);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  height: 200px;
  outline: none;
  padding: 10px;
}

.upload-pending-container {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-pending-label {
  color: rgb(196, 205, 213);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin: 30px 0 20px 0;
}

.upload-pending-desc {
  color: rgb(196, 205, 213);
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 42px;
  letter-spacing: -0.28px;
  line-height: 21px;
  text-align: center;
  width: 792px;
}

.programEdit-icon:hover+.tooltiptext {
  visibility: visible;
}

#tooltiptext-program.tooltiptext {
  right: 42px;
  left: auto;
  width: auto;
}

.tooltiptext.programDetails {
  top: 0;
  left: 15px;
  background-color: transparent;
}

.tooltiptext.projectDetails {
  top: 0;
  left: auto;
  right: 25px;
  background-color: transparent;
}

.existing-program {
  color: red;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-right: 10%;
}

.edit-rejected-boi {
  cursor: not-allowed !important;
}

.edit-rejected-boi .delete-boi-button {
  color: #bcbcbc;
}

.no-confirmation-doc-container {
  padding: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.no-confirmation-doc-container>div {
  color: #495057 !important;
}

.no-confirmation-doc-container>svg {
  color: rgb(161, 0, 255);
  font-size: 70px;
}

.no-confirmation-doc-container .label-cont {
  padding-top: 2.5%;
  padding-bottom: 1%;
}

.deleted-header {
  font-weight: 400px;
}

.deleted-boi {
  margin-bottom: 8px;
}

.deleted-boi-field {
  height: 20px !important;
}

.modal-message {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 32px;
  line-height: 16px;
  margin-bottom: 10px;
}

.groove-dtpicker-custom-label-disabled,
div.groove-dtpicker-custom-label {
  padding: 0 0 0 10px !important;
}

.modal-title-special {
  display: flex;
  align-items: center;
}

.modal-title-special .svg-container {
  height: auto;
  font-size: 1.2em !important;
}

.add-boi-back-button {
  margin-right: 10px;
  cursor: pointer;
}

.select-boi-header,
.select-boi-body-row {
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgb(231, 234, 238);
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
}

.select-boi-body-row {
  border-radius: 0;
  border-top: none;
  padding: 15px 25px;
}

.select-boi-body-row:nth-child(odd) {
  background: rgb(255, 255, 255);
}

.select-boi-modal-column {
  width: 15%;
  align-items: center;
  display: flex;
}

.boi-name {
  overflow: hidden;
}

.select-boi-modal-column:first-child {
  width: 20px;
}

#uncheckboi-popover {
  width: 400px;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
  max-width: none;
  display: none;
  margin: -30px 20px;
  left: auto;
  top: auto;
}

#uncheckboi-popover .popover-body {
  width: 351px;
}

#uncheckboi-popover .arrow {
  display: none;
}

#uncheckboi-popover>.popover-body {
  color: #fff;
}

.select-boi-modal-column:hover #uncheckboi-popover {
  display: table-caption !important;
}

.select-boi-header>.select-boi-modal-column {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

.select-boi-body-row>.select-boi-modal-column {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.select-boi-footer {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  margin-top: 10px;
}

.cvm-custom-check-box {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 20px;
  width: 20px;
  cursor: pointer;
  padding: 0 2.5px;
}

.cvm-custom-check-box:hover {
  border: 1px solid rgb(161, 0, 255);
  box-shadow: 0 0 5px rgb(193 88 255);
}

.select-all-boi-checked {
  background: rgb(244, 251, 255);
  border: 2px solid rgb(161, 0, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-all-boi-checked>div {
  background: rgb(161, 0, 255);
  border-radius: 2px;
  width: 10px;
  height: 10px;
}

.cvm-custom-checked {
  background: rgb(161, 0, 255);
  border-color: rgb(161, 0, 255);
}

/* Custom Radio Button */

.custom-boi-label {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  letter-spacing: -0.35px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-weight: normal;
}

/* Hide the browser's default radio button */

.custom-boi-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.custom-boi-checkmark,
.custom-boi-checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(196, 205, 213);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.custom-boi-label:hover input~.custom-boi-checkmark,
.custom-boi-label:hover input~.custom-boi-checkmark2 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.custom-boi-label input:checked~.custom-boi-checkmark {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  height: 24px;
  width: 24px;
}

.custom-boi-label input:checked~.custom-boi-checkmark2 {
  background: rgb(161, 0, 255);
  border: 1.5px solid rgb(161, 0, 255);
  height: 16px;
  width: 16px;
  left: 4px;
  top: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.custom-boi-checkmark:after,
.custom-boi-checkmark2::after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

/* .custom-boi-label input:checked ~ .custom-boi-checkmark:after {
  display: block;
} */

/* Style the indicator (dot/circle) */

.custom-boi-label .custom-boi-checkmark:after,
.custom-boi-label .custom-boi-checkmark2:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.custom-boi-radio {
  display: flex;
}

.custom-boi-radio label:first-child {
  padding-right: 50px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}

.lead-options {
  display: flex;
}

.lead-options label.custom-boi-label {
  font-family: Graphik-Regular;
}

.customboi-dropdown .dropdown-list .dropdown-item:first-child {
  border-bottom: 1px solid #e6e6e6;
}

.add-subdimension-show,
.reason-boidefinition-show,
.reason-aggrelogic-show {
  display: block !important;
}

.add-subdimension-hidden,
.reason-boidefinition-hidden,
.reason-aggrelogic-hidden {
  display: none !important;
}

.rvd-link {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.rvd-link a:hover {
  text-decoration: none;
}

.metric-calc {
  display: contents;
}

.boidefinition-parameter {
  /* display: flex; */
  width: 100%;
}

.boidefinition-parameter .row {
  margin: 0;
}

.customboi-add-header {
  width: 87%;
  text-align: left;
}

.customboi-selected-header {
  width: 73%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 480px) {
  .customboi-selected-header{
    width:30%;
  }
}
@media screen and (min-width: 840px) {
    .customboi-selected-header{
      width: 50%;
    }

}
.no-boidefinition-warning {
  background: rgb(255, 245, 247);
  border-radius: 8px;
  border: 1px solid rgb(213, 0, 47);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  padding: 8px 15px;
  display: flex;
}

.no-boidefinition-warning .no-boidefinition-text {
  width: 100%;
  color: rgb(197, 0, 6);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  padding: 0px 15px;
  position: relative;
  top: 3px;
}

.disable-row,
.disable-check-box {
  cursor: not-allowed;
}

.disable-check-box {
  background: #bcbcbc !important;
  border: 1px solid #bcbcbc;
}

.disable-check-box:hover {
  border: 1px solid #bcbcbc;
  -webkit-box-shadow: 0 0 5px #bcbcbc;
  box-shadow: 0 0 5px #bcbcbc;
}

.add-edit-top-right {
  float: right;
}

#cal-comments #groove-textarea .groove-textbox:disabled {
  background: rgb(239, 242, 245);
  border: 1px solid rgb(181, 192, 202) !important;
  font-size: 16px;
  border-top: 25px solid transparent !important;
}

#cal-comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown):disabled+label {
  color: rgb(99, 115, 129) !important;
}

.select-boi-modal-column:nth-child(2) {
  width: 480px !important;
}

.BT-Lead-Landing-Page>.empty-program-container {
  min-height: 602px;
}

.customboi-card-collapse {
  margin: 20px 0px;
  border-bottom: 1px solid red !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.customboi-card-collapse .card-header {
  height: 66px;
}

.customboi-list-card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px !important;
}

.customboi-header-btn {
  display: flex;
  position: relative;
  bottom: 28px;
  float: right;
  right: 50px;
}

.customboi-header-btn div {
  margin-left: 20px;
}

#deleteCusBoi-popover {
  display: none;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px 0px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
}

#deleteCusBoi-popover .arrow::before,
#deleteCusBoi-popover .arrow::after {
  display: none;
}

.delete-cusboi:hover #deleteCusBoi-popover {
  display: table-caption !important;
}

#deleteCusBoi-popover>.popover-body {
  color: #fff;
}

.disableWithtooltip:hover #deleteCusBoi-popover {
  display: table-caption;
}

#deleteCusBoi-popover[x-placement^='right'] {
  display: none;
  right: auto;
  left: auto;
  margin-left: 95%;
}

#deleteCusBoi-popover[x-placement^='left'] {
  display: none;
  left: auto;
  right: 80%;
}

#deleteCusBoi-popover .bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
  top: 33px;
}

#deleteCusBoi-popover .popover-body {
  width: 274px;
  max-width: 312px;
  margin-left: 0 !important;
}

.customboi-container {
  min-height: 500px;
}

#programDetails-popover .bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
  top: 0 !important;
}

span.tooltiptext.projectDetails {
  width: 400px;
}

.customboi-container .customboi-card-collapse #groove-btn-id .btn-stat-alternate.disabled,
.customboi-container .customboi-card-collapse #groove-btn-id .btn-stat-alternate:disabled {
  cursor: pointer;
}

.program-lead-landing .applyFlex-align-center.apply-relative .single-select-dropdown .single-select-label {
  width: 17%;
}

.add-program-line .single-select-dropdown .single-select-label {
  min-width: fit-content;
}

.program-lead-landing .applyFlex-align-center.apply-relative.project-dropdown .single-select-dropdown .single-select-label {
  width: 64px;
  position: relative;
}

.projectEntry-container>.row>.applyFlex-align-center.apply-relative.pde-project-dropdown .css-1hwfws3 {
  position: relative;
  left: -29px;
}

.projectEntry-container>.row>.applyFlex-align-center.apply-relative .css-1hwfws3 {
  position: relative;
  left: -19px;
}

/* Agnostic BOI styles */
.exclamation-icon-container {
  margin-right: 8px;
  border: 2px solid rgb(117, 0, 192);
  border-radius: 50px;
  height: 30px;
  min-width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.exclamation-icon-container>.svg-container {
  height: 70%;
}

.exclamation-icon {
  color: rgb(161, 0, 255);
}

.exclamation-icon-disclaimerMessage .exclamation-icon {
  margin-top: 4px;
}

.agnostic-disclaimer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 88px;
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255);
  border-radius: 8px;
  padding: 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgb(117, 0, 192);
}

.generated-project-label {
  /* background: #f4fbff; */
  background: #e7eaee;
  padding: 5px 10px;
  border-radius: 16px;
  opacity: 1;
  /* color: #637381; */
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  height: 18px;
  letter-spacing: 0.25px;
  line-height: 11px;
  width: fit-content;
  white-space: nowrap;
  /* margin-left: 5px; */
}

.project-card-title {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: end;
  align-items: center;
}

.project-card-title>img {
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.project-card-title>.card-title {
  margin-bottom: unset;
  margin-right: 5px;
  width: min-content;
}

.sort-icon {
  margin-left: 24px;
  cursor: pointer;
}

.sort-wrapper {
  position: relative;
}

.sort-menu {
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  position: absolute;
  width: 270px;
  right: 0;
  z-index: 5;
  font-size: 14px;
  top: -8px;
}

.sort-menu>div {
  padding: 10px;
  cursor: pointer;
}

.sort-menu>div>img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/*Disable system generated tile when rejected @ ppc*/

.rvd-program-card-wrap.disabled,
.table-action-buttons.disable>svg.svg-inline--fa.fa-edit.fa-icons {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

#ban-icon svg {
  position: absolute;
  color: #000;
  opacity: 0.5 !important;
}

.creation-item {
  display: inline-block;
  /* white-space: nowrap; */
  flex: 1 1;
}

div#rvd-disabled-sys>div.tooltip-inner {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  text-align: left;
  max-width: 314px;
}

.boi-stat-warning {
  padding: 20px 40px 0 40px !important;
}

/*---End-----*/

.client-confirmation-docs .check-label {
  width: fit-content;
}

.dd-tooltip-right {
  display: flex;
}

.hover-view-prog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  color: red;
  margin: 0;
  color: #a100ff;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  top: 37%;
}

#progBoxContainer:hover #progWrapContainer {
  background-color: white;
  opacity: 0.2;
  transition: 0.3s;
  content: none;
}

#progWrapContainer:hover {
  background-color: white;
  opacity: 0.05;
  transition: 0.3s;
}

#progBoxContainer:hover .hover-view-prog {
  opacity: 1 !important;
}

#progBoxContainer:hover .prog-hover {
  opacity: 0.5;
}

.prog-card-stage-cont {
  max-width: 83%;
  display: flex;
}

span.prog-card-stage-name {
  text-overflow: ellipsis !important;
  /* display: block; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  /* word-break: break-word; */
  /* min-inline-size: -webkit-fill-available; */
  min-inline-size: -moz-available;
  /* white-space: unset; */
  /* margin-bottom: 6px; */
  line-height: 115%;
  overflow: hidden;
  white-space: nowrap;
}

.prog-stage {
  height: fit-content;
}

.edit-boi-link-container {
  display: flex;
  width: 100%;
}

.edit-boi-link-icon {
  height: 12.8px;
  width: 16px;
}

.edit-boi-link-span {
  color: #a100ff;
}

.edit-boi-link-divider {
  padding: 0px 10px;
  color: rgba(209, 213, 219);
}

.edit-boi-link {
  color: #637381;
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.25px;
  margin-left: auto;
  margin-right: 5px;
}

.add-program-details .multiWithAddButton {
  margin-bottom: 1rem;
}

/* Sort Functionality Enhancements */
.column-search-container .search-bar {
  position: relative;
  margin: 0;
}

.column-search-container .rvd-sort-wrapper {
  top: 10%;
}

.column-search-container>.search-bar #searchBox {
  width: 300px;
}

.custom-sortmenu.popover {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  position: absolute;
  width: 265px;
  right: 0;
  z-index: 5;
  font-size: 14px;
  border: none;
}

.custom-sortmenu.popover .arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.custom-sortmenulist>div {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.custom-sortmenulist .activesort-item,
.custom-sortmenulist>div:hover {
  background-color: #F3F4F6;
  font-weight: 500;
  border-radius: 4px;
}

.empty-project-list {
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: 400;
  color: #DC2626;
}

.project-filter-align {
  display: flex;
  align-items: center;
}

.project-filter-align .sort-wrapper .sort-icon {
  margin-left: 24px;
}

.column-search-container .dIL-actionrow {
  margin-bottom: 0;
}

.main-reminder-button {
  position: absolute;
  right: 270px;
  top: 12px;
  z-index: 1
}

.reminder-button>.send-reminder-button {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  padding: 0 8px;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

.reminder-button>.send-reminder-button>button>div.svg-container {
  transform: none !important;
}

.reminder-button>.send-reminder-button>button>.svg-container>.svg-inline--fa {
  height: 1.1rem;
  vertical-align: auto;
  width: auto;
}

.add-program-details .selected-menu-basic{
  color: #000 !important;
}

.add-program-details .display-selected{
  color: #000000 !important;
}

.add-new-project-popup .omid-container,
.add-new-project-popup .contract-container
{
  padding-bottom: 5px;
}

.detail-popup-section-oal,
.detail-popup-section-cxl {
  margin-bottom: 1rem;
}

.detail-popup-section-oal .section-desc,
.detail-popup-section-cxl .section-desc {
  font-family: Graphik-Regular;
  font-weight: normal;
  margin-bottom: 10px;
  width: 190px;
}

.detail-popup-section-oal .section-title,
.detail-popup-section-cxl .section-title {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.detail-popup-section-cxl {
  margin-bottom: 1rem;
}

.detail-popup-section-cxl .section-desc {
  font-family: Graphik-Regular;
  font-weight: normal;
  margin-bottom: 10px;
  width: 190px;
}

.detail-popup-section-cxl .section-title {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.ellipsis-hover {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-hover:hover {
  overflow: visible; 
  white-space: normal;
}

/* Invalid project creator and io approver dropdowns in project page */
#groove-select-dropdown.project-page-dropdown-invalid .form-control, 
#groove-select-dropdown.project-page-dropdown-invalid .form-control#selectmenu-anchor:hover {
  border: 1px solid red !important;
}