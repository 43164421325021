.tabbed-dropdown-field {
  background: #fff;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  color: #000;
  font-family: Graphik-Regular-Web;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  position: relative;
  cursor: pointer;
}

.tabbed-dropdown-field.tabbed-disabled {
  cursor: not-allowed;
  border: 1px solid #dfe3e8 !important;
  background: #f9fafb;
}

.tabbed-disabled .tabbed-dropdown-value {
  color: #919eab !important;
}

.tabbed-dropdown-label {
  color: #a100ff !important;
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.tabbed-dropdown-label span {
  color: red !important;
  margin-left: 2px;
}

.tabbed-dropdown-placeholder {
  color: #919eab;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.tabbed-dropdown-value {
  position: absolute;
  top: 20px;
  bottom: 0;
  margin: auto;
  /* display: flex; */
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
}
.tabbed-dropdown-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.tabbed-dropdown-menu {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 8px;
  background-color: #fff;
  width: inherit;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 8px 0;
  z-index: 2;
  position: relative;
  width: 378px;
}

.tabbed-dropdown-menu .react-tabs__tab-list {
  border-bottom: 1px solid #dbdbdb !important;
}

.react-tabs__tab-panel {
  padding: 4px 8px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: none;
}

.tabbed-dropdown-option {
  margin-top: 8px;
  max-height: 180px;
  overflow: auto;
}

.tabbed-dropdown-select {
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  display: inline-flex;
  white-space: normal;
  width: 100%;
  color: #212b36;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-family: Graphik-Regular;
  font-weight: 400;
}

.tabbed-dropdown-select:hover,
.tabbed-dropdown-select.tabbed-selected {
  background: #a100ff !important;
  color: #fff;
}

.tabbed-dropdown-field .svg-container {
  right: 24px;
  position: absolute;
  font-size: 1.3em;
}

.agnostic-dropdown {
  width: 100% !important;
}

.select-agnostic {
  padding: 2px 4px;
  cursor: pointer;
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.select-agnostic:hover {
  background-color: #a100ff;
  color: #fff;
}

.agnostic-serachbox {
  width: 100% !important;
}

.agnostic-search-bar svg {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

.agnostic-search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(231, 234, 238);
  outline-style: none;
}

.select-agnostic-no-option {
  font-family: 'Graphik-Regular';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.35px;
  cursor: pointer;
}

.close-modal-btn {
  cursor: pointer;
}
