.rvd-client-story-acknowledgement-modal .project-detail-tabs button[aria-selected='false'] span {
    color: rgb(99, 115, 129);
  }
  
  .upload-container {
    text-align: center;
  }
  
  /* .file-history-container {
    min-height: 400px;
  } */
  
  .file-history-container .svg-container {
    float: none;
    width: 20px;
    height: 20px;
    font-size: 1em;
  }
  
  .file-history-column{
    padding-right: 25px;
  }
  
  .file-history-column.select-boi-modal-column.boi-name {
    padding-left: 5px;
  }
  
  .select-boi-header .file-history-column:first-child {
    padding-right: 0;
  } 
  
  .file-history-column .select-boi-body {
    height: 350px;
  }
  
  .file-history-column:first-child {
    width: 3%;
  }
  
  .file-history-column:nth-child(2) {
    width: 37%;
  }
  
  .file-history-column:nth-child(3) {
    width: 20%;
  }
  
  .file-history-column:nth-child(4) {
    width: 20%;
  }
  
  .file-history-column:nth-child(5) {
    width: 20%;
  }
  
  .file-history-column:last-child {
    padding-right: 0;
  }
  
  .file-name {
    /* padding-left: 15px; */
    overflow: hidden;
  }
  
  .file-name>.svg-container>svg {
    filter: invert(50%) sepia(1%) saturate(1655%) hue-rotate(176deg) brightness(111%) contrast(85%);
  }
  
  .file-download {
    /* filter: brightness(1) sepia(3) hue-rotate(205deg) saturate(42); */
    float: right;
    padding-right: 10px;
    cursor: pointer;
  }
  
  .download-history-btn>button>span {
    padding-top: 2px;
  }
  
  .download-history-btn>button>div>svg {
    margin-top: 4px;
  }
  
  .client-story-modal .client-story-ack-modal-container {
    margin: 0px;
  }
  
  .client-story-modal .client-story-ack-modal-container .react-tabs__tab-list {
    border-bottom: 1px solid rgb(231, 234, 238);
    padding: 10px 20px 0 20px;
  }
  
  .client-story-modal .client-story-ack-modal-container div[datatest-id='uploadFile'], .client-story-modal .client-story-ack-modal-container div[datatest-id='fileHistory'] {
    padding: 20px;
  }
  
  .file-history-container > .empty-program-container {
    padding-top: 65px;
  }
  
  .modal-dialog.file-history-modal {
    max-width: 85% !important;
  }
  
  .modal-dialog.file-history-modal .modal-header {
    padding: 15px 20px !important;
    border-bottom: 1px solid rgba(231,234,238, 1) !important;
  }
  
  .modal-dialog.file-history-modal .modal-header .header24-semibold-midnight{
    opacity: 1;
    color: rgba(99,115,129,1);
    font-family: "Graphik-Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.25px;
    text-align: left;
    line-height: 14px;
  }
  
  .modal-dialog.file-history-modal .select-boi-modal{
    padding: 0px 20px;
  }
  
  .file-history-column .download-history-btn {
    position: relative;
    margin-left: auto;
    left: 25px;
  }

  .file-history-container .select-boi-body-row:nth-child(even) {
    background: rgb(255, 255, 255);
  }
  
  .file-history-container .select-boi-body-row:nth-child(odd) {
    background: rgb(249, 250, 251);
  }
  
  .file-history-container .select-boi-body-row:first-child {
    border-radius: 5px 5px 0px 0px;
    border: 1px solid rgb(231, 234, 238);
  }
  
  .file-history-container .select-boi-body-row:last-child {
    border-radius: 0px 0px 5px 5px;
  }
  
  .file-history-container .select-boi-header{
    background: rgb(255, 255, 255);
    border-radius: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
  }
  
  .file-history-container .cvm-table-pagination-container{
    background: none;
    border: none;
    padding: 15px 0px;
  }
  
  .file-history-container .cvm-table-pagination-container .page-size {
    display: none;
  }