.upload-subtext {
  font-size: 14px;
  margin-top: 20px;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown) {
  border-top: 25px solid transparent !important;
  display: block;
  padding: 0px 20px;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  height: 135px;
  cursor: default;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.doc-upload-invalid-comment:not(:placeholder-shown) {
  outline: 1px solid #aa0025 !important;
}

.comments .help-text-right {
  margin: 0 !important;
}

.comments .floating-label-textbox {
  border: 1px solid rgb(181, 192, 202);
  margin-bottom: 10px;
  border-radius: 4px;
}

.customboi-customparameter .selectmenu-selected-value,
.customboi-customparameter .selectmenu-floating-label {
  font-size: 11px;
  width: 90% !important;
}

#selectmenu-floating-label.selectmenu-floating-label.body-regular-disabled.default-display {
  font-size: 16px;
  font-family: 'Graphik-Regular';
  letter-spacing: -0.7px;
  font-weight: 400;
}

.leading-practice-label {
  width: 185px;
}
.customboi-customparameter .searchable-dropdown-input::placeholder {
  font-size: 11px;
}

.customboi-customparameter
  div.selectmenu-options
  .searchable-dropdown-content
  .searchable-dropdown-input {
  width: 70% !important;
}

.duplicateErr {
  border-color: red !important;
  margin-bottom: 25px !important;
}

.custombtn-error-message {
  bottom: 5px;
  position: absolute;
  color: red;
  padding: 0px 15px;
  font-size: 12px;
  margin: 0;
}

.customuom-message {
  bottom: 5px;
  position: relative;
  /* color: rgb(181, 192, 202); */
  color: #c89a15;
  padding: 0px 15px;
  font-size: 12px;
  margin: 0;
  top: -10px;
}

.second-stage-edit-boi {
  width: 120px;
  margin-left: auto;
  padding-top: 10px;
}

#deleteboi-popover {
  width: 400px;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
}

#deleteboi-popover .arrow::before,
#deleteboi-popover .arrow::after {
  display: none;
}

#deleteboi-popover > .popover-body {
  color: #fff;
}
.tooltiptext-wrap {
  padding: 0 20px;
}
.tooltiptext-wrap table {
  width: 100%;
}
.tooltiptext-wrap tr {
  text-align: left;
}
.tooltiptext-wrap tr td:first-child {
  width: 130px;
}
#boi-tooltip-right table {
  width: 100%;
}
#boi-tooltip-right table tr th:first-child,
#boi-tooltip-right table tr td:first-child {
  padding-left: 0;
  padding-right: 20px !important;
}

span.boiAgnosticDimensionModal.tooltiptext {
  left: auto;
  margin-left: -6px;
  margin-top: 5px;
}

span.boiAgnosticSubdimensionModal.tooltiptext {
  left: auto;
  margin-left: -6px;
  margin-top: 5px;
}
span.boiAgnosticUOMModal.tooltiptext {
  left: auto;
  margin-left: -6px;
  margin-top: 5px;
}

input:hover + .tooltiptext,
div#groove-input:hover + .tooltiptext {
  display: table-caption;
}
#boi-tooltip-right table tr th:first-child,
#boi-tooltip-right table tr td:first-child {
  padding-left: 0;
  padding-right: 20px !important;
}
input#boidefinition-0 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80% !important;
}
.add-boi-field-container #groove-select-dropdown .default-display {
  width: 70% !important;
}
.disclaimer-message {
  height: 16px;
  color: rgb(170, 0, 37);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding-top: 12px;
  line-height: 16px;
  width: 90%;
  padding-bottom: 16px;
}

.multi-select-dropdown-with-count.sfa-approver-dd .requiredText {
  color: #637381;
}
.multi-select-dropdown-with-count.sfa-approver-dd .required-multi-select .css-1nszmjf::after {
  content: '*';
  color: #637381;
}

.comment-loader {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-loader .lds-ellipsis {
  position: relative;
  margin: 0;
}