.boiHistoryModal {
  max-width: 95vw !important;
  min-height: 300px !important;
}

.boiHistoryModal .back-modal-btn {
  cursor: pointer;
}

.boi-history {
  margin: 0;
}

.boi-history .title {
  width: 759px;
  height: 36px;
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.boi-history .close-btn {
  right: 50px;
  top: 30px;
  color: #637381;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}

.boi-history .table-view-wraps {
  /* display: table; */

  width: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.boi-history .table-view-wraps.tab-modified .dimension-table-row > div:nth-child(6) {
  align-self: center;
}

.boi-history .table-view-wraps .dimension-table-body {
  max-height: 400px;
  overflow: auto;
  border-bottom: 1px solid rgb(234, 234, 234);
}

.btn-boi-history-container {
  display: inline-block;
}

#groove-btn-id > button#btn-boi-history {
  cursor: pointer;
}

#groove-btn-id > button#btn-boi-history:active,
#groove-btn-id > button#btn-boi-history:focus,
#groove-btn-id > button#btn-boi-history:hover {
  background: transparent;
}

#groove-btn-id > button#btn-boi-history > * {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 0;
}
/* #btn-icon-history button[aria-hidden='true'] .svg-container small {
  transform: none;
} */

#btn-icon-history.svg-container > svg {
  transform: none !important;
}
