.config-complete-container {
  padding: 60px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  display: flex;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  flex-direction: column;
  align-items: center;
}

.config-complete-container>.row>div>div {
  display: flex;
  flex-direction: column;
}

.completed-icon {
  border: 3px solid #a100ff;
  border-radius: 50%;
  padding: 2px 7px;
}

.completed-status-details {
  width: 90%;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}

.completed-status-details .main-text {
  margin: 25px 0 10px 0;
}

.completed-status-details .left-column {
  display: flex;
  flex-direction: column;
}

.completed-label {
  display: flex;
  margin: 20px 0 0 0;
}

.completed-details {
  display: flex;
}

.completed-details>div {
  margin: 0 35px;
}

.completed-value {
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 24px;
  letter-spacing: -0.28px;
  margin: 0 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.completed-programs-container {
  width: 100%;
  display: flex;
  padding: 40px 0;
  justify-content: center;
}

.completed-program-tile {
  padding: 20px 25px;
  background: rgb(249, 250, 251);
  border-radius: 16px;
  border: 1px solid rgb(117, 0, 192);
  margin: 20px auto;
}

.completed-program-title {
  color: rgb(117, 0, 192);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
  margin: 0 0 10px 0;
}

.completed-program-label {
  color: rgb(117, 0, 192);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.dimension-table,
.dimension-table .card-header .btn-link {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.dimension-table .dimension-table-header {
  color: rgb(33, 43, 54);
  letter-spacing: 0px;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
  padding: 12px 24px;
  border-bottom: none;
}

.dimension-table-collapse {
  width: 100%;
}

.dimension-table-row,
.dimension-table .card-header .btn-link {
  width: 100%;
  display: flex;
  align-items: baseline;
  text-align: left;
  justify-content: space-between;
}

.dimension-table-row-approved {
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
  padding: 5px 0;
}

.dimension-table-row>div:first-child,
.dimension-table-row>div:nth-child(2) {
  width: 15%;
}

.dimension-table-row>div:nth-child(3) {
  width: 20%;
  position: relative;
  margin-left: 10px;
}

.dimension-table-row>div:nth-child(4) {
  width: 14%;
}

.dimension-table-row>div:nth-child(5) {
  width: 9%;
}

.dimension-table-row>div:nth-child(6) {
  width: 78px;
  display: flex;
  align-self: flex-start;
}

.dimension-table-row.dimension-table-row-unedit> {
  width: 7%;
  margin-right: 10px;
}

.dimension-table-row.dimension-table-row-unedit>div:nth-child(7).row-visibility {
  width: 8%;
  margin-right: 10px;
}

.dimension-table-row>div:nth-child(7) {
  width: 7%;
  margin-right: 10px;
}

.dimension-table-row>div.toggle-swtch {
  width: 78px;
}

.dimension-table-row-awapp>div:first-child,
.dimension-table-row-awapp>div:nth-child(7),
.dimension-table-row-rejected>div:first-child,
.dimension-table-row-rejected>div:nth-child(7) {
  width: 11%;
}

.dimension-table-row-approved>div:first-child,
.dimension-table-row-approved>div:nth-child(7) {
  width: 11%;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>div:nth-child(7) {
  position: relative;
  left: 4px;
}

.dimension-table-row-rejected>div:nth-child(10),
.dimension-table-row-awapp>div:nth-child(10) {
  width: 24px;
}

.dimension-table-row-approved>div:nth-child(10) {
  width: 4%;
  transform: translate(-20px, -4px);
}

.dimension-table-row-approved>div:nth-child(11) {
  width: 4%;
}

.dimension-table-row-awapp>div:nth-child(7) {
  align-self: self-start;
}

.dimension-table-row-awapp>div:nth-child(2) {
  width: 14%;
  margin-right: 15px;
}

.dimension-table-row-approved>div:nth-child(2) {
  width: 15%;
  margin-right: 15px;
}

.dimension-table-row-awapp>div:nth-child(3) {
  width: 18%;
  position: relative;
  margin-left: 10px;
}

.dimension-table-row-approved>div:nth-child(3) {
  width: 16%;
  position: relative;
  /* margin-left: 10px; */
}

.dimension-table-row-awapp>div:nth-child(4),
.dimension-table-row-awapp>div:nth-child(5),
.dimension-table-row-rejected>div:nth-child(4) {
  width: 16%;
}

.dimension-table-row-approved>div:nth-child(5) {
  width: 16%;
}

.dimension-table-row-approved>div:nth-child(4) {
  width: 14%;
}

.dimension-table-row-rejected>div:nth-child(5) {
  width: 9%;
}

.dimension-table-header>.dimension-table-row-awapp>div:nth-child(8) {
  margin-left: 0;
}

.dimension-table-row-awapp>div:nth-child(6) {
  width: 8%;
  align-self: auto;
}

.dimension-table-row-approved>div:nth-child(6) {
  width: 8%;
}

div.uom-string {
  position: relative;
  left: 6px;
}

.dimension-table-row-awapp>div:nth-child(6) {
  margin-left: 12px;
}

.dimension-table-row-rejected>div:nth-child(6) {
  width: 13%;
  align-self: self-start;
}

.dimension-table-row-awapp>div:nth-child(8) {
  width: 13%;
  text-align-last: left;
  margin-left: 12px;
}

.dimension-table-row-rejected>div:nth-child(8) {
  width: 14%;
  text-align-last: left;
}

.dimension-table-row-approved>div:nth-child(8) {
  width: 12%;
  /* text-align-last: left; */
  margin-right: 0px;
}

.dimension-table-row-awapp>div:nth-child(9) {
  width: 8%;
  transform: translate(-10px, 5px);
}

.dimension-table-row-awapp>div:nth-child(9) div.svg-container.large {
  position: relative;
  left: 33px;
}

.dimension-table-row-approved>div:nth-child(9) {
  width: 14%;
  transform: translate(-5px, -1px);
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>div:nth-child(9) {
  left: 8px;
  position: relative;
  top: 4px;
}

.dimension-table-row-rejected>div:nth-child(9) {
  width: 8%;
  transform: translate(-10px, 5px);
}

.dimension-table-row-rejected,
.dimension-table-row-awapp {
  padding: 10px 0;
}

.dimension-table-row-rejected {
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 10px;
}

.dimension-table-collapse .card {
  border-radius: 0px;
}

.dimension-table .card-header,
.dimension-table .card-header .btn-link {
  padding: 8px 12px;
}

.dimension-table .card-header {
  padding: 8px 24px;
  border: none;
  background: #fff;
}

.dimension-table .dimension-table-header .dimension-table-row,
.dimension-table-body .card-body .dimension-table-row {
  width: 95%;
}

.dimension-table .dimension-table-header .history-header,
.dimension-table .dimension-table-header .dimension-table-row-approved.dim-label,
.dimension-table .dimension-table-header .dimension-table-row-rejected,
.dimension-table .dimension-table-header .dimension-table-row-unedit,
.dimension-table .dimension-table-header .dim-title,
.dimension-table .dimension-table-header .dim-header {
  width: 95%;
}

.dimension-table .dimension-table-header .dimension-table-row-awapp {
  width: 94%;
}

.dimension-table .dimension-table-header .dim-awapp,
.dimension-table .dimension-table-header .dim-rejected,
.dimension-table .dimension-table-header .dim-label {
  width: 94%;
}

.dimension-table-row.dimension-table-row-rejected.dim-rejected>div[name="status"] {
  padding-left: 15px;
}

.row.boi-column {
  margin-left: 10px;
}

.dimension-table-row-unedit>div:first-child,
.dimension-table-row-unedit>div:nth-child(2) {
  width: 106px;
}

.dimension-table-row-unedit>div:nth-child(6) {
  width: 12%;
  align-items: center;
}

.dimension-table-row-unedit>div:nth-child(8) {
  width: 9%;
  transform: translate(0px, 8px);
  position: relative;
  top: -8px;
}

.dimension-table-collapse .collapse~.dimension-table-row-unedit>div:nth-child(8) {
  display: none;
}

.dimension-table-row-unedit>div:nth-child(9) {
  width: 30px;
}

.dimension-table .card-body {
  padding: 16px 24px;
}

/* .dimension-table-row .info-icon {
  height: 10px;
  width: 10px;
  border: 1.2px solid #a100ff;
  border-radius: 55%;
  position: absolute;
  bottom: 50%;
  right: inherit;
  top: inherit;
  margin-left: 5px;
} */
.dimension-table-row input[type='text'],
.dimension-table-row-approved input[type='text'] {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 100%;
}

.dimension-table-collapse .subdimension-rows {
  border-top: 1px solid rgb(223, 227, 232);
}

.dimension-table-collapse .card-body {
  padding: 0;
}

.dimension-table-collapse .card-body .dimension-table-row,
.dimension-table-collapse .card-body .dimension-table-row-approved {
  padding: 16px 0;
  /* width: 95%; */
}

.dimension-table-collapse .card-body .dimension-table-row {
  padding-left: 36px;
}

.dimension-table-collapse .collapse.show~div.card-header .btn-link>div.svg-container {
  transform: rotate(90deg);
}

.dimension-table-collapse .collapse~div.card-header .dimension-table-row>div,
.dimension-table-collapse .collapse~div.card-header .dimension-table-row-approved>div {
  display: none;
}

.dimension-table-collapse .collapse~div.card-header .dimension-table-row>div:first-child,
.dimension-table-collapse .collapse~div.card-header .dimension-table-row-approved>div:first-child {
  display: block;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child {
  pointer-events: none;
  width: 100%;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>#groove-select-dropdown div#display-selected,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>#groove-select-dropdown div#display-selected {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  max-width: 100%;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group>div#selectmenu-anchor,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div.form-group>div#selectmenu-anchor,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>div.form-group>div#selectmenu-anchor {
  border: none;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div>div.form-group#groove-select-dropdown>div#selectmenu-anchor.form-control[disabled],
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group#groove-select-dropdown>div#selectmenu-anchor.form-control[disabled],
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>div.form-group#groove-select-dropdown>div#selectmenu-anchor.form-control[disabled] {
  border: none !important;
  background: none;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div.form-group {
  margin-bottom: unset;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group>div#selectmenu-anchor>div#selectmenu-dropdown-icon,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div.form-group>div#selectmenu-anchor>div#selectmenu-dropdown-icon,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>div.form-group>div#selectmenu-anchor>div#selectmenu-dropdown-icon {
  display: none;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row>div,
.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>div {
  display: block;
}

.boi-column {
  display: flex !important;
  align-items: baseline;
}

.dimension-table-collapse .collapse~div.card-header .dimension-table-row>.boi-column,
.dimension-table-collapse .collapse~div.card-header .dimension-table-row-approved>.boi-column {
  display: none !important;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row>.boi-column {
  display: flex !important;
  margin-left: 10px;
  margin-right: 12px;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>.boi-column {
  display: flex !important;
  margin-left: 5px;
  margin-right: 16px;
}

.text-field {
  width: 265px;
  height: 78px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(181, 192, 202);
  border-radius: 4px;
}

.comment-input {
  height: 90px;
  resize: none;
  outline: none;
  background: rgb(239, 242, 245);
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}

textarea {
  width: 100%;
  height: 100%;
  background: white;
}

.top-left-container-icon {
  width: 17.1px;
  height: 17.55px;
  margin: -4px 5px 0;
  cursor: pointer;
}

.top-left-container-text {
  width: 86px;
  height: 19px;
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 10px;
  cursor: pointer;
}

.dimension-header-container {
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.dimension-header-container .svg-inline--fa.fa-w-18 {
  transform: inherit;
}

button.dim-acc-btn.btn.btn-link {
  width: 30px;
}

.accordion>.card>.dim-card-header {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

textarea.comment-input:read-only {
  cursor: not-allowed;
}

.program-column {
  max-width: 90%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0px !important;
  text-align: left;
}

.program-table,
.program-table .card-header .btn-link {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.program-table-header {
  color: #ffff;
  letter-spacing: 0;
  background: #919eab;
  border-radius: 5px 5px 0 0;
  padding: 12px 24px;
  border: 1px solid #e7eaee;
  border-bottom: none;
  text-align: center;
}

.program-table-row {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #dfe3e8;
}

.program-table .program-table-header .program-table-row,
.program-table-body .card-body {
  width: 95%;
}

.accordion>.card>.prog-card-header {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prog-card-header {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.program-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.project-table-header {
  color: #15181b;
  letter-spacing: 0;
  background: rgba(0, 186, 255, 0.06);
  padding: 12px 24px;
  border: 1px solid #e7eaee;
  border-bottom: none;
  text-align: center;
  margin: 0px 24px;
}

.collapse.show~div.card-header>.program-header-container>.btn-link>div.svg-container {
  transform: rotate(90deg);
}

.project-table-header~.dimension-table-body .program-column:nth-child(5) {
  width: 27px !important;
}

.project-header-container>.dimension-table-row {
  width: 100% !important;
  align-items: center;
  padding: 2px;
}

.project-table-header~.dimension-table-body {
  margin: 0 24px;
  padding: 0 24px;
}

.project-table-header>.dimension-table-row {
  width: 100% !important;
}

.program-table-header~.dimension-table-body>.accordion>.card {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-radius: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.dim-del-icon {
  -ms-transform: rotate(90deg);
  transform: rotate(180deg);
}

/*======================================== REJECTED BOI CSS ===============================================*/

.collapse.show~div.card-header div.svg-container>.quick-delete {
  transform: rotate(180deg);
}

div.svg-container>.quick-delete {
  vertical-align: middle;
  cursor: pointer;
  color: rgb(161, 0, 255);
}

.boi-status {
  word-break: keep-all;
  width: 80px;
  cursor: default;
}

.boi-status.no-status {
  background: transparent;
}

.boi-status.status-break {
  word-break: break-all;
}

.reject-status-table .dimension-table-row>div {
  word-break: break-word;
}

.disabled-delete>div.svg-container>.quick-delete {
  color: #bcbcbc;
  cursor: not-allowed;
}

.disabled-uom-accordion {
  position: relative;
}

span.quickDelete.tooltiptext {
  left: auto;
  right: 0;
  margin-top: 5px;
}

input:hover+.tooltiptext,
div#groove-select-dropdown:hover+.tooltiptext {
  display: table-caption;
}

span.boiUom.tooltiptext {
  right: auto;
  left: auto;
  margin-top: 5px;
}

span.boiUomUsedInProj.tooltiptext {
  right: -140px;
  left: auto;
  margin-top: 5px;
}

span.boiAgnosticdimension.tooltiptext {
  left: 20px;
  margin-top: 5px;
}

.wheel-visibility-button input:checked+.mini-toggle-slider {
  background-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button input:checked+.mini-toggle-slider:before {
  border-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button input:checked+.mini-toggle-slider:after {
  border-color: transparent !important;
}

.wheel-visibility-button span.mini-toggle-slider[disabled] {
  cursor: not-allowed !important;
}

.wheel-visibility-button .mini-toggle-switch {
  margin-top: 9px;
}

.info-icon-wrap {
  display: inline-block;
}

.row.boi-column>div:first-child>div:not(.info-icon-wrap) {
  margin-left: 10px;
}

.row.boi-column>div:first-child>.info-icon-wrap+div {
  margin-left: 0;
}

.dimension-new-info img.new-info-icon {
  transform: scale(1);
  margin-top: 0;
}

.new-info-icon {
  margin-top: -11px !important;
  margin-bottom: -17px !important;
  margin-left: -9px !important;
}

.disabled-switch .mini-toggle-slider {
  cursor: not-allowed !important;
}

#boi-tooltip-right {
  opacity: 1;
}

#groove-toggle .mini-toggle-slider {
  height: 21px !important;
  width: 40px !important;
}

#groove-toggle .mini-toggle-slider:before {
  height: 19px !important;
  width: 20px !important;
}

#groove-toggle input:checked+.mini-toggle-slider:before {
  height: 19px !important;
}

.wheel-visibility-button-approve input:checked+.mini-toggle-slider {
  background-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button-approve input:checked+.mini-toggle-slider:before {
  border-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button-approve input:checked+.mini-toggle-slider:after {
  border-color: transparent !important;
}

.wheel-visibility-button-approve span.mini-toggle-slider[disabled] {
  cursor: not-allowed !important;
}

td {
  vertical-align: top;
}

.dim-delete {
  margin-top: 7px;
}

/* Edit Stage 2 Dimension Accordion CSS */

.dimension-table-row-awapp #groove-select-dropdown,
.dimension-table-row-approved #groove-select-dropdown,
.dimension-table-row-rejected #groove-select-dropdown {
  height: 35px;
  margin-bottom: 0px;
  contain: size;
}

.dimension-table-row-awapp #groove-select-dropdown .default-display,
.dimension-table-row-approved #groove-select-dropdown .default-display,
.dimension-table-row-rejected #groove-select-dropdown .default-display {
  width: 65%;
  font-size: 10px;
  margin-top: -15px;
}

.dimension-table-row-awapp #groove-select-dropdown div#selectmenu-anchor,
.dimension-table-row-awapp #groove-select-dropdown div#selectmenu-anchor[disabled],
.dimension-table-row-approved #groove-select-dropdown div#selectmenu-anchor,
.dimension-table-row-approved #groove-select-dropdown div#selectmenu-anchor[disabled],
.dimension-table-row-rejected #groove-select-dropdown div#selectmenu-anchor,
.dimension-table-row-rejected #groove-select-dropdown div#selectmenu-anchor[disabled] {
  font-size: 10px;
  border-radius: 20px;
  height: 35px;
  width: 100%;
  padding: 5px;
}

.dimension-table-row-awapp #groove-select-dropdown #selectmenu-dropdown-icon,
.dimension-table-row-approved #groove-select-dropdown #selectmenu-dropdown-icon,
.dimension-table-row-rejected #groove-select-dropdown #selectmenu-dropdown-icon {
  position: relative;
  margin-top: 5px;
  margin-right: 0px;
  float: right;
}

.dimension-table-row-awapp #groove-select-dropdown .selectmenu-group-values,
.dimension-table-row-approved #groove-select-dropdown .selectmenu-group-values,
.dimension-table-row-rejected #groove-select-dropdown .selectmenu-group-values {
  width: 65%;
}

.dimension-table-row-awapp #groove-select-dropdown .selected-no-placeholder,
.dimension-table-row-approved #groove-select-dropdown .selected-no-placeholder,
.dimension-table-row-rejected #groove-select-dropdown .selected-no-placeholder {
  margin-top: 5px;
}

.dimension-table-row-awapp #groove-select-dropdown div#display-selected,
.dimension-table-row-approved #groove-select-dropdown div#display-selected,
.dimension-table-row-rejected #groove-select-dropdown div#display-selected {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  /* max-width: 41px; */
}

.collapse.show~div.card-header .dimension-table-row-awapp .AA-accordion-dropdown div.svg-container,
.collapse.show~div.card-header .dimension-table-row-approved .AA-accordion-dropdown div.svg-container,
.collapse.show~div.card-header .dimension-table-row-rejected .rejected-accordion-dropdown div.svg-container {
  transform: rotate(0deg) !important;
}

.dimension-table-row-awapp #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item,
.dimension-table-row-approved #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item,
.dimension-table-row-rejected #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item {
  display: inline-flex;
  white-space: normal;
  padding: 5px;
  font-size: 12px;
}

.dimension-table-row-awapp #groove-select-dropdown div.selectmenu-options.display-list,
.dimension-table-row-approved #groove-select-dropdown div.selectmenu-options.display-list,
.dimension-table-row-rejected #groove-select-dropdown div.selectmenu-options.display-list {
  width: 185px;
}

.dimension-table-row-awapp #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input,
.dimension-table-row-approved #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input,
.dimension-table-row-rejected #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  left: 30px;
  width: 70%;
}

.dim-history #groove-btn-id .btn-utility-stat-alternate>.svg-container>svg {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dimension-table-row-duplicate-boi {
  border-color: red !important;
  padding: 10px 0;
  border: 1px solid;
  border-radius: 8px;
}

.dimension-table-row .row-visibility,
.dimension-table-program .row-visibility {
  white-space: normal;
}

.dim-textarea {
  margin-right: 16px !important;
}

@media only screen and (max-width: 1300px) {

  .dimension-table-row-approved #groove-select-dropdown div#display-selected,
  .dimension-table-row-awapp #groove-select-dropdown div#display-selected,
  .dimension-table-row-rejected #groove-select-dropdown div#display-selected {
    max-width: unset;
  }
}

.dimension-table-row-rejected>div:nth-child(3) {
  margin-left: 0px !important;
}

#groove-select-dropdown .selectmenu-group-values {
  width: 70% !important;
}

input#offering[disabled],
input#dtTargetEndDate[disabled],
input#projectName[disabled],
#groove-textarea .groove-textbox:disabled {
  color: #919eab;
}

.row.boi-column div {
  display: unset !important;
}

.empty-div {
  width: 24px;
  margin-left: 12px;
}

.accordion-child-div-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}

.dimension-table-row-duplicate-boi {
  align-items: flex-start !important;
}

.dimension-table-row-duplicate-boi>div:nth-child(8) {
  padding-top: 4px;
}

.dimension-table-row-duplicate-boi>div:nth-child(10) {
  padding-top: 8px;
}

.db-visib-tooltip {
  padding-bottom: 10px;
}

.toggle-swtch {
  /* display: flex !important; */
}

#groove-toggle .mini-toggle-slider[disabled]:hover {
  background-color: #c4cdd5;
}

.reject-msg-on-toggle {
  text-align: center;
}

.reject-msg-on-toggle .svg-inline--fa.reject-BOI-msg {
  color: white;
  background-color: rgb(170, 0, 37);
  transform: none;
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.reject-msg-on-toggle .svg-container.large {
  height: 87.75px;
  width: 87.75px;
}

.reject-msg-on-toggle p {
  text-align: center;
}

.reject-msg-on-toggle p.header {
  color: rgb(21, 24, 27);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  height: 18px;
  letter-spacing: -0.28px;
}

.reject-msg-on-toggle p.body-msg {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 48px;
  line-height: 24px;
}

.reject-msg-btn-container {
  display: inline-flex;
  margin: 0 auto;
}

.reject-msg-btn-container button {
  width: 94px;
  height: 40px;
  margin: 0 25px;
}

.reject-msg-btn-container #groove-btn-id>button>* {
  margin: 3px auto;
}

.disabler {
  pointer-events: none;
}

.svg-inline--fa.fa-exclamation-triangle.reject-alert {
  transform: rotate(0deg);
  color: rgb(170, 0, 37);
  height: 14.4px;
  width: 16px;
}

.svg-inline--fa.fa-exclamation-triangle.reject-alert.first-boi {
  transform: rotate(0deg);
  color: rgb(170, 0, 37);
  height: 14.4px;
  width: 16px;
}

.tooltip-dbVisibUpdate>span {
  font-family: Graphik-Medium !important;
  font-size: 11px !important;
}

.tooltip-dbVisibUpdate {
  opacity: 1 !important;
  padding: 11px !important;
  text-align: left !important;
}

.collapse.show~div.card-header .deleted-bois>div.svg-container {
  -webkit-transform: unset;
  transform: unset;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row>div.toggle-swtch {
  display:flex;
}

.add-program-details .floating-label.input-box input#programName:disabled{
  color: #919eab;
}

.categorization-table-collapse > .card {
  border-radius: 5px !important;
  border: 1px solid rgba(231,234,238, 1);
  background-color: rgba(255,255,255, 1);
  margin-bottom: 15px;
  padding: 0px 15px !important;
}

.categorization-table-collapse > .card > .card-header {
  background: none;
  border: none;
  padding: 5px 0px 0px !important;
}

.categorization-table-collapse .card-header > .dimension-header-title {
  color: rgba(33,43,54,1);
  font-family: "Graphik-Medium";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
}

.categorization-table-collapse .dimension-table-program {
  padding-bottom: 15px !important;
}

.categorization-table-collapse  > .card > .card-header > .btn:focus {
  outline: none;
  box-shadow: none;
}