.dIL-actionrow {
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  margin-bottom: 36px;
}

.dIL-dropdowns {
  display: flex;
}

.view-types {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
}

.view-types img {
  margin-left: 1rem;
  cursor: pointer;
}

.view-unselected {
  filter: invert(50%) sepia(3%) saturate(1647%) hue-rotate(169deg) brightness(120%) contrast(86%);
}

.view-selected {
  filter: brightness(1) sepia(3) hue-rotate(205deg) saturate(42) !important;
}

.statusDropdown {
  margin-right: 24px;
}

.statusDropdown .multi-select-dropdown,
.reportingYrDropdown .multi-select-dropdown,
.statusDropdown .multi-select-label,
.reportingYrDropdown .multi-select-label {
  width: inherit;
}

.statusDropdown .css-14s1juc-control,
.statusDropdown .css-1csqycf-control {
  width: 160px;
}

.statusDropdown .css-144vaf5-menu {
  width: 170px;
  position: absolute;
  right: 0;
}

.statusDropdown .css-dvua67-singleValue,
.reportingYrDropdown .css-dvua67-singleValue {
  color: rgb(161, 0, 255);
}

.statusDropdown .multi-select-dropdown .css-oq3rz7-multiValue {
  width: auto;
}

.dIL-dropdowns .reportingYrDropdown .css-14s1juc-control,
.dIL-dropdowns .reportingYrDropdown .css-1csqycf-control {
  width: 100px;
}

.dIL-dropdowns .statusDropdown .multi-select-dropdown [class*='control'] {
  width: 380px;
}

.rvd-sort-wrapper {
  position: relative;
  top: 20%;
  margin: 0 5px;
}
.rvd-view-select.landing-img-box {
  margin: 0 0 0 1.5rem !important;
}

.reportingYrDropdown .css-144vaf5-menu {
  width: 190px;
  position: absolute;
  right: 0;
}

.card-view-container .accordion .card-header {
  background: rgb(255, 255, 255);
  border-bottom: none;
  border-radius: 8px !important;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
  padding: 23px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 72px;
}

.card-view-container .accordion .card {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px !important;
  margin-bottom: 24px;
}

.card-view-container .accordion .card-body {
  padding: 0px 30px 10px 30px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #c4cdd5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.dot-awaitingApproval {
  background: #ffc82c;
}

.dot-approved {
  background: #7ec344;
}

.dot-rejected {
  background: #d5002f;
}


.status-list ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.status-list ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  margin-left: 24px;
}

.accordion-toggle:after {
  font-family: 'FontAwesome';
  content: '\f078';
  float: right;
}

.accordion-opened .accordion-toggle:after {
  content: '\f054';
}

.data-IngestionContainer .client-details-row {
  margin: 20px 0px;
}

.data-IngestionContainer .client-details-row:first-child {
  margin-top: 0px;
}

.data-IngestionContainer .accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid rgb(223, 227, 232);
}

.data-IngestionContainer .css-1nszmjf {
  color: #a100ff!important;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

/* .data-IngestionContainer .collapse.show ~ div.card-header {  
  border-bottom: 1px solid rgb(231, 234, 238);
} */

.card-outcome {
  height: 120px;
  /* width: 200px; */
  display: block;
  width: auto;
  /* vertical-align: top; */
  position: relative;
  margin: 10px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.card-indicate {
  position: relative;
  padding: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-indicate::before {
  position: absolute;
  content: '';
  background: rgb(181, 192, 202);
  width: 4px;
  height: 100%;
  border-radius: 8px 0px 0px 8px;
  left: 0;
  top: 0;
}

.card-awaitingApproval.card-indicate::before {
  background: #ffc82c;
}

.card-approved.card-indicate::before {
  background: #7ec344;
}

.card-rejected.card-indicate::before {
  background: #d5002f;
}

.card-items {
  text-align: center;
  width: 100%;
}

.card-indicate img {
  width: 20px;
  height: 28px;
  margin-bottom: 16px;
}

.card-indicate p {
  line-height: 12px;
  color: rgb(181, 192, 202);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.19px;
  margin-bottom: 0;
  overflow: hidden;
  width: 100%;
  max-height: 49px;
  line-height: 13px;
}

.table-view-wrap {
  height: 500px;
}

.collapse.show ~ div.card-header div.svg-container {
  transform: rotate(180deg);
}

.table-view-wrap .table-action-buttons > img {
  width: 16px;
  margin: 0 30px 0 0;
  cursor: pointer;
}

.table-view-wrap .cell-status {
  font-family: 'Graphik-Medium-Web';
}

.card-custom-view {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.rdv-label {
  font-size: 14px !important;
}

.system-generated-container .rdv-label {
  white-space: nowrap;
}

.rvd-card {
  align-items: center;
  margin-bottom: 0.75rem;
}

.rvd-card > img:first-child {
  width: 20px;
  /* height: 28px; */
  margin-right: 10px;
}

.rvd-card-detail {
  margin-bottom: 10px !important;
}

.statusDropdown .css-15hpnlr-control {
  background: rgb(252, 251, 251);
  border-radius: 20px;
  border: 1.5px solid #a100ff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #a100ff;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  min-height: 0px;
  width: 253px;
}

.statusDropdown .css-15hpnlr-control:hover {
  border: 1.5px solid #a100ff;
}

.statusDropdown .css-2dd3o1-control {
  background: rgb(252, 251, 251);
  border-radius: 20px;
  border: 1.5px solid #a100ff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #a100ff;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  min-height: 0px;
  width: 253px;
}

.statusDropdown .css-2dd3o1-control:hover {
  border: 1.5px solid #a100ff;
}

/* r10 changes */
.rvd-program-card-box .program-card-detail > div:first-child {
  width: fit-content;
}

.rvd-nav-dropdowns{
  display: flex;
}

.rvd-nav-dropdowns > .rvd-contract-dd-cont,
.rvd-nav-dropdowns > .rvd-program-dd-cont {
  padding-left: 0;
}

.rvd-contract-dd-cont {
  /* width: 17.66667% !important; */
  flex: 0 0 17.66667% !important;
}

.rvd-card-detail .title-ellip {
  margin-right: 9%;
  padding-top: 10px;
}

.rvd-card-detail > .system-generated-container{
  /* display: flex;
  align-items: center;
  flex: 0 0 10em; */
  width: -webkit-fill-available;
}

.rvd-card-detail .system-generated-container .title-ellip {
  margin-bottom: 0px !important;
}

.rvd-program-card-box .prog-stage{
  padding-top: 7px;
}