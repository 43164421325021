/* Pagination */
.cvm-table-pagination-container {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid rgb(231, 234, 238);
  padding: 5px;
  display: flex;
}

.pagination {
  margin: 0 !important;
}

li#prevPage > button {
  margin-right: 15px;
}

li#nextPage > button {
  margin-left: 15px;
}

.pagination>li>button {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  min-width: 30px;
  color: #7b8e98;
  outline: none;
  margin: 0 5px;
  line-height: 1;
}

.pagination > li > button:hover {
  border: 1px solid #a100ff;
}

button.pagination-btn-disable {
  color: rgb(220, 226, 231) !important;
  cursor: not-allowed;
  border: none !important;
}

button.pagination-btn-pages {
  background: none !important;
  border: none !important;
  min-width: 24px !important;
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
  margin: 0 !important;
}

button.pagination-btn-pages:hover {
  border: 1px solid #a100ff !important;
  margin-left: -2px !important;
}

button.pagination-btn-active.pagination-btn-pages {
  background: rgb(161, 0, 255) !important;
  border-radius: 3px !important;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
}

button.pagination-btn-active.pagination-btn-pages:hover {
  margin: 0;
}

/* Page Size dropdown */
.page-size {
  display: -ms-flexbox;
  display: flex;
  margin: 0 10px;
  align-items: center;
}

.page-size > label {
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 0 10px;
}

.page-size {
  display: flex;
}

.page-size svg {
  width: 12px;
  fill: #7b8e98;
}

/* width */
.page-size ::-webkit-scrollbar {
  width: 7px !important;
  height: 1px !important;
}

/* Track */
.page-size ::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
.page-size ::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

/* Handle on hover */
.page-size ::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
}