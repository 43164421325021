  .upload-support-document {
    max-width: 750px !important;
  }
  
  .upload-support-document .modal-content {
    box-shadow: 0 0 12px rgb(60 60 60);
    cursor: default;
    border: unset;
    overflow: hidden;
  }
  
  .upload-support-document .content-wrapper {
    padding: 20px !important;
  }
  
  .upload-support-document .md-stepper-horizontal {
    margin: unset;
    box-shadow: unset;
    border: unset;
    display: flex;
    padding: unset;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #e7eaee;
    cursor: pointer;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step:active {
    border-radius: unset;
  }
  
  .upload-support-document .md-stepper-horizontal .active {
    border-bottom: 3px solid rgb(117, 0, 192);
  }
  
  .upload-support-document .modal-tab-content {
    padding: 20px;
    min-height: 250px;
  }
  
  .upload-support-document .modal-buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }
  
  .upload-support-document .modal-buttons button {
    width: 80px;
    margin-left: 10px;
    justify-content: center;
  }
  
  #groove-radio-button.upload-support-radio .form-check {
    margin: 15px 0;
    display: flex;
    align-items: center;
  }
  
  #groove-radio-button.upload-support-radio .form-check > label {
    margin-left: 10px !important;
  }
  
  #groove-radio-button.upload-support-radio input.form-check-input {
    margin-top: 0;
    height: 20px;
    width: 20px;
    content: '';
    display: inline-block;
    visibility: visible;
    -moz-appearance: initial;
  }
  
  #groove-radio-button.upload-support-radio input.form-check-input[type='radio']:after {
    content: '';
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1.5px solid #6c757d;
  }
  
  #groove-radio-button.upload-support-radio input.form-check-input[type='radio']:hover:after,
  #groove-radio-button.upload-support-radio
    input.form-check-input[type='radio']:checked:after {
    height: 20px;
    width: 20px;
    border: 1.5px solid rgb(161, 0, 255) !important;
  }
  
  #groove-radio-button.upload-support-radio
    input.form-check-input[type='radio']:checked::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid white;
    background: rgb(161, 0, 255);
  }
  
  #groove-radio-button.upload-support-radio
    input.form-check-input[type='radio']:checked:after {
    height: 20px;
    width: 20px;
    border: 1.5px solid rgb(161, 0, 255) !important;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step.inactive .md-step-img-circle,
  .upload-support-document .md-stepper-horizontal .md-step.disabled .md-step-img-circle {
    background: rgb(161, 0, 255);
    opacity: .2;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step.inactive .md-step-img-circle {
    cursor: pointer;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step .md-step-title {
    font-size: 12px;
    font-family: 'Graphik-Regular';
    color: rgb(70, 0, 115);
  }

  .upload-support-document .md-stepper-horizontal .md-step.active .md-step-title {
    font-size: 16px;
    font-family: 'Graphik-Medium';
  }
  
  .upload-support-document .cvm-dropzone {
    padding: 20px;
    margin: unset;
  }
  
  .upload-support-document .uploaded-files {
    width: 80%;
    padding: 0;
  }
  
  .upload-support-document .uploaded-files-container {
    margin: 10px 0 0 0;
  }
  
  
  .upload-support-document .view-button-wrapper {
    display: flex;
    justify-content: center;
    height: 14px;
  }
  
  .upload-support-document .view-button {
    cursor: pointer;
  }
  
  .upload-support-document .view-button-wrapper,
  .upload-support-document .view-last-wrapper {
    color: #919eab;
    font-family: Graphik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 14px;
    margin-top: 10px;
  }
  
  .upload-support-document .view-last-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  
  .upload-support-document .view-button svg,
  .upload-support-document .view-last-wrapper > div:first-child span {
    margin-left: 5px;
    word-break: break-all;
  }

  .upload-support-document .view-last-wrapper > div:first-child {
    width: 70%;
  }

  .upload-support-document .view-last-wrapper > div:nth-child(2) {
    width: 150px;
    text-align: center;
  }
  
  .upload-support-tab-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    -webkit-mask: url(../../../Static/images/svg/UploadSupportDocs.svg) no-repeat center;
    mask: url(../../../Static/images/svg/UploadSupportDocs.svg) no-repeat center;
  }
  
  .doc-type-tab-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    -webkit-mask: url(../../../Static/images/svg/DocType.svg) no-repeat center;
    mask: url(../../../Static/images/svg/DocType.svg) no-repeat center;
  }
  
  .view-last-icon {
    color: rgb(156, 106, 222);
  }
  
  .view-last-download {
    cursor: pointer;
    color: rgb(70, 0, 115);
  }
  
  .upload-support-document .modal-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffffcc;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-support-document .link-wrapper {
    padding: 20px 30px;
    background: rgb(251, 249, 254);
    border-radius: 16px;
    border: 2px dashed rgb(145, 158, 171);
    margin-bottom: 20px;
  }

  .upload-support-document .link-wrapper > div {
    display: flex;
    align-items: center;
  }

  .upload-support-document .link-wrapper input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 12px;
    margin-left: 10px;
  }

  .upload-support-document .link-wrapper input,
  .upload-support-document .link-wrapper input::-webkit-input-placeholder {
    font-family: Graphik-Regular;
    font-size: 14px;
    font-weight: normal;
    height: 14px;
    letter-spacing: -0.25px;
    line-height: 14px;
  }