.data-visualisation-container .multi-select-dropdown {
  padding: 0 5px 0 16px;
}

.data-visualisation-container .title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -15px;
}
.data-visualisation-container .title-section .value-meter-title {
  width: 100%;
}

.client-stage-details {
  display: flex;
  align-items: flex-start;
  margin-top: -10px;
}

.client-stage-details > span {
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  color: rgb(145, 158, 171);
  letter-spacing: -0.25px;
  line-height: 16px;
}

div#client-stage-defination {
  z-index: 9999;
}

.dashboard-client-details .client-details-button {
  margin-bottom: 0;
}

#client-stage-defination .tooltip-inner {
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.dIL-dropdowns.db-not-project-details {
  margin-bottom: 10px;
}
.dIL-dropdowns.db-project-details {
  margin-bottom: 40px;
}
.client-stage-label {
  color: #7500c0 !important;
}

.title-section.bottom-margin {
  margin-bottom: 0px;
}
.dashboard-client-details.margin-left {
  margin-left: 40px;
}

div#client-stage-defination {
  margin-top: 3px;
}

.bodyText24-Medium {
  color: rgb(45, 58, 75);
  font-size: 24px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.7px;
}

.bodyText24-Semibold {
  color: rgb(45, 58, 75);
  font-size: 18px;
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.data-visualisation-container .default-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-view-container .default-view {
  background: rgb(255, 255, 255);
  border: 1px dashed rgb(161, 0, 255);
  border-radius: 16px;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  width: 45rem;
  height: 14rem;
  margin-top: 5rem;
}

.default-view-container .default-view .btn {
  padding: 0.5rem 1.75rem;
  margin-top: 33px;
}

.default-view-container #groove-btn-id .btn-stat-alternate.disabled,
.default-view-container #groove-btn-id .btn-stat-alternate:disabled {
  background: rgb(231, 234, 238);
  border-color: rgb(231, 234, 238);
}

.details-view-section {
  margin-top: -30px;
}

.details-view-section .status-list ul {
  justify-content: flex-start;
  padding: 0;
}

.details-view-section .status-list ul li:first-child {
  margin-left: 0;
}

.details-view-section .status-list ul li {
  color: rgb(0, 0, 0);
  font-family: Graphik-Medium-Web;
}

.dot.dot-purple,
.bar-purple .progress-bar {
  background: rgb(117, 0, 192);
}

.dot.dot-blue,
.bar-blue .progress-bar {
  background: rgb(133, 215, 255);
}

.dot.dot-gray,
.bar-gray .progress-bar {
  background: rgb(196, 205, 213);
}

.visualization-collapse.accordion .card {
  margin-bottom: 24px;
  border-radius: 5px !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.visualization-collapse.accordion .card:last-child {
  margin-bottom: 0px;
}

.visualization-collapse.configure-client-collapse .card-header {
  padding: 20px 25px;
}

.visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 15px 20px 15px;
}
.program-box-container {
  /* max-height: 400px; */
  overflow-y: auto;
  overflow-x: hidden;
  /* padding: 5px 5px 30px; */
  overflow: visible;
  position: relative;
  margin-top: 20px;
}

.show-project-details.program-box-container {
  max-height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 5px;
  overflow: visible;
}

.program-box-container > .modal-container {
  margin: 0;
  top: -130px;
  left: -18px;
}

.tab-component-content > .program-box-container {
  border-top: 1px solid rgb(181, 192, 202);
}

.tab-component-content > .program-box-container:first-child {
  border-top: none;
}

.program-box {
  padding: 15px;
}

.program-box-container .program-box:last-child {
  border-bottom: none;
}

.program-box-title svg,
.program-box-title-colored svg {
  font-size: 8px !important;
  margin-left: -1px;
}

.program-box-title > .program-box-title-container,
.program-box-title-colored > .program-box-title-container {
  width: 69%;
}

.program-box-title > .dashboard-button-container,
.program-box-title-colored > .dashboard-button-container {
  width: 24%;
  margin-bottom: auto;
}

.program-box-title > div > button:hover,
.program-box-title-colored > div > button:hover {
  background: rgb(161, 0, 255) !important;
}

.program-box-container .program-box-title > div > button:hover > #btn-icon-info > svg.fa-info,
.program-box-container .program-box-title > div > button:hover > span,
.program-box-container
  .program-box-title-colored
  > div
  > button:hover
  > #btn-icon-info
  > svg.fa-info,
.program-box-container .program-box-title-colored > div > button:hover > span {
  color: #fff !important;
}

.program-box-container .program-box-title > div > button:hover > #btn-icon-info,
.program-box-container .program-box-title-colored > div > button:hover > #btn-icon-info {
  border-color: #fff;
}

.program-box-title-colored {
  display: flex;
  align-items: center;
  /* background: rgb(255, 251, 238);
  border: 0.5px solid rgb(255, 200, 44); */
  border-radius: 0px;
  /* border-left-color: rgb(255, 200, 44); */
  border-left-width: 8px;
  padding: 5px 10px;
}

.program-box-title {
  display: flex;
  align-items: center;
  /* background: rgb(251, 245, 255); */
  border-radius: 0px;
  /* border: 0.5px solid rgb(227, 209, 255);
  border-left-color: rgb(222, 193, 255); */
  border-left-width: 8px;
  padding: 5px 10px;
}

#boi-no-aggre {
  width: 28px;
  height: 19px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.program-box-title-label #dashboard-info-icon > .svg-container > svg {
  font-size: 14px !important;
}

.program-box-title h5,
.program-box-title-colored h5 {
  color: rgb(45, 58, 75);
  max-width: 16.25rem;
  word-break: break-word;
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0 10px 0 0;
}

.program-box-title #groove-btn-id .btn-outline-stat-alternate#info-details,
.program-box-title-colored #groove-btn-id .btn-outline-stat-alternate#info-details {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(161, 0, 255);
  border-radius: 16px;
  line-height: 13px;
  padding: 4px 7px;
  width: 115px;
  box-shadow: none;
  align-items: center;
  float: right;
}

.program-box-title #info-details span,
.program-box-title-colored #info-details span {
  color: rgb(161, 0, 255);
  font-size: 10px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 12px;
}

.program-box-title #groove-btn-id > button > *,
.program-box-title-colored #groove-btn-id > button > * {
  font-size: 10px;
  margin: 0 2px;
}

#info-details #btn-icon-info {
  border: 1px solid rgb(161, 0, 255);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  letter-spacing: 0;
  line-height: 14px;
  font-family: 'Graphik-Regular';
  font-weight: 400;
}

.dashboard-button-container #groove-btn-id .btn-outline-stat-alternate.hover > .svg-container > svg,
.dashboard-button-container
  #groove-btn-id
  .btn-outline-stat-alternate:hover
  > .svg-container
  > svg {
  transition: color 0.1s ease-in;

  color: rgb(161, 0, 255) !important;
}

.program-box p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  margin: 0 5px 30px 0px;
}

.program-progress {
  display: flex;
  align-items: center;
  color: rgb(156, 106, 222);
  font-size: 10px;
  font-family: 'Graphik-Medium-Web';
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
}

.reverse .program-progress,
.reverse .xaxis-label {
  flex-direction: row-reverse;
}

.reverse .xaxis-label span {
  text-align: left !important;
}

.reverse .xaxis-label span > div > div {
  position: relative;
  left: -100%;
}

.reverse .xaxis-label > span:first-child > div > div {
  left: 0;
}

.program-progress.has-negative {
  margin-left: 50%;
}

.program-progress.has-negative.negative-val {
  margin-right: 50%;
  margin-left: 0;
  display: flex;
  flex-direction: row-reverse;
}

.negative-val > .deliveredValue-progress,
.negative-val > .identifiedValue-progress {
  margin-left: 5px;
}

.program-progress > div {
  line-height: 1;
}

.program-progress .progress {
  height: 0.625rem;
}

.identifiedValue-progress,
.deliveredValue-progress {
  margin-right: 5px;
}

.program-progress .identifiedValue-progress .progress-bar {
  border-radius: 0px 6.5px 6.5px 0px;
}

.program-progress .deliveredValue-progress .progress-bar {
  border-radius: 0px 6.5px 6.5px 0px;
}

.xaxis-line {
  width: 100%;
  border-bottom: 1px solid rgb(181, 192, 202);
}

.xaxis-label {
  width: 100%;
  display: flex;
  position: relative;
}

.xaxis-label span {
  margin-left: -2px;
  color: rgb(0, 0, 0);
  font-size: 11px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.17px;
  margin-top: 5px;
  position: absolute;
  text-align: right;
  min-width: fit-content !important;
}

.xaxis-label span > div {
  text-align: right !important;
  display: inline-block;
}

.xaxis-label span > div > div {
  margin-right: -50%;
  display: inline-block;
}

.xaxis-label span:first-child > div > div {
  margin: 0;
}

.xaxis-label span:last-child {
  min-width: auto;
}

.modal-container {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 1;
  width: 100%;
}

.dashboard-projectDetails-modal {
  background: rgb(249, 250, 251);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 23px 25px 36px;
}

.projectDetails-modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.projectDetails-modal-header h5 {
  width: calc(100% - 50px);
  height: 14px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.22px;
  border-left-color: rgb(255 255 255 / 38%) !important;
  border-left: groove;
  border-left-width: 2px;
  padding-left: 7px;
  margin-right: 5px;
  margin-left: 5px;
  min-height: 16px;
}
.projectDetails-modal-header h4 {
  width: 28px;
  height: 19px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 3px;
}

.projectDetails-modal-header .fa-times {
  color: rgb(145, 158, 171);
}

.project-tab-content .project-desc {
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 17px;
  margin-bottom: 24px;
}

.project-desc-chart {
  width: 50%;
  padding: 30px 0;
  margin-bottom: -6px;
  height: 300px;
}

/* .project-tabs-list button:active span, .project-tabs-list button:focus span, .project-tabs-list button:active, .project-tabs-list button:focus {
  position: absolute;
} */

.dim-tabs > div > div > div > div {
  margin-bottom: -7px !important;
  margin: 0 -15px;
}

.dim-tabs .MuiTabs-scroller.MuiTabs-scrollable > div {
  margin: 0 !important;
  width: min-content;
}

.project-detail-tabs button > span:first-child {
  text-transform: initial;
  min-width: max-content;
  padding: 0 10px !important;
}

.project-detail-tabs button span {
  font-size: 0.75rem;
  padding: 0;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.21px;
  text-align: center;
  word-wrap: break-word;
  display: block;
  max-width: 200px;
}

.project-detail-tabs button > span:nth-child(2) {
  display: none;
}

.project-detail-tabs button {
  border: 3px solid transparent;
}

.project-detail-tabs button > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.project-detail-tabs .MuiTabs-scrollButtons > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.project-detail-tabs button[aria-selected='true'] {
  border-bottom-color: #a100ff;
}

.project-detail-tabs > div > div > span {
  display: none;
}

.project-detail-tabs span.MuiTabs-indicator {
  display: none;
}

@keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 1ms;
  cursor: pointer;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor-expand > div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0;
}

.chartjs-size-monitor-shrink > div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
}

.data-visualisation-container .statusDropdown {
  width: 440px;
}

.data-visualisation-container .statusDropdown .css-14s1juc-control,
.statusDropdown .css-1csqycf-control {
  width: 420px !important;
}

.data-visualisation-container .statusDropdown .css-144vaf5-menu {
  width: 420px;
}

.data-visualisation-container .reportingYrDropdown .css-144vaf5-menu {
  width: 100px;
}

/* .recharts-surface > .recharts-layer.recharts-pie:nth-child(2),
.recharts-surface > .recharts-layer.recharts-pie:nth-child(4),
.recharts-surface > .recharts-layer.recharts-pie:nth-child(5) {
  cursor: pointer;
} */

.recharts-surface
  > .recharts-layer.recharts-pie:nth-child(2).recharts-layer
  .recharts-pie-sector
  > path[fill='#A9A9A9'],
.recharts-surface
  > .recharts-layer.recharts-pie:nth-child(4).recharts-layer
  .recharts-pie-sector
  > path[fill='#A9A9A9'],
.recharts-surface
  > .recharts-layer.recharts-pie:nth-child(5).recharts-layer
  .recharts-pie-sector
  > path[fill='#A9A9A9'] {
  cursor: not-allowed;
}

#dashboard-info-icon {
  display: inline-block;
}

#dashboard-tooltip-right {
  z-index: 3010;
}

#dashboard-tooltip-right .tooltip-inner {
  -webkit-box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #000;
  padding: 15px 15px;
  max-width: 500px;
}

#dashboard-tooltip-right.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff !important;
}

.dashboard-details-wrap h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
  text-align: left;
}

.dashboard-details-wrap p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
  text-align: left;
}

#dashboard-tooltip-right .tooltip-parameter {
  margin-right: 20px;
}

#tooltiptext-dashboard {
  top: 35px !important;
  clear: both !important;
  left: -260px !important;
  box-shadow: 0 0 2px black;
}

.program-box-title-label {
  color: rgb(99, 115, 129);
  word-break: break-word;
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0 0 0 0;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 0px 10px;
}

#prog-view {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 23px;
  letter-spacing: -0.25px;
  display: inline;
  width: auto;
  padding-right: 10px;
  border-right: outset;
  border-right-width: 1.5px;
  border-right-color: rgb(255 255 255 / 30%);
  margin-right: 4px;
  margin-bottom: auto;
}
#prog-view-colored {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  height: 23px;
  letter-spacing: -0.22px;
  display: inline;
}

.aggregation-label {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  border-left: 1px solid;
  white-space: nowrap;
  margin-left: 5px;
  padding: 0px 10px;
}

.program-box-title > div.dashboard-button-container:empty,
.program-box-title-colored > div.dashboard-button-container:empty {
  width: 0%;
}

.program-box-title > div.no-aggregation,
.program-box-title-colored > div.no-aggregation {
  width: 22vw;
}

.db-numerical-label {
  display: flex;
  margin: 0 5px;
}

/* SVM Wheel summary css starts here */

.align-piechart {
  display: flex;
  justify-content: center;
  max-width: 513px;
  margin: auto;
}

.graph-view-section {
  height: 890px;
  overflow: hidden;
}

.svmOverview-page {
  padding-top: 10px;
}

.svmOverview-page #download {
  float: right;
  color: #637381;
  font-size: 12px;
  margin-top: -29px;
}

.svmOverview-page #hrTop {
  margin: 0 0 10px 0;
  border-top: 2px solid rgb(166, 166, 166);
}

span#download {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

span#download > img {
  margin-right: 10px;
}

.col-lg-3 .dimension-card {
  margin-bottom: 24px;
}

.col-lg-3 .dimension-card:last-child {
  margin-bottom: 5px;
}

.dm-card-header {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 16px;
  padding: 6px 12px;
  width: 80%;
  border-radius: 4px;
  position: relative;
  margin-left: 8px;
  z-index: 1;
}

.dm-card-box {
  background: rgb(249, 250, 251);
  border-radius: 8px;
  border: 1px solid rgba(151, 151, 151, 0.36);
  margin-top: -14px;
  padding: 14px 12px 0 0;
  max-height: 430px;
  overflow-y: auto;
}

.dm-card-box-disabled {
  padding: 5px 14px;
  margin-left: 8px;
  width: 80%;
  height: 29px;
  background: #e2e2e2;
  border-radius: 2px;
  font-size: 13px;
  color: #282529;
}

.dm-card-details {
  padding: 0 0 4px 12px;
  max-height: 300px;
  overflow: hidden;
}

.dm-card-details ul {
  padding-left: 16px;
  margin-bottom: 0;
}

.dm-card-details h6 {
  color: rgb(74, 33, 93);
  font-size: 13px;
  line-height: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.23px;
  margin: 8px 0px;
}

.dm-card-details ul li {
  word-break: break-word;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 13px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.21px;
  margin-bottom: 10px;
}

.dm-card-details ul li p,
.dm-card-details ul li div:first-child {
  color: rgb(40, 37, 41);
  font-family: Graphik-Medium;
  font-weight: 500;
  margin-bottom: 0;
  /* max-width: 82%; */
  display: inline-block;
  /* vertical-align: middle; */
  vertical-align: text-top;
}

.dm-card-details ul li p b,
.dm-card-details ul li div:first-child p b {
  font-family: Graphik-Semibold;
  font-weight: 600;
}

.dm-card-details ul li p b:first-child,
.dm-card-details ul li div:first-child p:nth-child(2) b {
  color: rgb(200, 154, 21);
  margin-left: 2px;
}

.dm-card-details ul li p b:nth-child(2),
.dm-card-details ul li div:first-child p:nth-child(3) b {
  color: rgb(12, 127, 170);
  margin-left: 2px;
}

.view-more-link {
  color: rgb(150, 72, 155);
  font-size: 12px;
  line-height: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  /* margin: 10px 0 16px 28px; */
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  margin-bottom: 15px
}

.btn-knowMore-container {
  position: absolute;
  margin-left: calc(50% - 78px);
  margin-top: calc(50% - 180px);
  margin-top: 0 !important;
  top: unset !important;
  bottom: 0;
}

.btn-knowMore {
  left: calc(50% - 60px);
}

.btn-knowMore-container #groove-btn-id > button > * {
  font-size: 14px;
}

#legend-container {
  margin-top: 10px;
  left: calc(50% - 62.66px);
  position: relative;
  font-size: 10px;
}

#legend-container #legend-identified {
  color: #f49342;
  margin-right: 3px;
}

#legend-container #legend-delivered {
  color: #26c1c9;
  margin-left: 11px;
  margin-right: 3px;
}
.dashboard-wheel-spacing {
  fill: #f9fafb;
  transform-origin: calc(100% / 2) calc(28% + 7px / 2);
}
.dashboard-wheel-vd-spacing {
  fill: #f9fafb;
  transform-origin: calc(100% / 2) calc(28% + 7px / 2);
}
@media (min-width: 992px) {
  .svmOverview-page .col-lg-3 {
    flex: 0 0 29%;
    max-width: 29%;
  }
  .svmOverview-page .col-lg-6 {
    flex: 0 0 42%;
    max-width: 42%;
  }
}

@media (width: 1536px) {
  .btn-knowMore-container {
    top: 560px;
  }
  .dm-card-box {
    max-height: 489px;
  }
}

@media (width: 1280px) {
  .btn-knowMore-container {
    top: 455px;
  }
}

/* SVM Wheel summary css ends here */

.db-back-icon {
  font-size: 24px;
  color: #637381;
  cursor: pointer;
}

.program-cumulative-container {
  width: 49%;
  height: 180px;
  position: relative;
}

.prog-UOM {
  width: 63px;
  height: 12px;
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.25px;
  line-height: 12px;
  position: relative;
  left: -28px;
  top: -10px;
}

.cumulative-container {
  padding: 10px 20px 10px 10px;
  position: relative;
  left: -10px;
}

.title-container {
  width: 100%;
  margin-bottom: 20px;
}

.cumulative-container .title-container {
  margin-left: 7px;
  flex-direction: column;
  display: flex;
}

.title-container .title {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
  width: 97px;
  margin: 0;
}

.title-container .fy {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
  width: 100%;
}

.graph-container,
.project-tab-content > div.charts {
  display: flex;
  width: 100%;
  height: 100%;
}

.graph-left {
  width: 100%;
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.graph-right {
  height: 180px;
  width: 100%;
  padding: 0 10px 0px 10px;
  /* margin: 0; */
}

.graph-right .trend-header {
  width: 100%;
  height: 18px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  position: relative;
  top: -55px;
}

.graph-right .program-trend-chart {
  height: 90%;
  width: 45%;
  padding-left: 15px;
}

.graph-right .no-trend-label {
  height: 18px;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  position: relative;
  top: 35%;
  /* left: 30%; */
  text-align: center;
}
.graph-right #program-trend-chart span.recharts-legend-item-text {
  font-size: 12px;
}

.graph-right #program-trend-chart p.recharts-tooltip-label {
  font-size: 14px !important;
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(21, 24, 27);
}

.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-name,
.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-separator {
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(21, 24, 27) !important;
}

.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-value,
.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-unit {
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.comment-section {
  display: flex;
  margin: 10px 0px;
  padding: 0 0px 0px 5px;
  width: 100%;
}

.comment-section .comment {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  word-break: break-word;
  vertical-align: middle;
  margin: 5px;
  flex: 1;
}

.comment-section .comment-btn {
  color: rgb(1, 142, 255);
  font-family: Graphik-Regular !important;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
  min-width: 100px;
  vertical-align: middle;
  margin: 5px;
}

.commentary-holder {
  word-break: break-all;
}
.dot.square-palette-amber {
  background: rgb(255, 200, 44);
  border-radius: 0px;
  height: 8px;
  width: 8px;
}

.dot.square-palette-purple {
  background: rgb(111, 59, 171);
  border-radius: 0px;
  height: 8px;
  width: 8px;
}

.asterisk-notif {
  width: 112px;
  height: 12px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  font-style: italic;
  letter-spacing: -0.25px;
  line-height: 12px;
}
/* .dot.square-palette-blue {
  background: rgb(102, 187, 255);
  border-radius: 0px;
  height: 8px;
  width: 8px;
} */

div[role='tabpanel'] > div {
  /* max-height: 500px; */
  /* overflow-x: hidden; */
  padding: 0px;
  /* overflow-y: auto; */
  position: relative;
  left: 18px;
}

.vertical-divider {
  /* width: 1px; */
  height: 185px;
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  padding: 0 0.5px;
}

.dashboard-new-info-wrap {
  width: 35px;
}

.dashboard-new-info-wrap img.new-info-icon {
  transform: scale(1);
  margin-top: 0;
  margin-left: -6px;
}

/* COMMENTARY */

.comment-section #addComment,
.comment-section #viewHistory {
  align-items: center;
  padding: 0;
  float: right;
}

.comment-section #addComment span,
.comment-section #viewHistory span {
  font-size: 10px;
  font-family: Graphik-Regular;
  letter-spacing: -0.21px;
  line-height: 12px;
  flex: 1;
  margin-top: 2px;
}

.comment-section #btn-icon-plus {
  border: 1px solid #a100ff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  letter-spacing: 0;
  line-height: 10px;
  font-family: 'Graphik-Regular';
  font-weight: 400;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-section .history-btn #groove-btn-id > button > * {
  margin: 0;
}

.project-tabs-list.no-aggregation-logic .project-tab-content {
  display: flex;
}

.project-tabs-list.no-aggregation-logic .project-desc-chart {
  padding: 30px 0px 0px 0px;
  height: 250px;
  width: 40%;
}
#program-trend-chart svg.recharts-surface {
  overflow: visible;
}

#dashboard-tooltip-right.tooltip.show {
  opacity: 1;
}

.tspanLabel {
  font-family: 'Graphik-Regular-Web';
  font-weight: 400;
  font-size: 8px;
  visibility: hidden;
}

#dashboard-tooltip-right table tr th:first-child,
#dashboard-tooltip-right table tr td:first-child {
  padding-left: 0;
  padding-right: 20px !important;
}

.chart-col {
  flex: 0 0 100%;
  max-width: 50%;
}
.vertical-divider {
  display: block;
}
.graph-left {
  padding-right: 10px;
}

.projectDetails-modal-body .graph-container,
.project-tab-content > div.charts {
  align-items: center !important;
}

@media (max-width: 1024px) {
  .projectDetails-modal-body .graph-container,
  .project-tab-content > div.charts {
    flex-direction: column;
  }
  .project-desc-chart {
    width: 100%;
  }
  .global-project-desc-chart {
    width: 100%;
  }
}
.taking-screenshot {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

/*Trend chart y-axis*/
.graph-right tspan {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 1.2;
  opacity: 0.8;
}
.project-tabs-list #groove-select-dropdown div#selectmenu-anchor {
  border-radius: 8px !important;
  background: rgb(250, 245, 253) !important;
  border: 1px solid rgb(161, 0, 255);
}
.project-tabs-list #groove-select-dropdown div#selectmenu-options div.dropdown-list {
  max-height: 145px;
  color: #fff !important;
  width: -webkit-fill-available;
  margin: -10px 15px 5px 15px;
}
.project-tabs-list .dropdown-item:hover {
  background-color: #f4f6f8 !important;
  color: rgb(32, 31, 31) !important;
}
.project-tabs-list #groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium;
  font-size: 11px;
}
.project-tabs-list
  #groove-select-dropdown
  div.selectmenu-options
  .searchable-dropdown-content
  .searchable-dropdown-input {
  width: 80%;
}
.project-tabs-list .body-small {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
}
.project-tabs-list .body-regular {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  width: 458px;
}
.project-tabs-list #groove-select-dropdown #selectmenu-dropdown-icon {
  margin-top: 15px;
  margin-right: 3px;
}
.project-tabs-list #groove-select-dropdown svg {
  color: #212b36;
  width: 13px;
}

.summation {
  background: rgb(244, 251, 255);
  padding: 5px 10px;
  border-radius: 16px;
  opacity: 1;
  border: none;
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  height: 18px;
  letter-spacing: 0.25px;
  line-height: 11px;
  width: fit-content;
}
.sum-dim {
  top: -2px;
  position: relative;
  margin-left: 7px;
}

.sum-subdim {
  float: right;
  margin-top: -2px;
}

.project-tabs-list.no-aggregation-logic .comment-section-history {
  display: flex;
  flex-direction: row;
}
.legend-list {
  /* position: relative;
  top: -15px; */
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.legend-list-item {
  /* color: rgb(145, 158, 171); */
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 10px;
  font-weight: normal;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  white-space: nowrap;
}

.dot-square-palette-purple {
  background: rgb(111, 59, 171);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  /* position: relative;
  top: 3px; */
}
.dot-square-palette-gray {
  background: rgb(145, 158, 171);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  /* position: relative;
  top: 3px; */
}
.dot-square-palette-blue {
  background: rgb(102, 187, 255);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  /* position: relative;
  top: 3px; */
}

/* Global Dashboard CSS */
.global-dashboard-rs {
  padding: 50px 0 0 24px;
  max-width: calc(100% - 160px);
  margin-left: 0 !important;
}

.default-view-container .default-view-global {
  vertical-align: middle;
  text-align: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  width: 45rem;
  height: 14rem;
  margin-top: 5rem;
}

.global-graph-left {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.global-program-cumulative-container {
  width: 95%;
  height: 180px;
  position: relative;
}

.global-legend-list {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.global-legend-list-item {
  /* color: rgb(145, 158, 171); */
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  white-space: nowrap;
}

.global-dot-square-palette-purple {
  background: rgb(161, 0, 255);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
}

.global-dot-square-palette-amber {
  background: rgb(255, 200, 44);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
}
.custom-tooltip {
  min-width: 150px;
}

.custom-tooltip-row-container {
  display: flex;
  width: 100%;
  /* align-items: center; */
}

.custom-tooltip-row-label {
  width: 45%;
}

.custom-tooltip-row-value {
  width: 55%;
  white-space: nowrap;
  /* overflow: hidden; */
}

.statusDropdown.disabled > .multi-select-dropdown:hover {
  cursor: not-allowed;
}

.global-project-desc-chart {
  width: 100%;
  padding: 30px 0;
  margin-bottom: -6px;
  height: 300px;
}
.project-tabs-list.no-aggregation-logic .global-project-desc-chart {
  padding: 30px 0px 0px 0px;
  height: 250px;
  width: 40%;
}

@media screen and (min-width: 480px) {
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 100px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: 100px;
  }
}

@media screen and (min-width: 768px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 100px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: 100px;
  }
}

@media screen and (min-width: 840px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 200px !important;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: 100px;
  }
}

@media screen and (min-width: 992px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control,
  .statusDropdown .css-144vaf5-menu {
    width: 350px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 685px;
  }
  .btn-knowMore-container {
    margin-top: calc(50% - 100px);
  }
}

/* Dashboard Responsive*/
@media screen and (max-width: 1024px) {
  /* Stage 3 */
  .col-md-5.graph-view-section,
  .col-md-7.details-view-section {
    flex: none !important;
    max-width: 100% !important;
  }
  .graph-view-section {
    height: 500px;
  }
  .graph-view-section #piechartContainer #wheel-chart-container .recharts-wrapper,
  .graph-view-section #piechartContainer #wheel-chart-container .recharts-wrapper svg {
    height: 800px !important;
  }
}

@media screen and (min-width: 1200px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 380px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: calc(50% - 150px);
  }
}

@media screen and (min-width: 2560px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 480px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 1000px;
  }
  .btn-knowMore-container {
    margin-top: calc(50% - 340px);
  }
}

/* chart tooltip css */
.dot-square-palette-aqua {
  background: rgb(38, 193, 201);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 4px;
}

.dot-square-palette-amber {
  background: rgb(244, 147, 66);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 4px;
}

.custom-tooltip .global-legend-list-item {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-right: 5px;
  white-space: nowrap;
}

.custom-tooltip .global-legend-list {
  width: 100%;
  background: rgb(21, 24, 27);
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 52px;
  display: inline-block;
  padding: 10px;
  /* text-align: center; */
}

.tooltip-body {
  display: flex;
  flex-direction: column;
}

.chart-tooltip {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 0px;
}

.tooltip-body > div {
  display: flex;
  align-items: center;
}

.chartjs-tooltip > table {
  width: 100%;
}

.tooltip-row-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.tooltip-row-label {
  width: 60%;
}

.tooltip-row-value {
  width: 40%;
  white-space: nowrap;
  display: flex;
  /* overflow: hidden; */
}

.tooltip-row-value > div {
  padding-left: 4px;
}

.disclaimer-BOI-UOM {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  width: 195%;
  height: 50px;
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255);
  border-radius: 8px;
  padding: 0 10px;
  line-height: 20px;
  color: rgb(161, 0, 255);
  position: relative;
  left: 5px;
  top: 10px;
}

.disclaimer-BOI-UOM .disclaimer-usd {
  color: rgb(117, 0, 192);
  font-family: Graphik-Medium;
  font-size: 13px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.22px;
  line-height: 20px;
  padding-bottom: 10px;
  display: inline-table;
  margin-top: 11px;
}

.disclaimer-info-icon {
  margin-right: 8px;
  border: 2px solid rgb(117, 0, 192);
  border-radius: 50px;
  height: 20px;
  width: 20px;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.disclaimer-info-icon .svg-container {
  margin: 0 auto;
  width: auto;
}
.exclamation-icon-dashboard {
  color: #a100ff;
  height: 10px;
}

.dashboi-header {
  width: -webkit-fill-available;
}

.subtab-uom {
  background: rgb(181, 192, 202);
  border-radius: 0px;
  height: 1px;
  width: 100%;
  left: 0px;
  position: absolute;
}

.program-box-container .visualization-collapse.accordion .card:last-child {
  border-left: 8px solid rgb(181, 192, 202);
}

.program-box-container .visualization-collapse.configure-client-collapse .card-header {
  padding: 0px 15px 0px 5px !important;
  display: inline-flex;
}

.program-box-container .configure-client-collapse .card-header > button {
  align-items: baseline;
  text-align-last: left;
  width: 4%;
}

/* .dashboard-button-container {
  position: absolute;
  right: 47px;
  top: 14px;
} */

.collapse.show ~ div.card-header #info-details div.svg-container {
  transform: rotate(0deg);
}
.accordion-button-dashboard {
  position: absolute;
  right: 1px;
  top: 14px;
}

.program-box-container .dimension-header-container {
  margin: 0;
}

.dashboard-projectDetails-modal #scrollable-auto-tabpanel-0 > div {
  left: -10px;
}
#dashboard-info-icon .custom-boi {
  margin-top: 0;
}

.program-box-container .visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 10px 20px 10px;
}

.project-detail-tabs button[aria-selected='true'] .subtabs-UOM {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
}
.project-detail-tabs button[aria-selected='false'] .subtabs-UOM {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
}

.project-tabs-list button.subtabs-UOM-Highlight {
  min-width: 64px;
}
#btn-icon-history .svg-inline--fa {
  vertical-align: 1 !important;
}
#btn-icon-history.svg-container {
  transform: none;
}

.data-visualisation-container .dm-card-details h6.dashboard-subdim {
  color: rgb(70, 0, 115);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.data-visualisation-container .dm-card-details .dashboard-boi .card{
  margin-bottom: 10px;
  padding: 10px !important;
}

.data-visualisation-container .dm-card-details .boi-title {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.data-visualisation-container .dm-card-details .comment-container {
  margin-bottom: 5px;
}

.data-visualisation-container .dm-card-details .boi-dahsboard-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.data-visualisation-container .dm-card-details .boi-detail {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.data-visualisation-container .dm-card-details .boi-dahsboard-details span {
  flex: 1;
}

.data-visualisation-container .dm-card-details .boi-dahsboard-details .uom-container{
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px;
  border: none;
  margin-top: 0.1em;
  margin-right: 20px;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 50px;
  text-align: center;
  display: inline-block;
  margin-left: 3px;
}

.data-visualisation-container .dm-card-details .comment-container {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}

/* QC START */
.data-visualisation-container.svm-tool .dqc-modal-loader > div {
  margin-left: 47.5% !important;
  margin-top: 19.5% !important;
}

.data-visualisation-container.svm-tool .dqc-modal-loader {
  height: 95% !important;
  width: 97.5% !important;
}

.data-visualisation-container.svm-tool #mt-count-tooltip div[class*='-container'] > div[class*='-control'] > div:first-child {
height: 50px !important;
}

.data-visualisation-container.svm-tool span#download>img {
  height: 38px;
  padding: 8px;
  width: 32px !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
}
/* QC END */