.cvm-date-range {
  position: relative;
}

.cvm-date-range-overlay {
  width: 100%;
  height: 100%;
  background: #a2a2a21f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
}

.cvm-date-range-pop {
  width: 100%;
  background: white;
  position: absolute;
  bottom: -70px;
  z-index: 99;
  border-radius: 10px;
  padding: 20px 30px;
  box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%);
}

.date-range-selected {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: 54px !important;
  padding: .375rem .75rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}

.date-range-selected.selected-date-field {
  border: 1.5px solid #a100ff !important;
  box-shadow: 0 0 5px #a100ff54;
}

.date-range-label {
  color: #a100ff !important;
  font-family: "Graphik-Medium" !important;
  font-size: 11px !important;
  line-height: initial !important;
}

.date-range-value {
  color: #212b36 !important;
  font-size: 16px !important;
  letter-spacing: -.25px !important;
}

.date-range-value.blank-value, .cvm-date-range-input.blank-value {
  color: #919eab !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.cvm-date-range-input.blank-value {
  padding: .375rem 1.45rem .375rem .75rem;
}

.cvm-date-range-input-required {
  border: 1px solid red !important;
  box-shadow: 0 0 5px #ff00007d;
}

.date-range-picker-container {
  width: 100%;
  padding: 15px;
}

.date-range-picker-header {
  padding: 5px 20px;
  border: 1.5px solid #ced4da;
  border-radius: .25rem .25rem 0 0;
  display: flex;
  justify-content: space-between;
}

.date-range-picker-header > div {
  color: rgb(80 80 80) !important;
  font-family: "Graphik-Medium" !important;
  font-size: 14px !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  align-items: center;
  display: flex;
}

.adjust-year-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.adjust-year-button > div {
  font-size: 18px !important;
}

.date-range-picker-body {
  border: 1.5px solid #ced4da;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 10px 15px;
}

.month-row {
  display: flex;
  justify-content: space-around;
  border: 1px solid #ced4da;
  border-bottom: none;
}

.month-row:last-child {
  border: 1px solid #ced4da;
}

.month-tile {
  width: 100%;
  text-align: center;
  padding: 5px;
  font-family: "Graphik-Medium" !important;
  cursor: pointer;
  height: 42px;
  font-size: 14px !important;
  color: rgb(80 80 80) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-tile:hover {
  box-shadow: 0 0 5px #a100ff29;
  background: #bf52ff29;
}

.month-tile.selected-month {
  background: #bf52ff29;
}

.month-tile:nth-child(2) {
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
}

.month-tile.disabled-month {
  background: #ececec9e;
  color: #5050507d !important;
  cursor: not-allowed;
  box-shadow: none;
}

.date-range-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 15px;
}


.cvm-date-range-input {
  width: 100%;
  font-family: "Graphik-Regular" !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: -.25px !important;
  line-height: 18px !important;
  height: 54px !important;  
  padding: 1.2rem 1.45rem .375rem .75rem;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  justify-content: space-between;
  color: #212b36 !important;
}

.cvm-date-range-input:hover {
  cursor: pointer;
  border: 1.5px solid #a100ff !important;
}

#cal-icon {
  font-size: 20px !important;
}

.custom-field-label {
  color: #a100ff !important;
  font-family: "Graphik-Medium";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -.25px;
  line-height: 11px;
  position: absolute;
  top: 8px;
}

.disabled-field {
  background: #ececec9e;
  color: #5050507d !important;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}