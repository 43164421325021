.CVMSideModal-container-comment {
    padding: 28px 25px 0px 23px;
    width: 368px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(220, 226, 231);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: absolute;
    display: block;
    height: 50%;
    right: 0px;
    z-index: 999!important;
    overflow-y: auto;
}
.CVMSideModal-close {
    position: absolute;
    right: 25px;
    top: 18px;
    z-index: 9999!important;
    color: #919eab;
    font-weight: 500;
    cursor: pointer;
}

.CVMSideModal-title {
    color: rgb(45, 58, 75);
    font-size: 18px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    text-align: left;
    letter-spacing: -0.28px;
    line-height: 18px;
    margin-bottom: 17px;
}
.CVMSideModal-tab-container {
    overflow-y: auto;
    padding: 0;
    margin-top: 16px;
}
.CVMSideModal-tab-container .card {
    border: none;
}
.CVMSideModal-tab-container .card-header .btn {
    display: flex;
    align-items: center;
}
.CVMSideModal-tab-container .card-header {
    border: none;
    background: #fff;
    padding: 0;
}
.CVMSideModal-tab-container .btn-link.focus, 
.CVMSideModal-tab-container .btn-link:focus, 
.CVMSideModal-tab-container .btn-link:hover {
    text-decoration: none;
}
.CVMSideModal-tab-container .btn-link {
    color: #a100ff;
}
.CVMSideModal-tab-container .btn-link:hover {
    color: #7500c0;
}
.CVMSideModal-tab-container .btn.focus, 
.CVMSideModal-tab-container .btn:focus {
    outline: 0;
    box-shadow:none;
}
.CVMSideModal-tab-container .card-body {
    padding: 0 0 0 1.25rem;
}
.CVMSideModal-tab-container .card-header svg {
    margin-right: 10px;
}

.CVMSideModal-tab-container .collapse.show ~ .card-header svg{
    transform: rotate(90deg);
}


/* #groove-textarea .floating-label-textbox #comment-textbox.groove-textbox {
    border: 1px solid #dfe3e8 !important;
    padding: 20px 15px 10px 15px !important;
    color: #212b36;
    font-size: 1rem;
    letter-spacing: -0.35px;
}
.CVMSideModal-container #groove-textarea .floating-label-textbox #comment-textbox.groove-textbox {
    height: 184px;
}
#groove-textarea .floating-label-textbox #comment-textbox.groove-textbox:focus {
    border: 1px solid #a100ff !important;
    padding: 20px 15px 10px 15px !important;
}
.rejection-modal-buttons #groove-btn-id > button {
    line-height: 1.4;
} */

.CVMSideModal-button-container {
    margin-top: 20px;
}