/* Master Config Landing */
.data-ingestion-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(181, 192, 202);
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.28px;
  padding: 50px 0 !important;
}

.data-ingestion-placeholder > img {
  width: 79px;
}

.data-ingestion-placeholder > span {
  color: rgb(181, 192, 202);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin: 32px 0 24px 0;
}

.rvd-view-select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  margin-bottom: 10px;
  /* margin-top: 10px; */
  align-items: center;
}

.rvd-client-details-btn{
  justify-content: space-between;
}

/* uncomment for corebeat */
/* .rvd-program-card-box.error{
  background: rgb(255, 245, 247);
  border-radius: 8px;
  animation: blink 2s step-end infinite;
}

@keyframes blink{
  50% {
    border: 1px solid rgb(213, 0, 47);
  }
} */
