.body-content {
  height: auto;
  min-height: 81.2vh;
}

/* .body_container {
  background: #f9f9f9;
} */

input::-ms-clear {
  display: none;
}

.modal-backdrop {
  background-color: #fff !important;
}

.rfa_container {
  width: 426px;
  margin: 60px auto;
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.rfa_container > div {
  padding: 0;
}

.report-title {
  margin-left: 60px;
  margin-bottom: 20px;
  color: #008dfd;
  margin-top: 30px;
  font-size: 2.3em;
  font-family: Graphik-Regular-Web;
}

.bo-title {
  margin-left: 60px;
  margin-bottom: 20px;
  color: #008dfd;
  margin-top: 30px;
  font-size: 2.3em;
  font-family: Graphik-Regular-Web;
}

.rfa_title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold-Web;
  font-size: 18px;
  letter-spacing: -0.24px;
  line-height: 26px;
  padding-top: 8px;
}

/* Access Request Tabs */
.rfa_container .nav-tabs {
  border-bottom: 1px solid rgb(220, 220, 220);
  padding-top: 20px;
}
.rfa_container .nav-tabs > li.active > a,
.rfa_container .nav-tabs > li.active > a:hover,
.rfa_container .nav-tabs > li.active > a:focus {
  border-top: 0px;
  border-bottom: 4px solid #b200ff;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  cursor: pointer;
  padding: 0px 0px 15px 0px;
  margin-left: 30px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
}

.rfa_container .nav > li > a,
.rfa_container .nav > li > a:hover,
.rfa_container .nav > li > a:focus {
  margin-left: 30px;
  padding: 0;
  outline: none;
  font-family: Graphik-Regular-Web;
  color: #000;
  background-color: transparent;
  border: 0px;
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
}

.rfa_container .tab-pane {
  margin: 20px 0px 0px 0px;
}

.rfa_container div.form-group,
.rfa_container .form-group label {
  text-align: left;
}

.rfa_container .form-group div {
  padding: 0px;
}

.rfa_container .form-group label,
.rfa_container .form-group input,
.rfa_container .form-group textarea {
  font: normal 0.87em OpenSans-Regular;
  color: #000;
  padding-top: 5px;
}

.rfa_container .form-group input {
  /* border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 56px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  font-family: Graphik-Regular-Web !important;
  padding: 39px 13px 16px 13px; */

  border: 1px solid rgb(223, 227, 232);
  padding: 22px 14px 3px 14px;
  color: rgb(33, 43, 54);
  font-size: 16px !important;
  letter-spacing: -0.35px;
  line-height: 16px;
  border-radius: 4px !important;
  font-family: Graphik-Regular-Web !important;
  height: 58px !important;
}

.rfa_container .form-group textarea {
  width: 100%;
  resize: none;
  padding: 3px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 100px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  font-family: Graphik-Regular-Web !important;
  padding: 29px 13px 16px 13px;
}

.rfa_wrapper {
  min-height: calc(100vh - 144px);
}

.rfa_container .form-control {
  box-shadow: none;
}

.rfa_container .form-horizontal .form-group {
  margin: 10px 35px;
}
.rfa_container .form-horizontal {
  padding: 0px;
}
.rfa_container .form-horizontal .form-group:last-child {
  margin-top: 15px;
}

.access-request-button-container {
  padding: 0;
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid rgb(223, 227, 232);
  height: 72px;
  margin-top: 20px;
}

.form-horizontal .label-of-field {
  position: absolute;
  z-index: 1;
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium-Web !important;
  letter-spacing: 0.2px;
  line-height: 11px;
  padding: 10px 15px !important;
  font-size: 0.85em !important;
  font-weight: normal !important;
}

.form-horizontal .blank-field {
  padding: 14px !important;
  color: rgb(33, 43, 54);
  font-size: 16px !important;
  letter-spacing: -0.35px;
  line-height: 16px;
  border-radius: 4px !important;
  font-family: Graphik-Regular-Web !important;
  height: 58px !important;
}

.form-horizontal .blank-textarea {
  padding: 18.7px 13px !important;
}

.rfa_container button,
.rfa_container button .btn-default {
  color: rgb(161, 0, 255);
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(161, 0, 255);
  padding: 7.5px 16px;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.27px;
  text-align: center;
  cursor: pointer;
  float: right;
  width: 120px;
  outline: none;
  box-shadow: none;
  top: 0;
  height: 40px;
}

.rfa_container .tab-content {
  margin: 0px !important;
}

.rfa_container button:hover,
.rfa_container button:focus,
.rfa_container button:active:focus,
.rfa_container button .btn-default:hover,
.rfa_container button .btn-default:focus,
.rfa_container button .btn-default:active:focus {
  color: #fff;
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  background-color: #a100ff;
  font-weight: 500;
  border: 0;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #a100ff;
  border-radius: 20px;
}

.rfa_container button:disabled,
.rfa_container .btn-default:disabled,
.rfa_container .btn-default[disabled]:hover {
  background-color: #a0a0a0;
  border: 1px solid #a0a0a0;
  color: #fff;
}

.rfa_container .svg-inline--fa.fa-fw {
  width: 0.5em;
  color: #a100ff;
  margin-bottom: 2px;
}

.rfa_container .form-group input.required {
  border: 1px solid rgb(161, 0, 255);
}

.rfa_container span.characterCounter {
  font: normal 0.7em OpenSans-Regular;
  color: #a0a0a0;
  float: right;
}

body.modal-open #root {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

/* Access Request Modal CSS */
.rfa_modal .modal-dialog {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 25%;
}

.rfa_modal .modal-content {
  border: 0px;
  border-radius: 16px;
}

.rfa_modal .modal-body {
  text-align: center;
}

.rfa_modal .modal-body span,
.rfa_modal .modal-body svg {
  display: inline-block;
  margin: 15px;
}

.rfa_modal .modal-body svg {
  color: #a100ff;
}

.rfa_modal .modal-body span {
  font-family: OpenSans-Regular;
  font-size: 1em;
  color: #000;
}

.rfa_modal button,
.rfa_modal button:hover,
.rfa_modal button:focus,
.rfa_modal .btn-default,
.rfa_modal .btn-default:hover,
.rfa_modal .btn-default:focus {
  border: 0px;
  color: #008dfd;
  font: normal 1.2em OpenSans-Regular;
  background-color: #fff;
  margin: auto;
  outline: none;
  border-style: outset;
  box-shadow: none;
}

/* Bulk Request Access */

.rfa-labels-container {
  margin: 0 40px;
  height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rfa-footer-container {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid rgb(223, 227, 232);
  height: 70px;
  padding: 15px 25px;
}

label#RequestForAccessFileLabel.input-file {
  width: 100px;
  background-color: #a100ff;
  text-align: center;
  font: 0.87em 'OpenSans-Regular';
  height: 30px;
  cursor: pointer;
  color: #fff;
}

#requestFileName {
  color: rgb(78 78 78);
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: 20px;
}

#requestForAccessAnchor {
  color: rgb(161, 0, 255);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.access_denied_container,
.page_notfound_container {
  padding: 15.2% 0% 0% 0%;
  text-align: center;
}

.access_denied_logo {
  text-align: right;
  font-size: 11.5em;
  color: #008dfd;
  padding: 10px 0 0 8px;
  margin: 0;
}

.access_denied_content,
.page_notfound_content {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  padding: 15px 0 0 11.5px;
}

.access_denied_content h1,
.page_notfound_content h1 {
  font-size: 8em;
  font-family: Graphik-Extralight-Web;
  font-weight: normal;
  color: rgb(161, 0, 255);
  padding: 0;
  margin: 0;
  line-height: 1;
}

.access_denied_content p,
.page_notfound_content p {
  font-size: 1.32em;
  font-family: OpenSans-Light;
  color: #000;
  padding: 0 0 0 19px;
  margin: 0;
}

.glyphicon-ban-circle {
  color: rgb(161, 0, 255);
}

.access_denied_btn,
.page_notfound_btn {
  font-size: 1.1em;
  background-color: rgb(161, 0, 255);
  padding: 4.5px 26px;
  font-weight: normal;
  color: #fff;
  font-family: OpenSans-Regular;
  border: 0;
  margin: 15px 0 0 23px;
}

#bulkUploadFallout {
  font-family: Graphik-Regular-Web;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-top: 10px;
  display: none;
}

#bulkUploadFallout > a {
  color: rgb(178, 0, 255);
}

#bulkUploadError,
#bulkUploadSuccess,
#bulkUploadSecurityError,
#bulkUploadErrorFileSize {
  font-family: Graphik-Regular-Web;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: none;
  padding-top: 15px;
  padding-right: 40px;
}

.btn-default[disabled]:hover {
  background-color: #008dfd;
}

/* Module Modals CSS */
.modules_modal .modal-content {
  text-align: center;
  border-radius: 16px;
}

.modules_modal .button_container {
  text-align: center;
}

.modules_modal .btn,
.modules_modal .btn:hover,
.modules_modal .btn:focus {
  background-color: #fff;
  color: #008dfd;
  width: 80px;
}

.modules_modal button#noBtn {
  color: #a0a0a0;
}

.modules_modal .content_container .svg-inline--fa.fa-fw {
  width: 1.5em;
  color: #61b1ff;
  margin-bottom: 2px;
  border: 1px solid;
  border-radius: 100%;
  border-width: 3px;
  height: 1.5em;
  padding: 14px;
}

/* Form Modals CSS */
.form_modal .modal-content {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.form_modal .button_container {
  text-align: right;
  padding: 10px 10px;
}

.form_modal button {
  margin: 0px 5px;
  width: 100px;
}

/* div.modal-body div.content_container span {
  font-family: Graphik-Regular-Web;
} */

.bo_create_modal_title {
  font-family: Graphik-Regular-Web;
  padding: 15px;
}

/* Stepper CSS */
.stepper_container > img {
}

.stepper_container {
  display: inline;
  font-size: 1em;
  padding: 0;
}

.stepper {
  border-radius: 100%;
  border: solid 1px;
  border-color: #000;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  display: inline-block;
  line-height: 1.7;
  margin: 2px;
}

.stepper_title {
  display: inline;
  font-family: SourceSansPro-SemiBold;
  vertical-align: middle;
}

span.stepper_title {
  display: inline;
  font-family: SourceSansPro-SemiBold;
  font-size: 13px;
  color: #7b8e98;
  vertical-align: middle;
}

span.stepper_active {
  color: #343d47;
  /* border-color: #000; */
}

.stepper_active {
  color: #343d47;
  /* border-color: #000; */
}

.stepbar {
  padding: 0 2px;
  display: inline-block;
  vertical-align: middle;
}

.stepbar > hr {
  border-bottom: 3px solid #bdc0c4;
  border-top: 0px;
  margin: 9px 9px;
}

.stepbar > hr.stepper_active {
  border-bottom: 3px solid #a100ff;
  border-top: 0px;
  margin: 9px 9px;
}

.stepper_box {
  text-align: center;
  padding: 0;
}

/* .stepper_title_spacer hr {
  clear: both;
  visibility: hidden;
  border: 0 none;
    height: 0px;
    color: #ffffff;
    shadow: none;
} */

.stepbar_title_spacer {
  padding: 0 0px;
  display: inline-block;
  vertical-align: middle;
}

/* Label/Field CSS */
.caret_indicator svg {
  color: #000;
  margin: 0 5px;
}

.disabled svg {
  color: #fff;
  margin: 0 5px;
}

.label_container {
  padding: 0;
}

.bo-search-field {
  height: 25px !important;
  border-radius: 0;
  border: 1px solid #dbdbdb;
  box-shadow: none;
  padding: 3px;
  font-family: 'OpenSans-Regular' !important;
  width: 100%;
  outline: none;
}

.bo-client-search-field {
  height: 34px !important;
}

.requiredMessage {
  font: normal 0.87em 'OpenSans-Regular';
  color: red;
  height: 1em;
  margin-right: 15px;
}

.indicator_container {
  cursor: pointer;
}
/* 
.custom-tooltip {
 background-color: #a0a0a0 !important;
  $.place-right:after {
  border-right-color: #a0a0a0 !important;
  border-right-style: solid !important;
  border-right-width: 6px !important;
 }
} */
#customTooltip {
  opacity: 1 !important;
}

#customTooltip > .tooltip-inner {
  background-color: #a0a0a0;
  border: 1px solid #a0a0a0;
  max-width: unset;
  border-radius: unset;
}

#customTooltip > .tooltip-arrow {
  border-right-color: #a0a0a0 !important;
}

.customeTheme {
  color: #ff6e00 !important;
  background-color: orange !important;
}
.customeTheme ::before {
  color: orange !important;
  content: 'askfdja;sd';
  border-top-color: orange !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.form-select-item > div > div:nth-child(1) {
  cursor: text;
  padding: 0.5px 2px;
  min-height: 20px;
}

.form-select-item > div:nth-child(3) > div > div {
  min-height: 21px;
}

.form-select-item > div:nth-child(3) > div {
  max-height: 200px;
}

/* DateRangePicker CSS */
.DayPickerInput {
  width: 47%;
}

.DayPickerInput > input {
  width: 100%;
  outline: 0;
  border: 1px solid #dbdbdb;
  height: 25px;
}

/* Radio Button CSS */
.DeliveredBy > div,
.appAI > div {
  /* display: flex; */
  justify-content: space-between;
}
.appAI > div {
  display: flex;
  justify-content: space-between;
  height: 20px;
}
.DeliveredBy > div > label,
.AppDecision > label {
  position: relative !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  /* width: 40%; */
}

.DeliveredBy > div > div,
.appAI > div > div {
  width: 100%;
}

input[type='radio'] + span {
  font-size: 0.8em;
  line-height: 1;
}

.glyphicon {
  top: 0;
}

svg.svg-inline--fa.fa-frown.fa-w-16.fa-fw {
  font-size: 12em;
  color: #a0a0a0;
}

/* Executive Summary */
.exec-summary-textarea > div {
  padding: 0;
}

.exec-summary-textarea > div:nth-child(1) {
  margin-bottom: 10px;
}

.exec-summary-textarea textarea {
  border-radius: 0 !important;
}

.exec-summary-count {
  font-size: 0.7em;
  color: #a0a0a0;
  position: absolute;
  bottom: -9px; /*15px*/
  right: 45px;
  background-color: white;
}

.exec-summary-textarea > button {
  padding: 5px 35px;
  margin: 10px 0 40px 0;
}

.ba-project-table .tabulator-col .tabulator-col-content .tabulator-col-title {
  overflow-wrap: break-word !important;
  white-space: initial !important;
  vertical-align: top !important;
  width: 80% !important;
}

.ba-project-table
  .tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-arrow {
  right: 6% !important;
}

label.ba-subscript-file.file-link {
  margin-top: -30px;
  color: #a0a0a0;
}

.btnSecurityError,
.btnSecurityError:hover {
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

#securityError .modal-dialog {
  width: 400px;
}

#securityError .modal-content {
  border-radius: 0;
}

#securityError .content_container {
  max-height: 300px;
  overflow-x: auto;
}

#securityError .button_container {
  text-align: center;
}

/* Bulk Request CSS */

.dropzone-container {
  margin: 0 40px 5px;
}

.dropzone-container .dropzone {
  border-radius: 4px;
  border: 2px dashed rgb(196, 205, 213);
  height: 80px;
}

.dropzone-button {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
  height: 76px !important;
  width: 100%;
  cursor: pointer;
}

.dropzone-upload-icon {
  width: 25px;
  height: 25px;
  background: url(../../Static/images/upload-duotone.png) no-repeat center center;
  background-size: 100%;
  position: relative;
  display: inline-block;
}

.dropzone-initial {
  display: inline-flex;
  position: absolute;
  bottom: 195px;
  width: 320px;
  margin-left: 20px;
}

.dropzone-initial > div:first-child {
  margin-right: 15px;
  margin-top: 7px;
}

.dropzone-label-dropfile {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  line-height: 20px;
}

.dropzone-lable-sizelimit {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.dropzone-label-browse {
  color: rgb(178, 0, 255);
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.back-to-rfa-button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.rfa-inner-container {
  padding: 20px 0;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(227, 234, 237);
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.back-to-rfa-list {
  display: flex;
  margin-left: 30px;
}

.back-to-rfa-button svg {
  position: absolute;
  font-size: 14px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: rgb(45, 58, 75);
}

.rfa-export-icon {
  width: 15px;
  height: 15px;
  background: url(../../Static/images/Left\ Icon@2x\ copy.png) no-repeat center center;
  background-size: 100%;
  position: relative;
  display: inline-block;
  top: 2px;
}

.rfa-export-icon-small {
  width: 12px;
  height: 12px;
  background: url(../../Static/images/Left\ Icon@2x\ copy.png) no-repeat center center;
  background-size: 100%;
  position: relative;
  display: inline-block;
  top: 2px;
}

.rfa-export-btn,
.rfa-export-btn:hover,
.rfa-export-btn:active,
.rfa-export-btn:focus {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-weight: 500;
  letter-spacing: -0.27px;
  line-height: 13px;
  text-align: center;
  width: 110px;
  font-family: Graphik-Medium-Web;
  height: 40px;
  cursor: pointer;
  margin-right: 30px;
  font-size: 17px;
}
.rfa-table-report-container {
  padding: 20px 90px 0px 90px;
}

.exec-summary-edit-header-container {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium-Web;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 24px;
  position: absolute;
  z-index: 1;
  padding: 10px 15px;
}

.textarea.db-edit-summary-text.form-control,
.textarea.db-edit-summary-text.form-control:hover,
.textarea.db-edit-summary-text.form-control:focus,
.textarea.db-edit-summary-text.form-control:active,
.textarea.db-edit-summary-text.form-control:active:focus,
.textarea.db-edit-summary-text.form-control:active:hover {
  /* background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(227, 234, 237);
  -webkit-box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1); */
  max-height: 157px;
  width: 100%;
  /* padding: 25px 25px 25px 10px; */
  display: -ms-flexbox;
  display: flex;
  outline: none;
  border: none !important;
  padding: 0px;
  border-radius: 0;
  box-shadow: none;
}

.exec-summ-save {
  margin-top: 33px !important;
  width: 100px !important;
}
/* 
.content_container {
  padding: 0 15px 15px 15px;
} */

#modal_BO.modules_modal .content_container {
  padding: 15px;
}
#exportMonetization.modules_modal .content_container {
  padding: 0 15px 15px 15px;
  text-align: justify;
}
.text-center {
  text-align: center;
}

.instructions-container {
  margin: 0 40px;
  padding: 40px;
}

.instructions-container .tab-component-content {
  padding: 20px !important;
  box-shadow: 0 0 5px #d0d0d0;
  border-radius: 5px;
}

.instructions-steps {
  margin-bottom: 20px;
  font-size: 14px;
}

.instructions-container .tab-component-content > img {
  margin: 0 50px 30px;
  box-shadow: 0 0 5px #b1b1b1;
}

/* Custom checkbox code starts here */
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.25px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-checkbox {
  position: absolute;
  top: -3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark-checkbox {
  background-color: #a100ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark-checkbox:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Custom checkbox code ends here */
