#dl-modal-container {
  text-align: center;
}
#dl-modal-container button {
  margin-top: 25px;
  display: unset;
}
#dl-loading-screen {
  height: 519px;
}
#progress-container {
  width: 30%;
  margin: auto;
  margin-top: 100px;
}
#progress-container h5 {
  text-align: center;
}
