.dimension-details {
  width: 100%;
  margin: 0 !important;
}
.dimension-details > div,
.sub-dimension-details > div,
.boi-details > div {
  display: flex;
  align-items: baseline;
  padding: 0;
  margin: 0 !important;
}
.dimension-details .inputs,
.dimension-details .inputs > div,
.sub-dimension-details .sub-dimension-input,
.sub-dimension-details .sub-dimension-input > div,
.boi-details .sub-dimension-input,
.boi-details .sub-dimension-input > div {
  width: 100%;
}
.dimension-details .form-group {
  margin: 0;
}

.viewComment-button > :disabled {
  cursor: not-allowed;
}

.dimension-details .btn-link {
  padding: 0;
}

.dimension-details .btn-link:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.radio-button-container {
  display: flex;
  flex-direction: row;
}
.radio-button-container > div {  
  padding: 4px 36px;
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
}
.radio-button-container > div.selected {  
  background-color: #a100ff;
  color: rgb(255 255 255);
}
.radio-button-container > div.not-selected {  
  background-color: rgb(151, 151,151);
  color: white;
}

.dimension-details .svg-container {
  margin: 0 25px;
  display: flex;
  justify-content: flex-end;
}

.dimension-collapse {
  width: 100%;
  margin: 0 0 30px 0;
}

.dimension-collapse > .card {
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
}

.dimension-collapse .card-header {
  background: #fff;
  padding: 15px 28px;
  border: none;
}
.dimension-collapse .card-body {
  padding: 0 1.25rem;
}
.dimension-collapse .card-header > button {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 0;
}

.sub-dimension-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-radius: 8px 8px 0 0;
  border: 1px solid rgb(223, 227, 232);
  padding: 16px 44px 16px 44px;
}
.sub-dimension-details > .sub-dimension-title {
  margin: 0 0 0 -25px !important;
}

.dimension-container .card-body > form {
  padding-bottom: 30px;
}
.boi-details {
  border-radius: 0 0 8px 8px;
  border-top: none !important;
  padding: 16px 44px 16px 44px;
  border: 1px solid rgb(223, 227, 232);
}
.boi-details > form > .boi-details-input {
  margin: 0 0 15px 0 !important;
}

.boi-details > form > .row > div {
  padding: 0 !important;
}


.boi-details .sub-dim-input > div {
  padding-left: 0;
}

.sub-dimension-title svg {
  width: 13px !important;
  line-height: 3;
  margin: -3px 5px;
}

.dimension-details span {
  margin: 0 0 0 20px;
}

.sub-dim-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sub-dim-input > div {
  width: 100%;
  /* max-width: 490px; */
}

.sub-dim-input > span {
  margin: 0 0 0 20px;
  width: 80px;
}

/* modal css */

.add-boi-field-container {
  padding: 15px;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 8px;
  display: flex;
  margin: 10px 0;
  position: relative;
  align-items: center;
}

.add-boi-field-container .new-info-wrap {
  height: auto;
}

.add-boi-field-container > div {
  height: 56px;
}

.delete-boi-button.disabled-delete{
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events:none;
}

.add-boi-field-container:hover .delete-boi {
  display: flex;
}

.select-boi-dropdown {
  width: 100%;
  max-width: 25%;
  padding: 0 8px;
}

.addedBOI-dropdown {
  width: 100%;
  padding: 0 8px;
  height: 56px;
  max-width: 25%;
  /* overflow-x: overlay; */
  contain: size;
}

.addboi-dropdown-containers {
  display: flex;
  width: 100%;
}

.addedBOI-dropdown #groove-select-dropdown div#selectmenu-anchor, .addedBOI-dropdown #groove-input .groove-input {
  border-radius: 8px;
}

.delete-boi {
  align-items: center;
  padding: 0 5px;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  width: 50px;
  display: flex;
  justify-content: center;
}


.delete-dimension-boi {
  padding: 0 5px;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  width: 50px;
  display: block;
  align-self: center;
}

#deleteboi-popover[x-placement^=left] {
  display: none;
  left: calc(100% - 336px);
}

#deleteboi-popover[x-placement^=right] {
  display: none;
  right: auto;
  left: auto;
  margin-left: 22%;
}

#deleteboi-dimension-popover {
  display: none;
  position: absolute;
  float: right;
  left: calc(100% - 450px);
}
.delete-boi:hover #deleteboi-popover,
.delete-dimension-boi:hover #deleteboi-dimension-popover,
.addedBOI-dropdown:hover #deleteboi-popover{
  display: table-caption !important;
}
#deleteboi-popover .bs-popover-auto[x-placement^=left]>.arrow, .bs-popover-left>.arrow,
#deleteboi-dimension-popover .bs-popover-auto[x-placement^=left]>.arrow, .bs-popover-left>.arrow{
  top: 33px;
}

.add-boi-field-container .form-group {
  margin: 0;
}

.divider-horizontal {
  border-top: 1px solid rgb(231, 234, 238);
  margin: 10px 0;
}
.added-boi-container {
  /* max-height: 500px; */
  overflow: visible;
}

.add-boi-button {
  display: flex;
  justify-content: flex-end;
}

.add-boi-button > button, .view-project-detail > button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 8px 20px;
  border: none !important; 
  color: #a100ff !important;
  background-color: transparent !important;
}

.view-project-detail > button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.add-boi-buttons {
  display: flex;
  justify-content: flex-end;
}

.add-boi-buttons button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 6px 20px;
  margin: 0 0 0 25px;
}


.deleted-bois-container {
  margin: 10px 0;
  border-radius: 8px;
}

.deleted-boi-details {
  position: absolute;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px #8c8c8c;
  top: 100%;
  display: none;
  z-index: 1;
  min-width: 220px;
  left: 0;
}
.deleted-bois {
  display: flex;
  align-items: center;
  padding: 10px 20px;
    border: 1.5px solid #a100ff !important;
    margin: 10px 0;
    border-radius: 8px;
}

.deleted-bois-text, .deleted-bois-text > div {
  font-size: 14px;
  font-family: Graphik-Medium;
  display: inline;
}

.deleted-bois-text {
  max-width: 85%;
}

.deleted-bois-text > div {
  color: rgb(161, 0, 255);
  position: relative;
  cursor: default;
  margin: 0 5px;
  word-break: break-all;
}

.deleted-bois-text > div:hover > .deleted-boi-details {
  display: block;
}

.deleted-boi-details > .deleted-boi-details-label {
  color: #292929;
}

.undo-deleted-boi-btn {
  margin-left: auto;
  margin-right: 35px;
}

.deleted-bois > .svg-container:first-child {
  margin-right: 15px;
}

.deleted-bois > .svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleted-bois svg {
  font-size: 15px;
}

.tab-header-row.row {
  margin: 0px 0px 16px 0px;
  justify-content: space-between;
  align-items: baseline;
}

.tab-header-row.row > div:first-child {
  max-width: 45%;
}

.tab-header-row > div:nth-child(2) {
  margin-left: auto;
}
.expandAll-button button >span,
.collapseAll-button button >span{
  font-size: 14px !important;
}
.expandAll-button button,
.collapseAll-button button,
.viewComment-button button {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  padding: 0 8px;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}
.expandAll-button button {
  border-right: 1.5px solid rgb(99, 115, 129);
}
.expandAll-button .btn:focus,
.collapseAll-button .btn:focus,
.viewComment-button .btn:focus {
  outline: none;
  box-shadow: none;
  color: #a100ff;
}
.expandAll-button .btn:hover,
.collapseAll-button .btn:hover,
.viewComment-button .btn:hover {
  color: #a100ff;
}
.tab-header-row .add-project-button {
  margin-left: 24px;
}
.tab-header-row-left {
  margin: 10px 0 10px 30px !important;
  justify-content: flex-end;
  align-items: center;
  min-width: 480px !important;
}
.program-identified-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.program-identified-buttons button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 8px 20px;
  margin: 0 0 0 10px;
}
.viewComment-button .svg-container.small {
  height: inherit;
  width: inherit;
  margin-right: 2px;
}
.ApprovalRequestModal .notifyModal-dropdown {
  /* margin-top: 24px; */
}

.groove-radio-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.groove-radio-button > div {
  margin: 0 16px 0 0;
}

.svg-container:hover + .tooltiptext, .info-icon:hover + .tooltiptext, .new-info-icon:hover + .tooltiptext {
  display: table-caption;
}

.new-info-icon {
  transform: scale(1.5);
  margin-top: 8px;
}

.viewComment-button button,
.viewComment-button .btn:hover,
.viewComment-button .btn:focus {
  color: #a100ff !important;
}

.check-box.check-box-all-boi {
  margin: 20px 0;
}

.check-box-all-boi .check-label{
  width: auto !important;
}

.detail-row.project-section{
  border-left: 1px solid rgb(223, 227, 232);
  border-bottom-left-radius: 0 !important;
}
