.projectEntry-container {
  padding: 30px 80px;
  min-height: 840px !important;
}

.projectEntry-container .btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.projectEntry-container .btn-container .project-title-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-container>div {
  display: flex;
  width: 60%;
  word-break: break-all;
}

.projectEntry-container .title-section {
  margin-bottom: 5px;
}

.projectEntry-container .title-section .fa-times {
  cursor: pointer;
}

.select-project-modal .add-new-program-container {
  overflow-y: visible;
}

.select-project-modal .modal-header {
  padding-bottom: 15px !important;
}

.program-details-column {
  display: flex;
  margin-bottom: 3px;
}

.program-details-row {
  border-right: 1px solid #dfe3e8;
}

.program-details-row:last-child {
  border-right: none;
}

.program-details-row.col-lg-4 {
  padding-right: 10px;
  padding-left: 12px;
}

.project-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 18px;
}

.projectEntry-subcontainer {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 40px 50px;
}

.projectEntry-subcontainer .project-title {
  width: 100%;
  word-break: break-word;
}

.projectEntry-container .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.computeAll-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.sub-title-header {
  margin-right: 0px !important;
  margin-left: 0px !important;
  text-align: center;
}

.projectEntry-container .sub-title .sub-title-header h5 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 0;
  align-self: center;
}

.reset-all-button, .compute-all-button {
  display: flex;
  flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 50px;
  cursor: pointer;
}

.reset-all-button {
  padding: 3px 0;
  padding-left: 15px;
}

.reset-all-button button {
  color: #a100ff !important;
  align-self: center;
  justify-content: center;
  padding-left: 15px;
}

.compute-all-button button {
  color: #a100ff !important;
  align-self: center;
  justify-content: center;
}

.reset-all-button img {
  height: 30px;
  width: 15px;
  position: absolute;
}

.compute-all-button img {
  height: 33px;
  width: 15px;
  position: absolute;
  margin-left: 15px;
}

.reset-all-button:hover, .compute-all-button:hover {
  background-color: #a100ff;
  transition: all 0.15s ease-in-out;
}

.reset-all-button:hover button, .compute-all-button:hover button {
  color: #fff !important;
  transition: all 0.15s ease-in-out;
}

.compute-all-button:hover img, .reset-all-button:hover img {
  filter: brightness(0) invert(1);
  transition: all 0.15s ease-in-out;
}

.projectEntry-container .sub-title-right span, .projectEntry-container .sub-title-right label {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  margin-left: 25px;
  cursor: pointer;
}

.reports-boiHistory-container {
  margin: 5px 0 10px 0;
}

.projectEntry-container .file-upload-wrap {
  padding: 19px 20px;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  justify-content: space-between;
  margin: 24px 0px;
}

.projectEntry-container .file-upload-wrap.file-error {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
}

.projectEntry-container .file-upload-wrap.file-success {
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.projectEntry-container .accordion-action-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subdimension-table {
  margin: 20px 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 0px;
}

/*css for columns based on status*/

@media (min-width: 992px) {
  .projectEntry-container .notif-table-header .row .col-lg-2, .projectEntry-container .subdimension-row.accordion .col-lg-2, .projectEntry-container .notif-table-header .row .col-lg-1, .projectEntry-container .subdimension-row.accordion .col-lg-1 {
    word-break: break-word;
  }
  .projectEntry-container .subdimension-row.accordion div:nth-child(3), .projectEntry-container .subdimension-row.accordion div:nth-child(4), .projectEntry-container .subdimension-row.accordion div:nth-child(5), .projectEntry-container .subdimension-row.accordion div:nth-child(6), .projectEntry-container .subdimension-row.accordion div:nth-child(7), .projectEntry-container .subdimension-row.accordion div:nth-child(8) {
    padding-left: unset !important;
  }
  .target-value-padding {
    padding-left: unset !important;
  }
}

.boiComm-awapp {
  left: 30px;
}

.wVis-awapp {
  left: 25px;
}

.projectEntry-subcontainer label.mini-toggle-switch {
  top: 6px;
}

.app-rej-awapp {
  left: 40px;
}

.app-rej-rejected, .app-rej-rejected-new {
  align-self: baseline;
  padding-left: unset;
}

.app-rej-rejected .rvd-rejection-comment #groove-textarea, .app-rej-rejected-new .rvd-rejection-comment #groove-textarea, .app-rej-awapp-new .rvd-rejection-comment #groove-textarea {
  width: 80%;
}

.boiComm-rejected {
  right: 23px;
}

.subdimension-section-collapse .subdimension-row .card-header>button {
  justify-content: flex-start;
}

.subdimension-row .card-header {
  padding: 20px 30px;
}

.subdimension-row .card-header div {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.bodytext14 {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.parameter-title-center {
  margin-left: 185px;
}

.false {
  font-family: Graphik-Medium;
}

.bodytext14-regular {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.fa-icons {
  color: rgb(161, 0, 255);
  padding: 3px;
  font-size: x-large;
  cursor: pointer;
}

.data-entries {
  margin: 15px;
  opacity: 0.5;
}

.new-pde {
  margin-bottom: 40px !important;
}

.report-prd {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 24px;
  justify-content: flex-end;
  display: block !important;
  text-align: right;
}

.new-proj-btn {
  display: inherit;
  justify-content: flex-end;
}

.subdimension-row .card-header>button .svg-container {
  display: inline;
  margin-right: 5px;
}

.subdimension-row.accordion .card-header {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px !important;
}

.subdimension-row.accordion:first-child .card-header {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
}

.subdimension-row.accordion .card-header .row {
  width: inherit;
}

.subdimension-row.accordion .card-header>button {
  width: 3%;
}

.subdimension-section-collapse .card-header>button {
  display: flex;
  justify-content: right !important;
  width: 100%;
}

.subdimension-section-collapse .card-header>button>.bodytext18-medium-midnight {
  margin-left: 20px;
}

.parameter-field {
  display: flex;
  align-items: center;
  margin: 10px 0px 50px 0px;
}

.field-text {
  width: 34%;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 19px;
}

.field-value {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.field-value input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px 5px;
  margin: 0px 12px 0px 40px;
}

.field-row-btn {
  display: flex;
  justify-content: center;
}

.bodytext14-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

/* @media (min-width: 992px){
.subdimension-table .col-lg-2:nth-child(2) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
.subdimension-table .col-lg-2:nth-child(3) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
} */

.subdimension-row.accordion .collapse.show~div.card-header .btn-link>div.svg-container {
  transform: rotate(90deg);
}

.subdimension-row.accordion .collapse.show~div.card-header div.svg-container {
  transform: rotate(0deg);
  cursor: pointer;
}

.validate {
  color: red;
  margin-left: 10px;
}

.rdv-view-comment {
  display: flex;
}

.expandAll-button.active>button, .collapseAll-button.active>button {
  color: #a100ff;
}

.info-icon {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

.info-icon .svg-container {
  transform: none !important;
  font-size: 0.9em !important;
}

.info-icon>.svg-container>svg {
  width: 11px;
  cursor: pointer;
}

.info-icon.top {
  position: absolute;
  right: -15px !important;
  top: -10px !important;
}

/* 
.ApprovalRequestModal .groove-textbox {
  margin-top: 9px;
} */

.custom-boi {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 10px;
  border: none;
  margin-top: 0.25em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 70px;
  margin-left: 10px;
}

.custom-boi:hover {
  box-shadow: 0 0 5px rgb(187 135 255);
}

.modal-fade-custom-boi {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  height: 1064px;
  width: 1440px;
}

.custom-boi-modal {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modalheader-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  width: 308px;
}

.modalbody-details {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.2px;
  line-height: 20px;
  width: 63px;
}

.modalbody-desc {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 20px;
  width: 67px;
}

.modalbody-column {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modal-custom-BOI-container {
  /* position: fixed; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  height: 100%;
  /* width: 20vw; */
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-Custom {
  width: 70vw;
  height: 80vh;
  /* max-width: 100%; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* background-color: #c47474; */
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row-inside {
  display: flex;
}

.row-outside {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
  display: flex;
}

.row-outside h3 {
  position: absolute;
  margin: 2rem 3rem;
  font-size: x-large;
}

#close {
  position: absolute;
  margin: 1em 65rem;
  color: gray;
  border: none;
  background-color: white;
}

.col-50 {
  flex: 25%;
  margin: 4rem 3rem;
}

.col-50 h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
}

.col-50 p {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.col-25 {
  flex: 12.5%;
}

/* NOTIF HISTORY START */

.modal-dialog.notif-history-modal.modal-dialog-centered {
  max-width: 95vw !important;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:nth-child(2) {
  padding-bottom: 30px !important;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:nth-child(2)>div:first-child {
  padding-bottom: 5px;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:first-child {
  padding: 20px 40px 0 40px !important;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:first-child>.modal-title.h4 .close-modal-btn {
  height: 15px !important;
}

.notif-history-container>div:first-child {
  margin-top: -20px;
  padding-bottom: 60px;
}

.notif-history-container {
  /* height: 480px; */
}

.notif-table-container {
  border: 1px solid rgb(223, 227, 232);
  border-radius: 5px 5px 0px 0px;
  border-width: 1px 1px 0 1px;
}

.notif-table-header {
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
}

.notif-table-body {
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  overflow-y: scroll;
  height: 260px;
}

.notif-table-header .row {
  padding: 15px 13px 7px 15px;
  color: #52657f;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  margin: 0;
}

.notif-table-header>.row>div {
  padding: 0 5px;
  display: flex;
}

.notif-table-body>.row {
  margin: 0;
  padding: 19px 8px 8px 8px;
  height: 55px;
}

.notif-table-body>.row>div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notif-table-body>.row:nth-of-type(even) {
  background: #f9fafb !important;
  border: 1px solid #e7eaee !important;
  border-width: 0.5px 0 0.5px 0 !important;
}

.notif-sort {
  cursor: pointer;
  display: inline-flex;
}

.sort-btn-container {
  width: 20px;
  height: 16px;
  padding-left: 10px;
}

#notif-filter-popover {
  width: 21.5rem;
  max-width: inherit;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  left: -64px !important;
}

#notif-filter-popover.popover .arrow {
  display: none;
}

#notif-filter-popover .popover-body {
  padding: 0;
}

#notif-filter-popover .popover-body>div>div:first-child {
  padding: 0.75rem 1.25rem;
}

/* 
#notif-filter-popover .popover-body > div:nth-child(2) {
  padding: 0 !important;
} */

#notif-filter-popover .search-bar-container .search-bar {
  position: relative;
}

.filter-btn-container {
  padding-left: 10px;
}

@media (min-width: 992px) {
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:first-child, .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:first-child {
    max-width: 14% !important;
    padding-left: 30px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(2), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(2) {
    max-width: 17% !important;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(3), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(3) {
    min-width: 25% !important;
    padding-right: 25px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(4), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(4) {
    min-width: 30% !important;
    padding-left: 80px;
    padding-right: 20px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(5), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(5) {
    width: 15% !important;
    margin-left: 110px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(6), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(6) {
    padding-left: 100px;
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(2), .notif-table-body>.row>div.col-lg-2:nth-child(2) {
    flex: 0 0 33%;
    max-width: 33%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(3), .notif-table-body>.row>div.col-lg-2:nth-child(3) {
    flex: 0 0 10%;
    max-width: 10%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(4), .notif-table-body>.row>div.col-lg-2:nth-child(4),
  .notif-table-header>.row>div.col-lg-2:nth-child(5), .notif-table-body>.row>div.col-lg-2:nth-child(5) {
    flex: 0 0 12%;
    max-width: 12%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7), .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    flex: 0 0 5%;
    max-width: 5%
  }

}

.new-notif-hist, .old-notif-hist {
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.new-notif-hist {
  color: rgb(21, 24, 27);
}

.old-notif-hist {
  color: rgb(99, 115, 129);
  /* padding-top: 10px; */
}

.new-notif-hist-cell {
  margin-top: -11px;
}

.new-notif-hist-cell>span, .old-notif-hist-cell>span {
  color: rgb(156, 106, 222);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.new-notif-hist-cell>div:first-child {
  /* margin-top: -20px; */
}

.new-notif-hist-cell>div:first-child>.svg-container.normal {
  /* padding-right: 5px; */
}

.notif-status>div:first-child {
  padding-right: 7px;
}

@media (min-width: 1920px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: none;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1535px) and (max-width: 1919px) {
  .notif-table-header>.row>div.col-lg-2, .notif-table-body>.row>div.col-lg-2 {
    flex: 0 0 16%;
    max-width: 16%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 23px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: 0px !important;
  }
}

@media (min-width: 1601px) and (max-width: 1919px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 248px;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 228px;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1351px) and (max-width: 1440px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 194px;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1421px) and (max-width: 1599px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 1px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -20px !important;
  }
}

@media (min-width: 1395px) and (max-width: 1535px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: -9px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -29px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1350px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 175px;
  }
  .notif-table-header>.row>div.col-lg-2, .notif-table-body>.row>div.col-lg-2 {
    flex: 0 0 15.3333%;
    max-width: 15.3333%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(2), .notif-table-body>.row>div.col-lg-2:nth-child(2) {
    flex: 0 0 21%;
    max-width: 21%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(3), .notif-table-body>.row>div.col-lg-2:nth-child(3) {
    flex: 0 0 12.3333%;
    max-width: 12.3333%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(6), .notif-table-body>.row>div.col-lg-2:nth-child(6) {
    flex: 0 0 13.3333%;
    max-width: 13.3333%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 42px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: 20px !important;
  }
}

@media (min-width: 1162px) and (max-width: 1279px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 0px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -20px !important;
  }
}

@media (min-width: 1101px) and (max-width: 1200px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 153px;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 129px;
  }

  .notif-table-header>.row>div.col-lg-2, .notif-table-body>.row>div.col-lg-2 {
    flex: 0 0 15%;
    max-width: 15%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 25px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: 5px !important;
  }
}

@media (min-width: 1023px) and (max-width: 1162px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: -9px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -26px !important;
  }
}

.notif-table-header>.row>div:nth-child(7) {
  max-width: 85px;
  margin-left: 25px;
}

.notif-table-body>.row>div:nth-child(7) {
  max-width: 70px;
  margin-left: 8px;
  padding-top: 7px;
}

.notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
  max-width: 119px;
}

.notif-history-searchbar .search-bar {
  position: relative;
}

.notif-history-searchbar.search-bar-container {
  height: 40px;
  display: flex;
}

#notifSearchBox {
  width: 320px;
  border-radius: 20px 0 0 20px;
  height: 40px;
}

.importance-btn-container {
  width: 182px;
  border-radius: 0 20px 20px 0;
  float: left;
  height: 40px;
  cursor: default;
  border: 1px solid rgb(231, 234, 238);
  margin: 10px 0 0 0;
  padding-left: 13px;
  padding-top: 3px;
  display: flex;
}

.importance-btn-clicked>.importance-button {
  background: #fff5f7 !important;
  color: #aa0025 !important;
  border: 1px solid #ea8097 !important;
}

.importance-btn-clicked>.important-x-btn-container svg.importance-plus-circle {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  cursor: pointer;
}

.importance-button {
  height: 32px;
  width: 150px;
  /* margin-top: 3px; */
  color: rgb(21, 24, 27);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  background: rgb(239, 242, 245);
  border-radius: 16px;
  border: 1px solid rgb(181, 192, 202);
  /* padding-top: 4px; */
  padding-left: 10px;
  /* margin-left: 15px; */
}

.importance-button>span {
  padding-left: 8px;
  padding-right: 8px;
  font-family: Graphik-Medium;
}

.importance-button>div:first-child {
  padding-left: 8px;
  padding-top: 4px;
}

.important-x-btn-container {
  padding-top: 6px;
  font-size: 11px;
  margin-left: -30px;
  z-index: 100;
  position: relative;
}

.importance-btn-container:hover {
  cursor: pointer;
}

.table-action-button {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  right: -15px !important;
  top: -10px !important;
}

.table-action-button .svg-container {
  transform: none !important;
  font-size: 0.9em !important;
}

.table-action-button>.svg-container>svg {
  width: 11px;
  cursor: pointer;
}

#notif-hist-tooltip {
  z-index: 3010;
  margin-left: -55px;
}

#notif-hist-tooltip .arrow {
  margin-left: 55px;
}

#notif-hist-tooltip .tooltip-inner {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  max-width: 500px;
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
  color: rgb(33, 43, 54);
  padding: 0.7rem 0.7rem;
}

#notif-hist-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff !important;
}

#notif-hist-tooltip .tooltip-parameter {
  margin-right: 20px;
}

#notif-hist-tooltip.tooltip.show {
  opacity: 1;
}

.notif-impt {
  cursor: default;
}