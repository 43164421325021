.check-box {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  padding: 11px;
}
.labels {
  position: relative;
  display: -webkit-inline-box;
}
.cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
}
#mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 18px 13px 18px 13px;
}
#mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}
.dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.dropdown-cancel {
  margin: 0px;
}
.dropdown-apply {
  margin: 0px;
}
.dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}
#mt-count-tooltip .search-bar-container .search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}
#mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  flex-wrap: wrap-reverse;
}

#mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}
#mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}
.arigato {
  background-color: blue;
}

#mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}
#mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}
#mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  box-shadow: none;
}
#mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}
#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}
#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}
#mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}
#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6 > svg {
  background-color: blue;
}
#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6 > div > div {
  background-color: rgb(0, 255, 34);
}

#mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .multi-select-dropdown-with-count {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  cursor: pointer;
}
.multi-select-dropdown-with-count div[class*='-indicatorContainer'] > svg {
  height: 30px !important;
  width: 50px !important;
  color: #919eab;
}

.multi-select-dropdown-with-count .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.requiredText {
  color: red;
}

.required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.cvm-disabled-option {
  cursor: not-allowed !important;
  background: rgba(118, 118, 118, 0.3);
  /* color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170)); */
  border-color: rgba(118, 118, 118, 0.3) !important;
}
div[name='businessAdvisor'] .cvm-disabled-unselected {
  cursor: not-allowed !important;
  background: rgba(118, 118, 118, 0.3);
  border-color: rgba(118, 118, 118, 0.3) !important;
}
