div.stepperClose-Alert.cpModal {
  padding: 0 30px;
}

.stepperClose-Alert.cpModal .stepperClose-Alert-Content {
  justify-content: center;
  padding-top: 20px;
  display: flex;
}

.stepperClose-Alert-text.cpModal h5 {
  color: rgb(45, 58, 75);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.24px;
}

.notifyModal-buttons.cpModal {
  display: flex;
  justify-content: center;
  padding: 0px 40px 40px 40px;
  background: white;
  border: 0px;
}

.notifyModal-buttons.cpModal button {
  width: 101px;
  justify-content: center;
}

/*Buttons*/
.notifyModal-buttons.cpModal #groove-btn-id .btn-outline-stat-alternate {
  border-radius: 24px;
  color: white;
  border-color: rgb(213, 0, 47);
  background: rgb(213, 0, 47);
}

.notifyModal-buttons.cpModal #groove-btn-id .btn-outline-stat-alternate:hover {
  border-radius: 24px;
  color: white;
  border-color: rgb(200, 0, 47);
  background: rgb(200, 0, 47);
}

.notifyModal-buttons.cpModal #groove-btn-id .btn-stat-alternate {
  background: rgb(126, 195, 68);
  border-radius: 16px;
  border-color: rgb(126, 195, 68);
}

.notifyModal-buttons.cpModal #groove-btn-id .btn-stat-alternate:hover {
  background: rgb(117, 180, 65);
  border-radius: 16px;
  border-color: rgb(117, 180, 65);
}

.stepperClose-Alert-text.cpModal h5:nth-child(2) {
  text-align: center;
  font-weight: bold;
  color: rgb(161, 0, 255);
}
