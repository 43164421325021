.CVMSideModal-container-rejected {
    padding: 28px 25px 0px 23px;
    width: 368px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(220, 226, 231);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: fixed;
    display: block;
    grid-template-rows: auto;
    right: 0;
    top: 0 ;
    height: auto;
    z-index: 999!important;
}
.CVMSideModal-close {
    position: absolute;
    right: 25px;
    top: 18px;
    z-index: 9999!important;
    color: #919eab;
    font-weight: 500;
    cursor: pointer;
}

.CVMSideModal-title {
    color: rgb(45, 58, 75);
    font-size: 18px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    text-align: left;
    letter-spacing: -0.28px;
    line-height: 18px;
    margin-bottom: 17px;
}
.CVMSideModal-footer {
    border-top: 1px solid rgb(223, 227, 232);
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
    justify-content: flex-end;
    width: 100%;
    background: rgb(249, 250, 251);
}
.footer-position {
    position: absolute;
    bottom: 0;
    right: 0;
}
.rejection-modal-buttons.row {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}
.rejection-modal-buttons button {
    font-family: "Graphik-Medium";
    font-size: 14px;
    padding: 9px 20px;
    margin: 0 0 25px 25px;
}

#comment-textbox-rejected{
    height: 120px !important;
    
}
#groove-textarea .floating-label-textbox #comment-textbox-rejected {
    border-top: 25px solid transparent !important;
    padding: .3rem 16px 15px;
}

/* #groove-textarea .floating-label-textbox #comment-textbox-rejected:focus {
    border-top: 1px solid #a100ff!important;
    padding: 1.3rem 16px 15px;
} */

#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown) + label {
    font-family: "Graphik-Medium";
    color: #637381 !important;
    font-size: 11px;
}