.multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  width: 455px;
}

.multi-select-label {
  display: flex;
  align-self: flex-start;
  margin: 12px 0px;
  width: 160px;
}

.multi-select-dropdown
  > div[class*="-container"]
  > div[class*="-control"]
  > div {
  max-height: 65px;
  overflow-y: auto;
}


.multi-select-dropdown > div[class*="-container"] > div[class*="-control"] > div:first-child {
  padding: 2px 5px !important;
}

.notifyModal-dropdown .multi-select-dropdown > div[class*="-container"] > div[class*="-control"] > div{
  max-height: initial;
}


.notifyModal-dropdown .multi-select-dropdown > div[class*="-container"] > div[class*="-control"]{
  min-width: 100%;
}

.multi-select-dropdown  div[class*="-multiValue"]{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  min-width: 75px;
}