
.authentication-container{
    display: block;
    width: 300px;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -150px;
}
.authentication-container > .authentication-button{
    margin-top: 15px;
    width: 300px;
}