.add-project-modal {
  padding: 30px 0 0 0;
  max-height: initial !important;
  overflow-y: visible !important;
}
.add-project-modal #groove-textarea .floating-label-textbox {
  margin-bottom: 1rem !important;
}

.saveDraft-toast {
  position: fixed;
  top: 70px;
  right: 40px;
  z-index: 1;
}
.saveDraft-toast .close,
.saveDraft-toast.close:before,
.saveDraft-toast .close:after {
  position: inherit;
}
.saveDraft-toast h5 {
  font-size: 14px;
  font-family: "Graphik-Medium-Web";
  margin-bottom: 0px;
}
.saveDraft-toast .sr-only,
.saveDraft-toast .close:after,
.saveDraft-toast .close::before {
  display: none;
}
.saveDraft-toast .toast {
  flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}
.saveDraft-toast .toast-header {
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.saveDraft-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}
.saveDraft-toast .close {
  font-size: 2rem;
  opacity: 1;
}
.saveDraft-toast .close.ml-2 {
  margin-left: 2rem !important;
}
.saveDraft-toast .close.mb-1 {
  margin-bottom: 1.2rem !important;
}
.required-field {
  border-color: red;
}
.tooltip-bottom > .tooltip-inner {
  background-color: #fff;
  color: rgb(5, 5, 5);
  border: 1px solid rgb(107, 107, 107);
}
#boi-tooltip-right.bs-tooltip-top .tooltip-inner {
  margin-bottom: 0;
}
#boi-tooltip-right.bs-tooltip-top .arrow:before {
  border-top-color: #fff !important;
}
.tooltip-bottom > .tooltip-arrow {
  border-top: 5px solid #fff;
}
.program-info {
  text-align: left;
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
}
.project-tab-section-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
.project-tab-section-add-btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

#groove-checkbox .form-check {
  position: relative;
  display: block;
  padding-left: 2.25rem;
}

.project-details-button {
  display: flex;
  justify-content: flex-end;
  margin: 46px 0 0 0 !important;
}

.project-details-button button {
  margin: 0 0 0 25px;
}
