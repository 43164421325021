.header {
  color: gray;
}

.table-border {
  border: 1px solid gray;
  border-radius: 10px 9px 0px 0px;
  margin: 8px 35px 0 35px;
  padding: 5px 0px !important;
  overflow-x: hidden;
}
.table-border > div {
  padding: 5px 30px;
}

.table-border > div > div:first-child {
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-border > div > div {
  max-width: 22%;
  word-break: break-word;
}

.table-header {
  font-weight: bold;
}
.table-header,
.table-content {
  /* display: flex; */
  justify-content: space-around;
  padding: 2px;
  text-align: left;
  border-bottom: 1px solid gray;
  color: gray;
}

.table-content:last-child {
  border-bottom: none;
}
.boiCount {
  padding: 5px 35px;
  color: gray;
}
#groove-checkbox{
  min-height:25px;
}
/* .header-checkbox{
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin: 6% 13%;
} */