.notify-toast,
.notify-toast-error {
  position: fixed;
  top: 70px;
  right: 40px;
  z-index: 1;
}
.notify-toast .close,
.notify-toast.close:before,
.notify-toast .close:after,
.notify-toast-error .close,
.notify-toast-error.close:before,
.notify-toast-error .close:after {
  position: inherit;
}

.notify-toast h5,
.notify-toast-error h5 {
  font-size: 14px;
  font-family: "Graphik-Medium-Web";
  margin-bottom: 0px;
}
.notify-toast .sr-only,
.notify-toast .close:after,
.notify-toast .close::before,
.notify-toast-error .sr-only,
.notify-toast-error .close:after,
.notify-toast-error .close::before {
  display: none;
}
.notify-toast .toast {
  flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.toast-header-error .toast {
  flex-basis: auto;
  max-width: inherit;
  border: 1px solid red;
  background-color: #f2f9ec;
}

.notify-toast .toast-header{
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.notify-toast-error .toast-header{
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f9ecec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.notify-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}

.notify-toast-error .toast-header svg {
  color: red;
  font-size: 30px;
  margin-right: 25px;
}
.notify-toast .close,
.notify-toast-error .close {
  font-size: 2rem;
  opacity: 1;
}
.notify-toast .close.ml-2,
.notify-toast-error .close.ml-2{
  margin-left: 2rem !important;
}

.notify-toast .close.mb-1,
.notify-toast-error .close.mb-1 {
  margin-bottom: 1.2rem !important;
}