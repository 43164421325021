.client-story-recentFile {
  width: 100%;
  height: 56px;
  background: rgb(244, 251, 239);
  border: 1px solid rgb(126, 195, 68);
  border-radius: 8px;
  display: flex;
  align-items: center;
  align-content: center;
}

.download-clientstory-label {
  width: 53px;
  height: 12px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.download-clientstory-button {
  width: 100px;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(181, 192, 202);
  border-radius: 16px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  /* line-height: 12px; */
  margin: 0 5px;
}

.clientstory-button-text {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.22px;
}

.clientstory-download {
  /* float: right;
  position: relative;
  padding: 1%; */
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.download-icon {
  color: #b5c0ca;
  height: 16px;
  /* position: relative; */
  /* float: left; */
  /* margin-top: 4px; */
  padding-right: 6px;
  /* margin-left: 8px; */
}

.client-story-filename {
  padding: 0 0px 0 25px;
  height: 18px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.22px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download-icon-label {
  color: #b5c0ca;
  height: 16px;
  position: relative;
  float: left;
  padding-right: 11px;
  filter: invert(70%) sepia(78%) saturate(368%) hue-rotate(44deg) brightness(87%) contrast(87%);
}