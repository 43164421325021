.add-new-program-container #groove-radio-button {
  display: flex !important;
}

.add-new-program-container #groove-radio-button>.form-check {
  margin-right: 30px !important;
}

.add-new-program-container #groove-radio-button .form-check>label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.add-new-program-container .label16-medium-midnight {
  font-family: 'Graphik-Medium' !important;
  color: #212b36 !important;
}

.add-user-client-type {
  display: flex;
}

.add-user-client-type>div:first-child {
  margin: 0 30px 0 0;
}

.add-user {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
}

span.invalid-selection {
  color: red;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  font-family: 'Graphik-Medium-Web';
}

span.invalid-selection.error-on-profile {
  bottom: unset;
}

span.invalid-selection.error-on-profile.another-qc-error,
span.invalid-selection.error-on-profile.client-error {
  line-height: 0.1px;
  top: 62px;
}

@media only screen and (max-width: 768px) {
  span.invalid-selection {
    font-size: 10px;
  }
}

.selected-profile-container {
  padding: 0 15px;
}

.selected-profile-container-background {
  background: #faf5fd;
  padding: 30px 40px 30px 30px !important;
  border-radius: 5px;
}

.selected-profile-container-background>div.row>div {
  padding-bottom: 30px;
  padding-top: 30px;
}

.selected-profile-container-background>div.row>div:last-child {
  padding: 0;
}

.selected-profile-container .form-group {
  margin-bottom: 0 !important;
}

.selected-profile-container-background>.row {
  position: relative;
}

.selected-profile-container-background>.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-right: 15px;
}

.selected-profile-container>div>div>.svg-container {
  line-height: 0;
  cursor: pointer;
}

.dashboard-dropdown .multi-select-dropdown-with-count {
  height: 60px;
}

.dashboard-dropdown .multi-select-dropdown-with-count div[class*='-control']>div {
  flex-wrap: wrap;
}

.profile-to-add-dropdown .multi-select-dropdown-with-count.disabled, .selected-profile-container .multi-select-dropdown-with-count.disabled, .dashboard-dropdown .multi-select-dropdown-with-count.disabled {
  background: #f9fafb;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  cursor: not-allowed;
}

.profile-to-add-dropdown .multi-select-dropdown-with-count.disabled:hover, .selected-profile-container .multi-select-dropdown-with-count.disabled:hover, .dashboard-dropdown .multi-select-dropdown-with-count.disabled:hover {
  border: 1px solid #ced4da !important;
}

.selected-profile-container .multi-select-dropdown-with-count.disabled svg, .dashboard-dropdown .multi-select-dropdown-with-count.disabled svg {
  display: none;
}

.dashboard-dropdown>.Multi-Select-BT {
  padding-bottom: 20px;
}

#checkboxlist-enableClient .cbox-container {
  display: inline-flex;
  width: 100%;
}

#checkboxlist-enableClient .cbox-container #groove-checkbox {
  margin-right: 10px;
}

#checkboxlist-enableClient .cbox-container>div:nth-child(3) {
  width: 70%;
}

#checkboxlist-enableClient .cbox-container>div:nth-child(3) #groove-checkbox-label {
  color: rgb(181, 192, 202);
}

#checkboxlist-enableClient #groove-checkbox div#groove-checkbox-label {
  line-height: 25px;
}

.deleteToast.saveDraft-toast .toast-header>svg {
  color: rgb(213, 0, 47);
}

.deleteToast.saveDraft-toast .toast {
  border: 1px solid rgb(234, 128, 151);
  background-color: rgb(255, 245, 247);
  font-size: 16px;
}

.deleteToast.saveDraft-toast .toast-header {
  background-color: rgb(255, 245, 247);
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.undo-delete-btn {
  margin-left: 92px;
  text-decoration: underline;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.userRemovedText {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  margin-bottom: 0px;
}

.added-profiles, .select-profile {
  padding-bottom: 30px;
}

.profile-header {
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.24px;
}

.duplicate-client.svg-inline--fa.fa-w-6 {
  border-radius: 16px;
  height: 16px;
  width: 16px;
  border: 1px solid red;
  color: red;
  margin: 0 5px 0 0;
  padding: 2px;
  cursor: pointer;
}

div[id^=client-] .tooltip-inner,
div[id^=offering-] .tooltip-inner,
div[id^=clientOffering-] .tooltip-inner {
  text-align: left;
}

/* Delete mapped user */
.select-project-modal .header24-semibold-midnight {
  opacity: 1;
  color: rgba(21,24,27,1);
  font-family: "Graphik-Semibold";
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.28px;
  text-align: left;
}

.stepperClose-Alert-Content.dmu-text {
  display: block;
}

.dmu-text > span {
  display: block;
  padding-bottom: 5px;
}

.dmu-text {
  opacity: 1;
  color: rgba(99,115,129,1);
  font-family: "Graphik-Regular";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  padding-bottom: 0 !important;
}

.dmu-client-list {
  color: rgba(161,0,255,1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.delete-mapped-user-buttons {
  background: none !important;
  border: none !important;
}
