.title-section .fa-chevron-left {
  margin-right: 24px;
}

.client-config-container {
  display: flex;
  border-radius: 0 0 16px 16px;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.stepper-tab-left-section {
  width: 21.2%;
  background: rgb(249, 250, 251);
  border-right: 1px solid rgb(223, 227, 232);
  padding: 25px 0;
  border-radius: 0 0 0 16px;
}

.stepper-tab-left-section .tab-section-header {
  margin: 0 0 15px 0;
  padding: 0 24px;
}

.card-body .tab-section-header {
  margin: 0 20px 15px 20px;
}
.stepper-tab-right-section {
  background: rgb(255, 255, 255);
  padding: 22px 35px;
  width: 100%;
  border-radius: 0 0 16px 0;
}
/* .tab-section-header {
  margin-bottom: 50px;
} */
.tab-section-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-section-header button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 6px 20px;
  /* margin: 0 0 0 25px; */
}
.subtab-list {
  margin-top: 30px;
}
.subtab-list ul li {
  border-radius: 0px;
  padding: 10px 25px;
  margin-bottom: 5px;
  cursor: pointer;
  color: rgb(99, 115, 129);
}
.subtab-list ul,
.left-sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.subtab-list ul li.active,
.subtab-list ul li:hover {
  background: #a100ff;
  color: rgb(255, 255, 255);
}
/* .progress-detail-section {
  display: flex;
} */
.progress-detail-section {
  display: flex;
  margin: 50px 0 0 0;
}
.progress-bar-unit {
  width: 126px;
  height: 126px;
  margin-left: 28px;
}
.progressbar-innertext {
  text-align: center;
  position: relative;
  top: -72px;
  background-color: white;
  width: 73px;
  left: 56px;
}
.progressbar-innertext h5 {
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 22px;
  margin-bottom: 8px;
}
.progressbar-innertext p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.21px;
  margin: 0 5px;
}

.progress-visual {
  padding: 0 16px;
}
.program-details {
  display: flex;
  flex-direction: column;
  padding: 35px 60px !important;
}

.details-row {
  margin: 8px 0;
  display: flex;
}
.progress-label {
  margin: 20px 0px 0px 0px;
}
.stepper-tab-right-section hr {
  margin-top: 30px;
  margin-bottom: 22px;
  margin-right: 20px;
  border-top: 1px solid rgb(234, 234, 234);
}
.project-list-details {
  padding-right: 20px;
  max-height: 430px;
  overflow-y: auto;
}
.project-list-details h4 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin: 0px 0px 0px 8px;
}
.project-detail-section {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 21px 27px;
  margin-top: 24px;
}
.project-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.project-detail-header h5 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.project-detail-row {
  margin-bottom: 15px;
}
.project-detail-row .row-label {
  margin-right: 10px;
}

.stepped-progressbar {
  display: flex;
  align-items: center;
}
.stepped-progressbar p {
  margin: 0 6px;
}
.stepped-progressbar .progressbar-stepslist {
  display: inline-flex;
}
.stepped-progressbar .progressbar-stepslist .progress-step {
  width: 26px;
  height: 5px;
  background: rgb(231, 234, 238);
  border-radius: 0px 0px 0px 0px;
  margin-left: 5px;
}
.progressbar-stepslist .progress-step:first-child {
  border-radius: 5px 0px 0px 5px;
  margin-left: 0px;
}
.progressbar-stepslist .progress-step:last-child {
  border-radius: 0px 5px 5px 0px;
}
.progressbar-stepslist .progress-step.done {
  background: rgb(228, 183, 255);
}
.progressbar-stepslist .progress-step.active {
  background: rgb(117, 0, 192);
}
/* .container-PIV {
  padding: 20px 15px 20px 20px;
} */
.container-PIV {
  padding: 0 0 20px 0;
}
.container-PIV-box {
  padding-right: 25px;
}
.errorbox {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  border-radius: 8px;
  padding: 11px 16px;
  padding-right: 9rem;
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
}
