#custom-toggle .mini-toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 34px;
}

#custom-toggle .mini-toggle-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 50px;
}

.wheel-visibility-button .mini-toggle-switch {
    margin-top: 9px;
}

#custom-toggle .mini-toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

#custom-toggle .mini-toggle-switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

#custom-toggle .mini-toggle-slider {
    height: 21px !important;
    width: 40px !important;
}

#custom-toggle .mini-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    height: 25px;
    width: 45px;
    border-radius: 20px;
    background-color: #c4cdd5;
}

#custom-toggle .mini-toggle-slider {
    background-color: #c4cdd5;
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    width: 45px;
}

#custom-toggle .mini-toggle-slider:before {
    height: 19px !important;
    width: 20px !important;
}

#custom-toggle .mini-toggle-slider:before {
    position: absolute;
    height: 23px;
    width: 24px;
    background-color: #fff;
    transition: 0s;
    border-radius: 20px;
    content: "";
    box-shadow: 0 0 0 0 rgb(0 0 0 / 24%);
    margin: 1px 1px 1px 2px;
}

#custom-toggle .mini-toggle-slider:before {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 24%);
    content: "";
    height: 23px;
    margin: 1px 1px 1px 2px;
    position: absolute;
    transition: 0s;
    width: 24px;
}

#custom-toggle input:checked+.mini-toggle-slider:before {
    height: 19px!important;
}

#custom-toggle input:checked+.mini-toggle-slider:before {
    border: 1px solid #a100ff;
    margin-top: 0.85px;
    height: 22px;
    border-radius: 12px;
    -webkit-transform: translateX(18px);
    transform: translateX(18px);
}

#custom-toggle input:checked+.mini-toggle-slider:after {
    content: "";
    position: absolute;
    left: 30px;
    bottom: 6px;
    width: 4px;
    height: 13px;
    border: solid #a100ff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    padding-bottom: 2px;
    border-color: transparent!important;
}

#custom-toggle .mini-toggle-slider>div {
    margin-left: 55px;
    width: auto;
}

#custom-toggle .mini-toggle-slider>div {
    margin-left: 55px;
    width: auto;
}

#custom-toggle input:checked+.mini-toggle-slider {
    background-color: #a100ff;
}

#custom-toggle input:checked+.mini-toggle-slider {
    background-color: #a100ff;
}

#custom-toggle .mini-toggle-slider {
    height: 21px !important;
    width: 40px !important;
}

#custom-toggle .mini-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    height: 25px;
    width: 45px;
    border-radius: 20px;
    background-color: #c4cdd5;
}

#custom-toggle .mini-toggle-slider {
    background-color: #c4cdd5;
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    width: 45px;
}

#custom-toggle.disabled span#switch_toggle_click{
    cursor: not-allowed;
}