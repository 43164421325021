.cvm-multiselect-text {
  position: relative;
}

.cvm-multiselect-text #groove-input .floating-label .groove-input:focus,
.cvm-multiselect-text
  #groove-input
  .floating-label
  .groove-input:not(:placeholder-shown) {
  padding-right: 90px !important;
}
.cvm-multiselect-text > #groove-input > .floating-label > input.groove-input {
  margin: 0;
}

.cvm-multiselect-text #groove-btn-id {
  position: absolute;
  right: 15px;
  top: 9px;
}

.cvm-multiselect-text-selected {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 15px 0;
  flex-wrap: wrap;
}

.cvm-multiselect-text-selected-values {
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  padding: 8px 15px;
  color: rgb(117, 0, 192);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.cvm-multiselect-text-selected-values > .svg-container {
  height: auto !important;
  width: 13px !important;
}

.cvm-multiselect-text-selected-values > .svg-container > svg {
  font-size: 13px;
  color: #460073;
  cursor: pointer;
}

.cvm-multiselect-text-selected-values > div {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 
.cvm-multiselect-text div.groove-input-custom-label {
  margin: -37px 0 !important;
} */
