.multi-select-dropdown-with-count {
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #fff;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.multi-select-dropdown-with-count > .multi-select-label {
  color: #a100ff;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  padding: 0 14px;
}

.multi-select-dropdown-with-count:hover {
  border: 1.5px solid #a100ff !important;
}

.multi-select-dropdown-with-count > .multi-select-label {
  display: flex;
  align-self: flex-start;
  margin: 0px !important;
}

.multi-select-dropdown > div[class*='-container'] > div[class*='-control'] > div {
  max-height: 65px;
  overflow-y: auto;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] {
  padding: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
}

.multi-select-dropdown-with-count div[class*='-menu'] {
  font-size: 16px !important;
  margin-top: 0;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] > svg {
  height: 30px !important;
  width: 50px !important;
}

.multi-select-dropdown-with-count div[class*='-control'] > div {
  display: flex;
  flex-wrap: nowrap;
}

.multi-select-dropdown-with-count div[class*='-control'] > div:first-child {
  overflow-x: auto;
}

.multi-select-dropdown-with-count div[class*='-control'] > div:nth-child(2) {
  width: 120px;
}

.multi-select-dropdown-with-count div[class*='-multiValue'] {
  min-width: initial !important;
  padding: 0 5px;
  height: 30px;
}

.multi-select-dropdown-with-count
  > div[class*='-container']
  > div[class*='-control']
  > div
  > div[class*='-multiValue'] {
  min-width: unset !important;
}

.multi-select-dropdown-with-count div[class*='-multiValue'] > div:first-child {
  padding-left: 3px;
}
.multi-select-dropdown-with-count div[class*='-multiValue'] > div:nth-child(2) {
  padding: 0;
}

.selected-count-container {
  color: #fff;
  background: #a100ff;
  width: fit-content;
  height: 28px;
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Graphik-Medium';
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 75px;
}

.selected-count-container > div {
  display: flex;
  align-items: center;
  font-size: 13px !important;
  margin-top: -2px;
}

.Multi-Select-BT .multi-select-dropdown {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 54px;
  width: 100%;
}

.disabled-mulitSelect > div {
  cursor: not-allowed !important;
}
.Multi-Select-BT .multi-select-dropdown-with-count {
  border: 1px solid #ced4da !important;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count {
  background: rgb(249, 250, 251);
  cursor: not-allowed;
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-control']
  > div:first-child {
  overflow: hidden;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(231, 234, 238);
  height: 22px;
  margin: 0 3px;
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-multiValue']
  > div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-multiValue']
  > div:nth-child(2) {
  display: none;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count > div:nth-child(2) > div {
  background: transparent !important;
}

.Multi-Select-BT.disabled-mulitSelect .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT .selected-count-container .svg-container > svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  height: 22px;
  margin: 0 3px;
}

.Multi-Select-BT.dwith-label .multi-select-dropdown-with-count div[class*='-multiValue'] > div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count > div:nth-child(2) > div:first-child {
  background: transparent !important;
}

.Multi-Select-BT.with-label .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .selected-count-container .svg-container > svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT.with-label .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}
